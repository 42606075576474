.ring,
.dot {
  display: none;
}
.ring {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border: 3px solid var(--atlasgreen);
  background-color: transparent;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  -webkit-transition-duration: 50ms;
  transition-duration: 50ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  will-change: width, height, transform, border;
  z-index: 9999999999;
  pointer-events: none;
}

.dot {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: var(--atlasblue);
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 9999999999;
  pointer-events: none;
}

.ring.hovered {
  transition: all 0.4s;
  opacity: 0;
}

.ring.slidered {
  transition: background-color 0.4s, width 0.4s, height 0.4s;
  width: 50px;
  height: 50px;
  opacity: 1;
  background-color: var(--atlasblack);
  border: 1px solid var(--atlasgreen);
}

.slidered {
  color: var(--atlasgreen);
  font-size: 20px;
  font-weight: normal;
}
.slidered:after {
  content: ">";
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translateY(-50%);
}

.slidered::before {
  content: "<";
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translateY(-50%);
}

.hovered + .dot {
  transition: background-color 0.4s, width 0.4s, height 0.4s;
  width: 50px;
  height: 50px;
  opacity: 0.5;
  background-color: var(--atlasblack);
  border: 2px solid white;
}

.slidered + .dot {
  transition: opacity 0.4s;
  opacity: 1;
  background-color: var(--atlasgreen);
}

@media (min-width: 1200px) {
  body {
    cursor: none;
  }
  .ring,
  .dot {
    display: block;
  }
}
