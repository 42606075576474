.button-com {
  border: 1px solid var(--atlasblack);
  border-radius: 50px;
  font-weight: bold;
  font-size: var(--f14);
  text-align: start;
  position: relative;
  padding: 8px 20px 8px 46px;
  transition: all 0.5s;
}

.button-com svg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 10px;
  position: absolute;
  left: 6px;
  top: 50%;
  color: var(--atlaswhite);
  background: var(--atlasblue);
  transform: translateY(-50%);
  transition: all 0.5s;
  stroke-width: 3px;
}

.dark-mode .button-com:focus,
.dark-mode .button-com:hover,
.dark-mode .button-com svg {
  color: var(--atlasblack);
}

.button-com:focus,
.button-com:hover {
  padding: 8px 46px 8px 20px;
  background-color: var(--atlasblue);
  color: var(--atlaswhite);
  box-shadow: none;
}

.button-com:focus svg,
.button-com:hover svg {
  left: calc(100% - 36px);
  background: var(--atlasblack);
}

.dark-mode .button-com:focus svg,
.dark-mode .button-com:hover svg {
  background: var(--atlaswhite);
}

/* === dark button === */

#dark-bg svg {
  background: var(--atlasgreen);
}

#dark-bg {
  background: var(--atlasblack);
  color: var(--atlaswhite);
  border: transparent;
}

.dark-mode #dark-bg {
  color: var(--atlaswhite);
}

.dark-mode #dark-bg:focus,
.dark-mode #dark-bg:hover {
  color: var(--atlasblack);
}

#dark-bg:focus,
#dark-bg:hover {
  color: var(--atlaswhite);
  background-color: var(--atlasblue);
}

/* === blue button === */

#blue-bg svg {
  background: #acbde1;
}

#blue-bg {
  background: var(--atlasblack);
  color: var(--atlaswhite);
  border: transparent;
}

.dark-mode #blue-bg {
  background: var(--atlaswhite);
  color: var(--atlasblack);
}

#blue-bg svg path {
  stroke: var(--atlasblue);
}

#blue-bg:hover svg path {
  stroke: #acbde1;
}

.dark-mode #blue-bg:focus,
.dark-mode #blue-bg:hover {
  color: var(--atlaswhite);
}

#blue-bg:focus,
#blue-bg:hover {
  color: var(--atlasblack);
  background-color: #acbde1;
}

#blue-bg:hover svg {
  background: var(--atlasblack);
}

.dark-mode #blue-bg:hover svg {
  background: var(--atlaswhite);
}

/* === dark button === */

@media (min-width: 768px) {
  .button-com svg {
    width: 40px;
    height: 40px;
  }

  .button-com:focus svg,
  .button-com:hover svg {
    left: calc(100% - 46px);
  }

  .button-com {
    font-size: var(--f19);
    line-height: var(--f19);
    padding: 15px 25px 15px 51px;
  }

  .button-com:focus,
  .button-com:hover {
    padding: 15px 51px 15px 25px;
  }
}

@media (min-width: 992px) {
  .button-com svg {
    width: 40px;
    height: 40px;
  }

  .button-com {
    padding: 13px 20px 13px 56px;
    font-size: var(--f20);
    line-height: var(--f20);
  }

  .button-com:focus,
  .button-com:hover {
    padding: 13px 56px 13px 20px;
  }

  .button-com:focus svg,
  .button-com:hover svg {
    left: calc(100% - 46px);
  }
}

@media (min-width: 1500px) {
  .button-com {
    padding: 13px 20px 13px 56px;
    font-size: var(--f24);
    line-height: var(--f24);
  }

  .button-com:focus,
  .button-com:hover {
    padding: 13px 56px 13px 20px;
  }
}
