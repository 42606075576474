/* === Service One === */

.our-service {
  padding: 50px 0;
}

.service-content h2 {
  font-size: 50px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  margin-bottom: 15px;
}

.service-content p {
  font-size: 18px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  line-height: 27px;
  margin-bottom: 15px;
}

.service-content h4 {
  font-size: 24px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  line-height: 36px;
}

/* === Service Two === */

#Services_slide .slider_slid .row {
  width: 100% !important;
  align-items: center;
}

#Services_slide .thumb_name .thumb-icon h3 {
  color: var(--atlasblack) !important;
  text-align: center;
}

#Services_slide .slider-thumb .thumb-icon span {
  background-color: #e6e6e6 !important;
}

#Services_slide
  .slider-thumb
  .thumb_name
  .thumb-icon
  span
  svg
  circle#Ellipse_194 {
  fill: var(--atlasblack);
}

#Services_slide .slider-thumb:after {
  border-color: var(--atlasblack);
}

#Services_slide .slider_slid .slide_head {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #a2ba5f;
}

#Services_slide .slider-thumb .slick-current .thumb-icon h3 {
  color: #a2ba5f !important;
}

#Services_slide .slider-thumb .thumb_name .thumb-icon h3 span {
  text-align: center;
  display: block;
  width: 100%;
}

#Services_slide
  .slider-thumb
  .slick-current
  .thumb-icon
  span
  svg
  circle#Ellipse_194 {
  fill: rgb(162, 186, 95);
}

.technologies_content .slider_slid .slide_head span {
  color: var(--atlasblack);
  display: block;
}

.technologies_content .slider_slid .slide_body {
  font-size: 24px;
  font-weight: 500;
  color: var(--atlasblack);
  line-height: 40px;
}

.slide_li ul li {
  font-size: 16px;
  font-weight: 500;
  line-height: 37px;
  list-style: disc;
  color: var(--atlasblack);
}

.slide_li ul li::marker {
  color: #a2ba5f;
}

#technologies_slider .slider-thumb {
  margin-top: 30px;
}

/* === Service Four === */

.time-material {
  /* padding: 50px 0; */
}

.time-material .accordion-header {
  justify-content: flex-start;
}
.time-material button[aria-expanded="false"] {
  color: gray;
  justify-content: flex-start;
  transition: all 0.4s;
}

.time-material .accordion-header:hover button,
.time-material button[aria-expanded="true"] {
  width: fit-content;
  transition: all 0.4s;
  color: black;
}

.time-active a {
  text-decoration: none;
}

.time-tebimation {
  gap: 16px;
}

.time-tebimation h2 button {
  font-size: 40px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  line-height: 60px;
  margin-bottom: 0;
}

.time-tebimation img {
  width: 46px;
  height: 46px;
}

.time-inner-content {
  gap: 20px;
}

.time-text h2 {
  font-size: 40px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  line-height: 60px;
}

.time-text p {
  font-size: 18px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  line-height: 28px;
}

.accordion-button:not(.collapsed) {
  color: unset;
  background-color: transparent;
  box-shadow: none;
}

.accordion-item {
  border: none;
}

.accordion-button::after {
  content: none;
}

.accordion-button:focus {
  border-color: transparent;
  box-shadow: unset;
}

.accordion-button {
  padding: 10px 5px;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-header img {
  margin-right: 10px;
}

/* === Service Five === */

.benefits-section {
  /* padding: 50px 0; */
}

.benefits-content h3 {
  font-size: 24px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  line-height: 34px;
}

.benefits-content h2 {
  font-size: 40px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  line-height: 46px;
}

.benefits-main-text {
  gap: 10px;
}

.benefits-main-text p {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  line-height: 35px;
}

.benefits-content p {
  font-size: 18px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  line-height: 34px;
}

/* === Service Six === */
.artical-card {
  width: 33.33%;
}

.artical-card .artical-card-img {
  overflow: hidden;
  border-radius: 25px;
  margin-bottom: 45px;
  box-shadow: 0 0 0 #0000001a;
  aspect-ratio: 4 / 3;
  transition: all 0.4s;
}

.artical-card .artical-card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.4s;
  scale: 1;
  overflow: hidden;
}

.artical-card:hover .artical-card-img img {
  scale: 1.05;
}

.artical-card:hover .artical-card-img {
  box-shadow: 0px 30px 20px #0000001a;
}

.artical-card-head {
  margin-bottom: 28px;
}

.artical-card-head p {
  font-size: 14px;
  line-height: normal;
  color: #003271;
  font-weight: bold;
  margin-bottom: 0;
}

.artical-card-title h3 {
  font-size: 24px;
  line-height: 35px;
  font-weight: bold;
  margin-bottom: 24px;
  margin-top: 10px;
  color: var(--atlasblack);
}

.artical-card-date {
  font-size: 14px;
  line-height: 40px;
  margin-bottom: 0;
}

.slick-initialized .slick-list .slick-slide .item {
  width: 100%;
  display: block;
  border: 1px solid #cccccc;
  background-color: var(--atlaswhite);
  box-shadow: unset;
  border-radius: 15px;
  scale: 1;
  transition: all 0.4s;
  object-fit: cover;
  object-position: top;
  overflow: hidden;
}
.slick-initialized .slick-list .slick-slide:hover .item {
  box-shadow: 0 5px 8px #68686875;
  scale: 1.02;
}
.dark-mode .slick-initialized .slick-list .slick-slide:hover .item {
  box-shadow: 5px 5px 8px #b5b5b575;
  scale: 1.02;
}
.white-box img {
  box-shadow: 0 0px 0px #68686875;
  scale: 1;
}
.white-box:hover img {
  box-shadow: 0 5px 8px #68686875;
  scale: 1.05;
}
.testimonial-section .slick-list .slick-slide:hover img {
  scale: 1.05;
  box-shadow: 0px 5px 8px #68686875;
}

.testimonial-section .slick-list .slick-slide:hover img,
.home_page_slider .slick-list .slick-slide:hover img {
  transition: all 0.4s;
  scale: 1;
}

.aboutseven .slick-initialized .slick-list .slick-slide img {
  border-radius: 50%;
}

#technologies_slider .slider_slid img,
.home_page_slider .slider_slid img {
  border: none !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.testimonial-section img,
.testimonialauthourwithimage .testi-img img {
  border: unset !important;
  background-color: transparent !important;
  box-shadow: unset !important;
}

#service_inner_ui .all-developer {
  justify-content: start;
  flex-wrap: wrap;
  gap: 20px;
}

#service_inner_ui .all-developer .box {
  display: flex;
  width: calc(33.33% - 13.33px);
  padding: 30px;
  flex-direction: column;
  justify-content: space-between;
}

/* === Media Css === */
@media (max-width: 1500px) {
  .time-tebimation h2 button {
    font-size: 32px;
  }
  .benefits-content h2,
  .service-content h2 {
    font-size: 38px;
    line-height: 50px;
  }
  .artical-card-title h3 {
    font-size: 20px;
    line-height: normal;
  }

  #service_inner_ui .all-developer .box {
    margin: 0;
  }
}

@media (max-width: 1200px) {
  #Services_slide .slider_slid .slide_head {
    font-size: 30px;
    line-height: normal;
  }
  #service_inner_ui .all-developer .box {
    margin: 0;
  }
  .time-tebimation h2 button {
    font-size: 30px;
  }
  #service_inner_ui .all-developer .box {
    width: calc(50% - 36px);
    margin: 0 0 0 0;
  }
  .benefits-content h2,
  .service-content h2 {
    font-size: 36px;
    line-height: normal;
  }
  .benefits-main-text p {
    font-size: 22px;
  }
  .benefits-content p {
    font-size: 16px;
    line-height: normal;
  }
  .artical-card-head p {
    line-height: normal;
  }
  .artical-card-head {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 768px) {
  /* === Service One === */

  .service-content h2 {
    font-size: 35px;
  }

  .service-content h4 {
    font-size: 20px;
  }

  /* === Services Two === */

  .technologies_content .slider_slid .slide_body {
    font-size: 20px;
    line-height: 30px;
  }

  #Services_slide .slider_slid .row {
    margin-top: 0;
  }

  /* === Service Four === */

  .time-tebimation h2 button {
    font-size: 30px;
    line-height: 30px;
  }

  .time-text h2 {
    font-size: 30px;
  }

  .accordion-body {
    padding: 0;
  }

  /* === Service Five === */

  .benefits-content h2 {
    font-size: 32px;
    line-height: 50px;
  }

  /* === Service Seven === */

  .artical-card {
    width: 48%;
    margin-top: 30px;
  }

  .artical-card .artical-card-img {
    margin-bottom: 20px;
  }

  .artical-card-head {
    margin-bottom: 10px;
  }

  .artical-card-head p {
    font-size: 14px;
    line-height: 20px;
  }

  .artical-card-title h3 {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 10px;
  }

  .artical-card-date {
    font-size: 14px;
    line-height: 20px;
  }

  .accordion-header {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .accordion-header:last-child {
    margin-right: 0;
  }

  #service_inner_ui .all-developer .box {
    width: 100%;
  }
}

@media (max-width: 450px) {
  /* === Service Seven === */

  .artical-card {
    width: 100%;
    margin-top: 30px;
  }

  .time-tebimation h2 button {
    font-size: 20px;
    line-height: 30px;
  }

  .accordion .row {
    margin: 0;
  }
  .accordion-header {
    margin-right: 10px;
  }
  .accordion-header:last-child {
    margin-right: 10px;
  }

  .artical-card-title h3 {
    font-size: 18px;
    line-height: normal;
  }
}
