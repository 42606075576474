:root {
  --atlaswhite: #ffffff;
  --atlaswhiteload: #00000048;
  --atlasgray: #f0f0f0;
  --atlaslitegray: #e6e6e6;
  --atlasblack: #212529;
  --atlasblue: #335b8d;
  --atlasdarkblue: #003271;
  --atlasdarkgreen: #006331;
  --atlasgreen: #a2ba5f;
  --f12: 12px;
  --f13: 13px;
  --f14: 14px;
  --f15: 15px;
  --f16: 16px;
  --f17: 17px;
  --f18: 18px;
  --f19: 19px;
  --f20: 20px;
  --f24: 24px;
  --f50: 50px;
  --f30: 30px;
  --f94: 94px;
}

.dark-mode {
  --atlaswhite: #000000;
  --atlaswhiteload: #ffffff48;
  --atlasgray: #2c2c2c;
  --atlaslitegray: #616161;
  --atlasblack: #f4f9ff;
  --atlasblue: #335b8d;
  --atlasdarkblue: #003271;
  --atlasdarkgreen: #006331;
  --atlasgreen: #a2ba5f;
  --f12: 12px;
  --f13: 13px;
  --f14: 14px;
  --f15: 15px;
  --f16: 16px;
  --f17: 17px;
  --f18: 18px;
  --f19: 19px;
  --f20: 20px;
  --f24: 24px;
  --f50: 50px;
  --f30: 30px;
  --f94: 94px;
}

:focus-visible {
  outline: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 3px;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb {
  background: var(--atlasgreen);
  border-radius: 50px;
}
body {
  font-family: "Poppins", sans-serif;
  font-size: var(--f16);
  font-weight: 400;
  color: var(--atlasblack);
  background-color: var(--atlaswhite);
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.graybg {
  background: var(--atlasgray);
}
.graylitebg {
  background: var(--atlaslitegray);
}
.atlasdark {
  background: var(--atlasblack);
}
.text-blue {
  color: var(--atlasdarkblue);
}
.text-green {
  color: var(--atlasgreen);
}
.f16 {
  font-size: 16px;
}
#tidio-chat #tidio-chat-iframe {
  inset: auto 30px 0px auto !important;
}
@media (max-width: 767px) {
  #tidio-chat #tidio-chat-iframe {
    inset: auto 0px 0px auto !important;
  }
}
.modal {
  z-index: 1060000000;
}

.slick-dots {
  position: relative !important;
}

.height-animation {
  min-height: 100vh;
  height: 100vh;
}

/* Loader css start */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*loader css end */
/*== Button ==*/
.btn-primary {
  color: #fff;
  background-color: var(--atlasblack);
  border-color: var(--atlasblack);
  border-radius: 100px;
  font-weight: 600;
  text-decoration: none;
  padding: 8px 20px;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary.active {
  background: var(--atlasgreen) !important;
  color: #ffffff !important;
  border-color: var(--atlasgreen) !important;
  box-shadow: none !important;
}
.btn-outline-primary {
  color: var(--atlasblack);
  border-color: var(--atlasblack);
  border-radius: 100px;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  padding: 8px 20px;
  line-height: 35px;
  padding: 5px 30px 5px 45px;
  position: relative;
  transition: all 0.5s;
}
.btn-outline-primary svg {
  width: 30px;
  height: 30px;
  background: var(--atlasblue);
  border-radius: 50%;
  padding: 10px;
  position: absolute;
  left: 2%;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s;
  stroke-width: 3px;
}
.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
  background: var(--atlasblue);
  color: #ffffff;
  border-color: var(--atlasblue);
  box-shadow: none;
  padding: 5px 45px 5px 30px;
}
.btn-outline-primary:hover svg,
.btn-outline-primary:active svg,
.btn-outline-primary:focus svg,
.btn-outline-primary:not(:disabled):not(.disabled):active svg,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus svg {
  background: var(--atlasblack);
  left: calc(100% - 35px);
}
.btn-white {
  background: var(--atlaswhite);
}
.btn-white:hover,
.btn-white:active,
.btn-white:focus,
.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled):active:focus {
  background: var(--atlasblue);
}
.btn-white svg,
.btn-white:hover svg,
.btn-white:active svg,
.btn-white:focus svg,
.btn-white:not(:disabled):not(.disabled):active svg,
.btn-white:not(:disabled):not(.disabled):active:focus svg {
  background: #acbde1;
}

.btn-black {
  background: var(--atlasblack);
  color: var(--atlaswhite);
  line-height: 20px;
}
.btn-black:hover,
.btn-black:active,
.btn-black:focus,
.btn-black:not(:disabled):not(.disabled):active,
.btn-black:not(:disabled):not(.disabled):active:focus {
  background: var(--atlasblue);
}
.btn-black svg,
.btn-black:hover svg,
.btn-black:active svg,
.btn-black:focus svg,
.btn-black:not(:disabled):not(.disabled):active svg,
.btn-black:not(:disabled):not(.disabled):active:focus svg {
  background: var(--atlasgreen);
}
@media (min-width: 768px) {
  .btn-outline-primary {
    font-size: 18px;
    padding: 7px 30px 7px 45px;
  }

  .btn-outline-primary:hover,
  .btn-outline-primary:active,
  .btn-outline-primary:focus,
  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus {
    padding: 7px 45px 7px 30px;
  }
  .btn-outline-primary svg {
    width: 30px;
    height: 30px;
  }
  .btn-outline-primary:hover svg,
  .btn-outline-primary:active svg,
  .btn-outline-primary:focus svg,
  .btn-outline-primary:not(:disabled):not(.disabled):active svg,
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus svg {
    left: calc(100% - 35px);
  }
}
@media (min-width: 992px) {
  .btn-outline-primary {
    font-size: 20px;
    padding: 7px 30px 7px 55px;
  }
  .btn-outline-primary:hover,
  .btn-outline-primary:active,
  .btn-outline-primary:focus,
  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus {
    padding: 7px 55px 7px 30px;
  }
  .btn-outline-primary svg {
    width: 36px;
    height: 36px;
  }
  .btn-outline-primary:hover svg,
  .btn-outline-primary:active svg,
  .btn-outline-primary:focus svg,
  .btn-outline-primary:not(:disabled):not(.disabled):active svg,
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus svg {
    left: calc(100% - 45px);
  }
  .btn-black {
    padding: 12px 30px 12px 55px !important;
  }
  .btn-black:hover,
  .btn-black:active,
  .btn-black:focus,
  .btn-black:not(:disabled):not(.disabled):active,
  .btn-black:not(:disabled):not(.disabled):active:focus {
    padding: 12px 55px 12px 30px !important;
  }
}
@media (min-width: 1700px) {
  .btn-outline-primary {
    font-size: 18px;
    padding: 7px 30px 7px 55px;
  }
  .btn-outline-primary svg {
    width: 40px;
    height: 40px;
  }
}
/*== Button End ==*/
@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.visibleelement {
  animation: fadeUp 1s ease-in-out; /* Adjust the duration and easing as needed */
}
/*== Back to top ==*/
#back-to-top {
  position: fixed;
  bottom: 100px;
  right: 57px;
  z-index: 1000;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #back-to-top {
    right: 20px;
  }
}
#back-to-top a {
  color: var(--atlaswhite);
  text-align: center;
  line-height: 33px;
  text-decoration: none;
  display: block;
  height: 2.25rem;
  width: 2.25rem;
  font-size: 1.25rem;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -webkit-transition-duration: 0.6s;
  -moz-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-box-shadow: 0px 0px 0px 5px var(--atlasblue);
  -moz-box-shadow: 0px 0px 0px 5px var(--atlasblue);
  box-shadow: 0px 0px 0px 5px var(--atlasblue);
  background: var(--atlasgreen);
}
#back-to-top a:hover {
  background-color: var(--atlasblack);
  color: var(--atlaswhite);
}
@media (max-width: 767px) {
  #back-to-top {
    right: 28px;
  }
}
/*== Back to top End ==*/
/*== Header css start ==*/
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: var(--atlasgreen);
}
header .navbar-nav .dropdown {
  position: static;
}
.navbar-light .navbar-nav .nav-link {
  color: var(--atlasblack);
  font-weight: 600;
  padding: 0.5rem 0rem;
  position: relative;
}
.dropdown-item {
  color: var(--atlasblack);
  font-weight: 600;
}
.dropdown-item.active,
.dropdown-item:active {
  background: var(--atlaswhite);
  color: var(--atlasblack);
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: var(--atlasgreen);
}
.dropdown-toggle::after {
  background: transparent;
  border: solid var(--atlasgreen);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  padding: 3px;
  transform-origin: 8px 1px;
  position: absolute;
  right: 3px;
  top: 17px;
}
.show.dropdown-toggle::after,
.navbar-light .navbar-nav .nav-link:focus::after,
.navbar-light .navbar-nav .nav-link:hover::after {
  background: transparent;
  border: solid var(--atlasgreen);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  padding: 3px;
  transform-origin: 8px 1px;
}
.sticky-nav {
  border-radius: 50px;
  margin-top: 1rem;
  transition: all 0.5s;
  box-shadow: 20px 20px 60px #0000001a;
}

.navbar-brand img {
  width: 100px;
}

.sticky-nav .navbar-brand img {
  width: 60px;
}

.mega-menu-box .accordion-header {
  margin-right: 0px;
}
.mega-menu-box .web-bevelopment {
  display: none;
}
.mega-menu-box button.accordion-button {
  padding: 15px 15px;
  position: relative;
  font-size: 14px;
}
.mega-menu-box button.accordion-button:after {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.mega-menu-box button.accordion-button img {
  width: 25px;
}
.mega-menu-box
  .accordion
  .accordion-item:not(:last-child)
  button.accordion-button {
  border-bottom: 1px solid var(--atlaswhite);
}
@media (min-width: 992px) {
  header .btn-primary {
    font-size: var(--f16);
  }
  .hoverotheritems.show {
    display: none !important;
  }
  .hoverotheritems.firsthoveritem.show {
    display: block !important;
  }
  .cmshover.show {
    display: block !important;
  }
  .mega-menu-box .web-bevelopment {
    display: block;
  }
}
@media (min-width: 1200px) {
  .dropdown-toggle::after {
    width: 5px;
    height: 5px;
    border: none;
    background: var(--atlasblack);
    position: absolute;
    right: 5px;
    top: 21px;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  .show.dropdown-toggle::after,
  .navbar-light .navbar-nav .nav-link:focus::after,
  .navbar-light .navbar-nav .nav-link:hover::after {
    background: transparent;
    border: solid var(--atlasgreen);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    padding: 3px;
    right: 3px;
    transform-origin: 8px 1px;
  }
  .navbar-light .navbar-nav .nav-link,
  .dropdown-item {
    font-size: var(--f14);
    line-height: 30px;
    transition: all 0.5s;
    padding: 0.5rem 1rem;
  }
  .sticky-nav.navbar-light .navbar-nav .nav-link,
  .sticky-nav .dropdown-item {
    font-size: var(--f14);
    transition: all 0.5s;
  }
  .navbar-brand img {
    width: 130px;
  }
  header .btn-primary {
    font-size: var(--f18);
  }
}
@media (min-width: 1400px) {
  .navbar-light .navbar-nav .nav-link,
  .dropdown-item {
    font-size: var(--f18);
    line-height: 30px;
    transition: all 0.5s;
  }
  .sticky-nav.navbar-light .navbar-nav .nav-link,
  .sticky-nav .dropdown-item {
    font-size: var(--f16);
    transition: all 0.5s;
  }
}
.header-navigation .navbar-nav .nav-link.nav-btn svg {
  margin-left: 10px;
  transform: rotate(130deg);
  transition: all 0.5s;
}
.header-navigation .navbar-nav .nav-link.nav-btn:hover svg {
  transform: rotate(180deg);
}
.header-navigation .navbar-nav .nav-link.nav-btn:hover svg path {
  fill: #ffffff;
}
button.navbar-toggler:focus:not(:focus-visible),
button.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
.navbar-toggler {
  padding: 0px;
  border: none;
}
.trigger {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  border: 2px solid #fff;
  border-radius: 10px;
  transform: translateX(-50%) translateY(-50%);
}

.bars {
  width: 60px;
  cursor: pointer;
}
.bars .line {
  fill: none;
  stroke: var(--atlasblue);
  stroke-width: 4;
  stroke-linecap: square;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
}
.bars .line.top {
  stroke-dasharray: 40 172;
}
.bars .line.middle {
  stroke-dasharray: 40 111;
}
.bars .line.bottom {
  stroke-dasharray: 40 172;
}
.bars.active .top {
  stroke-dashoffset: -132px;
}
.bars.active .middle {
  stroke-dashoffset: -71px;
}
.bars.active .bottom {
  stroke-dashoffset: -132px;
}
/*== Header css end ==*/

/*Footer css start*/

.blackbg {
  background: var(--atlasblack);
}
.footer-quicklink,
.footergray {
  color: #5f6367;
}
.quick-icon {
  position: relative;
  top: 6px;
  left: 10px;
}
.footer-contact-main {
  font-weight: 300;
}
.footer-contact-main a {
  color: var(--atlaswhite);
}
.footer-contact-main:hover a {
  color: var(--atlasgreen);
}
.footer-contact-main .footer-contact-icon {
  width: 25px;
  flex: 0 0 auto;
  margin-right: 10px;
}
.footer-list {
  list-style: none;
  padding-left: 0;
  font-weight: 300;
  margin-bottom: 0;
}
.footer-list li {
  position: relative;
  padding-left: 1rem;
}
.footer-list li:not(:last-child) {
  margin-bottom: 0.5rem;
}
.footer-list li::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--atlasgreen);
  position: absolute;
  top: 11px;
  left: 0;
}
.footer-contact-details p,
.footer-last-menu li a,
.footer-list li a {
  border-bottom: 1px solid transparent;
  transition: all 0.4s;
}
.footer-contact-details p:hover,
.footer-last-menu li a:hover,
.footer-list li a:hover {
  border-bottom: 1px solid var(--atlasgreen);
  color: var(--atlasgreen) !important;
}
.footer-last-menu {
  padding-left: 0px;
  list-style: none;
  margin-bottom: 0;
}
.footer-last-menu li {
  padding: 0px 10px;
  display: inline-block;
  margin-bottom: 5px;
}
.footer-last-menu li a {
  font-size: 18px;
}
.footer-last-menu li:not(:first-child) {
  border-left: 1px solid #5f6367;
}
.footer-last-menu a {
  color: #5f6367;
  text-decoration: none;
}
.copytext-main {
  position: absolute;
  left: 10px;
  transform: rotate(-90deg);
  transform-origin: left;
  bottom: 50px;
  color: var(--atlaswhite);
  z-index: 0;
  display: flex;
  justify-content: start;
  align-items: center;
}
.copytext-main p {
  color: var(--atlaswhite);
  margin: 0;
  margin-right: 10px;
  font-size: 16px;
}
.customzindex {
  z-index: 10;
}
.footer-contact-details p {
  color: var(--atlaswhite);
}
footer p {
  margin: 0;
}
/*Footer css end*/
.page-title h2 {
  font-weight: bold;
}

.page-title {
  font-size: 28px;
  line-height: 32px;
}
.sub-title {
  font-size: var(--f17);
  line-height: 24px;
}
.section-spacing {
  padding: 50px 0px;
}
.f94 {
  font-size: 44px;
  line-height: 50px;
  font-weight: bold;
}

/* === Spinner === */
.spinner-border {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
/* === Spinner === */

/* === Model Form === */
#quoteSubmit h4,
#NewsModal h4,
div#HeaderFormModal #quoteForm h4 {
  font-size: 24px;
  color: var(--atlasgreen);
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
}
#NewsModal form,
div#HeaderFormModal form {
  width: 70%;
  margin: 0 auto;
}
#quoteSubmit .form-group,
#NewsModal form .form-group,
div#HeaderFormModal form .form-group {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

#quoteSubmit .form-group label {
  width: max-content;
  margin-right: 20px;
}

#quoteSubmit .error-messages,
#NewsModal form .error-messages,
div#HeaderFormModal form#quoteSubmit .error-messages {
  width: 70%;
  text-align: end;
}
.recaptcha-error-messages {
  width: 100% !important;
}
.recaptcha-error-messages .google-recaptchacenter > div {
  display: flex;
  justify-content: center;
}
#NewsModal form .error-messages p,
div#HeaderFormModal form#quoteSubmit .error-messages p {
  margin: 0;
  line-height: 20px;
  display: block !important;
}

#NewsModal form .recaptcha-error-messages p,
form#quoteSubmit .recaptcha-error-messages p {
  text-align: center;
}
#NewsModal form .recaptcha-error-messages .recaptcha,
form#quoteSubmit .recaptcha-error-messages .recaptcha {
  margin-bottom: 10px;
}
#NewsModal
  form
  .recaptcha-error-messages
  form#quoteSubmit
  .recaptcha-error-messages {
  text-align: center;
  width: 100% !important;
  margin-bottom: 30px;
}
#NewsModal form .recaptcha div#HeaderFormModal .recaptcha {
  margin-top: 40px;
  /* position: absolute;
  bottom: 5px;
  left: 6px; */
}

div#HeaderFormModal .recaptcha div {
  display: flex;
  justify-content: center;
}
div#NewsModal .submit_button,
div#HeaderFormModal .submit_button {
  text-align: center;
}

div#HeaderFormModal .submit_button .btn-primary{
  color: var(--atlaswhite);
}

div#HeaderFormModal .submit_button p{
  color: var(--atlasblack);
  font-size: var(--f14);
  font-weight: bold;
  line-height: 18px;
}

div#HeaderFormModal .submit_button p a {
  display: block;
  width: fit-content;
  margin: 0 auto;
}

#quoteSubmit .form-group label,
div#NewsModal form .form-group label,
div#HeaderFormModal form .form-group label {
  font-size: 18px;
  font-weight: 500;
  margin-right: 20px;
}

#quoteSubmit .form-group .services-select label {
  margin: 0;
}

#quoteSubmit .form-group input:focus,
#quoteSubmit .form-group input:focus-visible,
#quoteSubmit .form-group input:hover,
div#NewsModal form .form-group input:focus,
div#NewsModal form .form-group input:focus-visible,
div#NewsModal form .form-group input:hover,
div#HeaderFormModal form .form-group input:focus,
div#HeaderFormModal form .form-group input:focus-visible,
div#HeaderFormModal form .form-group input:hover {
  box-shadow: unset;
  outline: 0;
  border: 0px solid var(--atlasgreen);
  border-bottom-width: 2px;
}

input:-internal-autofill-selected {
  background-color: unset !important;
}

#quoteSubmit .form-group input,
#quoteSubmit .form-group input,
#NewsModal form .form-group input,
div#HeaderFormModal form .form-group input {
  width: 100%;
  font-size: 18px;
  padding: 2px 10px;
  border-radius: 0;
  border: 0px solid #e6e6e6;
  color: var(--atlasgreen);
  border-bottom-width: 2px;
  font-weight: 600;
  transition: all 0.4s;
}

form {
  border: none !important;
}
/* === Model Form === */
@media (min-width: 768px) {
  .navbar-collapse {
    flex-grow: initial;
  }
  body {
    font-size: var(--f18);
    line-height: 27px;
  }
  .page-title {
    font-size: 42px;
    line-height: 50px;
  }
  .sub-title {
    font-size: 24px;
    line-height: 30px;
  }
  .section-spacing {
    padding: 50px 0px;
  }
  .f94 {
    font-size: 54px;
    line-height: 80px;
  }
}
@media (min-width: 992px) {
  .section-spacing {
    padding: 70px 0px;
  }
  .page-title {
    font-size: 40px;
    line-height: 50px;
  }
  .sub-title {
    font-size: 22px;
    line-height: 30px;
  }
  .hs-service h2,
  .hs-service h4 {
    font-size: 50px;
    padding-left: 120px;
  }
  .f94 {
    font-size: 60px;
    line-height: 80px;
  }
}
@media (min-width: 1400px) {
  .f94 {
    font-size: 74px;
    line-height: 110px;
  }
  .section-spacing {
    padding: 130px 0px;
  }
  .page-title {
    font-size: 46px;
    line-height: 54px;
  }
}

@media (min-width: 1700px) {
  .page-title {
    font-size: 50px;
    line-height: 60px;
  }
  .sub-title {
    font-size: 24px;
    line-height: 36px;
  }
}

ol,
ul {
  padding: 0 !important;
}
a {
  text-decoration: none !important;
}
.mega-menu-box .dropdown:hover .dropdown-menu {
  display: flex;
}
.mega-menu-box .dropdown-menu.show {
  display: flex;
}
.mega-menu-box.dropdown-menu {
  border-radius: 0px;
  padding: 0;
  overflow: hidden;
  background: #e6e6e6;
  top: 55px;
  /* top: 70px; */
  border: none;
  width: auto;
  min-width: 1130px;
  max-height: 90vh;
  overflow: auto;
}
.sticky-nav .mega-menu-box.dropdown-menu {
  top: 43px;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.mega-menu-box .nav-tabs .nav-item.show .nav-link,
.mega-menu-box .nav-tabs .nav-link.active {
  background-color: #003271;
}
.navbar-expand-xl .navbar-nav {
}
.nav-sub-menu .nav-tabs .nav-link.active {
  background-color: transparent;
}

.nav-sub-menu .nav-tabs .nav-link.actives {
  background-color: #335b8d;
  color: white;
}

.mega-menu-box .nav-tabs {
  border: none;
}
.mega-menu-box .nav-list {
  padding: 20px 0 !important;
  white-space: nowrap;
  flex: 0 0 42%;
  min-width: 42%;
  background: var(--atlasgreen);
}
.mega-menu-box .tab-content {
  flex: 0 0 58%;
  min-width: 58%;
}
.mega-menu-box .tab-content {
  background: #cfdbae;
}
.mega-menu-box .nav-sub-menu {
  flex: 0 0 70%;
  min-width: 70%;
  justify-content: space-between;
}
.mega-menu-box .nav-list ul li img {
  margin-right: 24px;
}
.mega-menu-box .nav-link {
  padding: 6px 20px !important;
  border: none !important;
}
.mega-menu-box .nav-pills a {
  font-size: 16px;
  padding: 11.5px 25px;
  color: #212529;
  text-decoration: none;
  white-space: nowrap;
  display: block;
  transition: all 0.4s;
}
.mega-menu-box .nav-pills li:hover a {
  color: #ffffff;
}
.mega-menu-box .tab-content .nav-pills {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.mega-menu-box .tab-content .nav-pills li {
  flex: 0 0 50%;
}
.mega-menu-box .web-bevelopment {
  padding: 60px 30px;
  flex: 0 0 30%;
  min-width: 30%;
  background: var(--atlasblue);
}
.mega-menu-box .web-bevelopment h5 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #fff;
}
.mega-menu-box .web-bevelopment p {
  font-size: 15px;
  color: #fff;
  line-height: 23px;
}
.mega-menu-box .tab-content > .active {
  display: block;
  padding: 30px 0;
  height: 100%;
}
.mega-menu-box .dropdown-menu {
  border: none;
}
.mega-menu-box ul li img {
  height: 20px;
  fill: #fff;
  stroke: #fff;
  margin-right: 10px;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff !important;
}
.mega-menu-box .web-bevelopment .comman-btn {
  margin-top: 60px;
  white-space: nowrap;
}
.mega-menu-box .fade {
  transition: none;
}
.mega-menu-box .fade ul li {
  opacity: 0;
  transition: opacity 1s linear;
}
.mega-menu-box .fade.show.active ul li {
  opacity: 1;
  transition: opacity 1s linear;
}
.mega-menu-box .nav-list ul li img {
  margin-right: 15px;
}
.mega-menu-box .accordion-item {
  background: transparent !important;
}
.mega-menu-box .accordion-body {
  padding: 0;
}

/* Dropdown menu */

.dropdown-menu {
  border: none;
  border-radius: 10px;
  background: #335b8d;
  color: white;
}
.dropdown-menu li {
  border-radius: 0px;
  transition: all 0.4s;
}

.dropdown-item:hover {
  color: unset;
  background-color: unset;
  border-radius: 5px;
}

.dropdown-menu li:hover {
  color: white;
  background-color: #335b8d;
}

#myTabContent.tab-content li a img {
  transition: all 0.4s;
}

#myTabContent.tab-content li:hover a img {
  filter: brightness(1) invert(1);
  -webkit-filter: brightness(1) invert(1);
}

.mega-menu-box .nav-tabs .nav-link {
  color: var(--atlaswhite);
}

/* New Header */

.about-dropdown {
  overflow: hidden;
}

.about-dropdown ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-width: 576px;
  background-color: var(--atlasblack);
  padding: 30px !important;
}

.about-dropdown ul li {
  width: 50%;
  color: white;
  display: flex;
  justify-content: start;
  align-items: center;
}

.about-dropdown ul li a {
  color: white;
}

.about-dropdown .about-menu-details {
  background: var(--atlasgreen);
  color: white;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about-dropdown .about-menu-details div:nth-child(2) svg {
  transform: rotate(180deg);
}
.about-dropdown .about-menu-details div:nth-child(2) {
  margin-left: 30px;
}

.about-dropdown .about-menu-details p {
  color: white;
  font-size: 20px;
  line-height: 33px;
  margin-bottom: 0;
  margin-top: 10px;
}

.about-dropdown .about-menu-details h5 {
  font-size: 24px;
  font-weight: bold;
  line-height: 35px;
}

.about-dropdown li:hover {
  color: var(--atlasgreen);
  background-color: transparent;
}

nav .btn-primary {
  font-size: 12px;
  padding: 8px 8px;
}

@media (min-width: 1200px) {
  .mega-menu-box.dropdown-menu {
    left: 50%;
    transform: translate3d(-50%, 10px, 0);
  }
  nav .btn-primary {
    font-size: 18px;
    padding: 8px 20px;
  }
}

@media (min-width: 768px) {
  nav .btn-primary {
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  .mega-menu-box.dropdown-menu {
    border-radius: 25px;
  }
  .right_section {
    right: 50px;
  }
  .copytext-main {
    left: 50px;
  }
}

/* New Header */

/* === side menu === */
.right_section {
  position: fixed;
  z-index: 12;
  right: 10px;
  bottom: 30px;
}

.get-in-touch img,
.right_section .social-icon img {
  width: 14px;
  height: 14px;
}

.get-in-touch-box {
  color: var(--atlasblack);
}

.right_section .social-icon:hover {
  transform: scaleX(-1);
}
.right_section .social-icon {
  transition: all 0.4s;
  margin-top: 10px;
}

.social-icons a {
  display: block;
  text-align: center;
}
.get-in-touch-box .get-in-touch-icon {
  display: block;
  text-align: center;
}

.get-in-touch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.get-in-touch-box svg {
  -webkit-transition: all 0.35s cubic-bezier(0, -0.04, 1, 1.08);
  -o-transition: all 0.35s cubic-bezier(0, -0.04, 1, 1.08);
  transition: all 0.35s cubic-bezier(0, -0.04, 1, 1.08);
}

.dark .get-in-touch-text:before {
  background: #000;
}
.get-in-touch-text:before {
  position: absolute;
  display: flex;
  align-items: center;
  content: "";
  height: 100%;
  background: #fff;
  width: 1px;
  right: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: -100%;
  -webkit-transition: 0.8s all cubic-bezier(0.46, 0.03, 0.52, 0.96);
  -o-transition: 0.8s all cubic-bezier(0.46, 0.03, 0.52, 0.96);
  transition: 0.8s all cubic-bezier(0.46, 0.03, 0.52, 0.96);
  opacity: 0.5;
}

.get-in-touch-text {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  color: var(--atlasblue);
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
/* === scroll === */
.scroll-down {
  position: fixed;
  bottom: 11px;
  top: unset;
  left: calc(50% - 6px);
  z-index: 1001;
  pointer-events: none;
}
.scroll-up {
  position: fixed;
  bottom: unset;
  top: calc(50% - 11px);
  left: calc(50% - 6px);
  z-index: 1001;
  pointer-events: none;
}
.dark-mode .scroll-up,
.dark-mode .scroll-down {
  left: 20%;
}
.scroll-down .scroll-down-text {
  filter: drop-shadow(0px 0px 0px white);
  color: white;
  font-size: 10px;
  letter-spacing: 9px;
  margin-left: 1px;
  opacity: 0.6;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: fadeScroll;
  animation-name: fadeScroll;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  -webkit-transform: translateY(-73px) rotate(-180deg);
  -ms-transform: translateY(-73px) rotate(-180deg);
  transform: translateY(-73px) rotate(-180deg);
}
.scroll-up .scroll-down-text {
  filter: drop-shadow(0px 0px 0px white);
  color: white;
  font-size: 10px;
  letter-spacing: 9px;
  margin-left: 1px;
  opacity: 0.6;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: fadeScroll;
  animation-name: fadeScroll;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  -webkit-transform: translateY(-3px) rotate(-180deg);
  -ms-transform: translateY(-3px) rotate(-180deg);
  transform: translateY(-3px) rotate(-180deg);
}
.scroll-down-icon .scroll-down-icon {
  width: 100%;
  text-align: center;
  animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  animation-name: scroll;
  display: inline-block;
  -webkit-transform: translateX(1px) translateY(-38px);
  -ms-transform: translateX(1px) translateY(-38px);
  transform: translateX(1px) translateY(-38px);
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: scroll;
  animation-name: scroll;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.scroll-up-icon .scroll-up-icon {
  width: 100%;
  text-align: center;
  animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  animation-name: scrollUp;
  display: inline-block;
  -webkit-transform: translateX(1px) translateY(-38px); /* Adjust translateY for the starting position */
  -ms-transform: translateX(1px) translateY(-38px);
  transform: translateX(1px) translateY(-38px);
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: scroll;
  animation-name: scroll;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
@keyframes fadeScroll {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
@keyframes scroll {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
    -webkit-transform: translateX(1px) translateY(-38px);
    transform: translateX(1px) translateY(-38px);
  }
  20% {
    opacity: 1;
  }
  25% {
    -webkit-transform: translateX(1px) translateY(0);
    transform: translateX(1px) translateY(0);
  }
  35% {
    opacity: 0;
    -webkit-transform: translateX(1px) translateY(-38px);
    transform: translateX(1px) translateY(-38px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(1px) translateY(-38px);
    transform: translateX(1px) translateY(-38px);
  }
}

/* === dot animation === */

.work_card-inner .dots span.green,
.choose_slide .dots span.green,
.box .dots span.green,
.dot-flex-testi .light-green-testi,
.perks-inner-section:hover .dot-sec,
.dot-animation:hover .dot-sec,
.dot-flex-testi .testidot1 {
  transform: translateY(0);
  transition: all 0.4s;
}

.work_card-inner .dots span.black,
.choose_slide .dots span.black,
.box .dots span.black,
.dot-flex-testi .green-testi,
.perks-inner-section:hover .dot-sec1,
.dot-animation:hover .dot-sec1,
.dot-flex-testi .testidot2 {
  transform: translateY(0);
  transition: all 0.4s;
}

.work_card-inner .dots span.blue,
.choose_slide .dots span.blue,
.box .dots span.blue,
.dot-flex-testi .blue-testi,
.perks-inner-section:hover .dot-sec2,
.dot-animation:hover .dot-sec2,
.dot-flex-testi .testidot3 {
  transform: translateY(0);
  transition: all 0.4s;
}

/* === hover dot animation === */

.work_card-inner:hover .dots span.green,
.choose_slide:hover .dots span.green,
.box:hover .dots span.green,
.perks-inner-section:hover .dot-sec,
.dot-animation:hover .dot-sec,
.dot-animation:hover .dot-flex-testi .light-green-testi,
.dot-animation:hover .dot-flex-testi .testidot1 {
  animation: dot-typing1 1s infinite linear;
}

.work_card-inner:hover .dots span.black,
.choose_slide:hover .dots span.black,
.box:hover .dots span.black,
.perks-inner-section:hover .dot-sec1,
.dot-animation:hover .dot-sec1,
.dot-animation:hover .dot-flex-testi .green-testi,
.dot-animation:hover .dot-flex-testi .testidot2 {
  animation: dot-typing2 1s infinite linear;
}

.work_card-inner:hover .dots span.blue,
.choose_slide:hover .dots span.blue,
.box:hover .dots span.blue,
.perks-inner-section:hover .dot-sec2,
.dot-animation:hover .dot-sec2,
.dot-animation:hover .dot-flex-testi .blue-testi,
.dot-animation:hover .dot-flex-testi .testidot3 {
  animation: dot-typing3 1s infinite linear;
}
@keyframes dot-typing1 {
  0% {
    /* box-shadow: 9984px 0 0 0 #9880ff; */
    transform: translateY(0);
  }
  16.667% {
    /* box-shadow: 9984px -10px 0 0 #9880ff; */
    transform: translateY(-10px);
  }
  33.333% {
    /* box-shadow: 9984px 0 0 0 #9880ff; */
    transform: translateY(0);
  }
  50% {
    /* box-shadow: 9984px 0 0 0 #9880ff; */
    transform: translateY(0);
  }
  66.667% {
    /* box-shadow: 9984px 0 0 0 #9880ff; */
    transform: translateY(0);
  }
  83.333% {
    /* box-shadow: 9984px 0 0 0 #9880ff; */
    transform: translateY(0);
  }
  100% {
    /* box-shadow: 9984px 0 0 0 #9880ff; */
    transform: translateY(0);
  }
}

@keyframes dot-typing2 {
  0% {
    /* box-shadow: 9999px 0 0 0 #9880ff; */
    transform: translateY(0);
  }
  16.667% {
    /* box-shadow: 9999px 0 0 0 #9880ff; */
    transform: translateY(0);
  }
  33.333% {
    /* box-shadow: 9999px 0 0 0 #9880ff; */
    transform: translateY(0);
  }
  50% {
    /* box-shadow: 9999px -10px 0 0 #9880ff; */
    transform: translateY(-10px);
  }
  66.667% {
    /* box-shadow: 9999px 0 0 0 #9880ff; */
    transform: translateY(0);
  }
  83.333% {
    /* box-shadow: 9999px 0 0 0 #9880ff; */
    transform: translateY(0);
  }
  100% {
    /* box-shadow: 9999px 0 0 0 #9880ff; */
    transform: translateY(0);
  }
}
@keyframes dot-typing3 {
  0% {
    /* box-shadow: 10014px 0 0 0 #9880ff; */
    transform: translateY(0);
  }
  16.667% {
    /* box-shadow: 10014px 0 0 0 #9880ff; */
    transform: translateY(0);
  }
  33.333% {
    /* box-shadow: 10014px 0 0 0 #9880ff; */
    transform: translateY(0);
  }
  50% {
    /* box-shadow: 10014px 0 0 0 #9880ff; */
    transform: translateY(0);
  }
  66.667% {
    /* box-shadow: 10014px 0 0 0 #9880ff; */
    transform: translateY(0);
  }
  83.333% {
    /* box-shadow: 10014px -10px 0 0 #9880ff; */
    transform: translateY(-10px);
  }
  100% {
    /* box-shadow: 10014px 0 0 0 #9880ff; */
    transform: translateY(0);
  }
}

@media screen and (max-width: 479px) {
  .work_card-inner .dots span.green,
  .choose_slide .dots span.green,
  .box .dots span.green,
  .dot-flex-testi .light-green-testi,
  .perks-inner-section .dot-sec,
  .dot-animation .dot-sec,
  .dot-flex-testi .testidot1,
  .work_card-inner .dots span.black,
  .choose_slide .dots span.black,
  .box .dots span.black,
  .dot-flex-testi .green-testi,
  .perks-inner-section .dot-sec1,
  .dot-animation .dot-sec1,
  .dot-flex-testi .testidot2,
  .work_card-inner .dots span.blue,
  .choose_slide .dots span.blue,
  .box .dots span.blue,
  .dot-flex-testi .blue-testi,
  .perks-inner-section .dot-sec2,
  .dot-animation .dot-sec2,
  .dot-flex-testi .testidot3 {
    width: 12px !important;
    min-width: 12px !important;
    height: 12px !important;
    margin-left: 6px !important;
  }
}
/* === scroll === */
/* === side menu === */

/* Dropdown menu */

@media (max-width: 1919px) {
  .mega-menu-box .nav-link {
    padding: 16px 20px;
  }
  .mega-menu-box .web-bevelopment .comman-btn {
    margin-top: 40px;
  }
  .mega-menu-box .web-bevelopment p {
    line-height: 34px;
  }
}

@media (max-width: 1599px) {
  .mega-menu-box .web-bevelopment {
    padding: 40px 20px;
  }
  .mega-menu-box .web-bevelopment .comman-btn {
    margin-top: 40px;
  }
}

@media screen and (max-width: 1439px) {
  .mega-menu-box .nav-pills a {
    font-size: 14px;
  }
}

@media screen and (max-width: 1365px) {
  .mega-menu-box .web-bevelopment {
    flex: 0 0 25%;
    min-width: 25%;
  }
  .mega-menu-box .nav-list {
    flex: 0 0 30%;
    min-width: 30%;
  }
  .mega-menu-box .tab-content > .active {
    padding: 20px 0;
  }
  .mega-menu-box .web-bevelopment {
    padding: 30px 20px;
  }
}

@media screen and (max-width: 1279px) {
  .mega-menu-box .tab-content .nav-pills {
    flex-direction: column;
  }
  .mega-menu-box .web-bevelopment {
    flex: 0 0 30%;
    min-width: 30%;
  }
  .mega-menu-box .nav-sub-menu {
    flex: 0 0 70%;
    min-width: 70%;
  }
  .mega-menu-box .nav-list {
    flex: 0 0 45%;
    min-width: 45%;
  }

  .mega-menu-box ul li img {
    height: 20px;
  }
  .mega-menu-box .web-bevelopment .comman-btn {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .mega-menu-box.dropdown-menu {
    min-width: auto;
  }
  /* Menubar Height */

  div#navbarSupportedContent {
    max-height: 90vh;
    overflow-x: auto;
  }

  /* Menubar Height */
}
@media (max-width: 1199px) {
  /* media nav bar */
  .sticky-nav {
    position: relative;
  }
  header .sticky-nav div#navbarSupportedContent {
    position: absolute;
    top: calc(100% + 10px);
    background: var(--atlasgray);
    width: 100%;
    right: 0;
    border-radius: 40px;
    padding: 20px;
    box-shadow: 20px 20px 60px #0000001a;
    transition: all 0.4s;
  }
  header div#navbarSupportedContent {
    position: relative;
    padding: 20px;
    transition: all 0.4s;
  }
  /* media nav bar */
}
@media screen and (max-width: 1023px) {
  .mega-menu-box .nav-sub-menu {
    flex: 0 0 65%;
    min-width: 65%;
  }
  .mega-menu-box .web-bevelopment {
    flex: 0 0 35%;
    min-width: 35%;
  }

  .mega-menu-box .nav-list ul li img {
    margin-right: 10px;
  }
}

@media screen and (max-width: 991px) {
  .mega-menu-box .nav-list {
    display: none;
  }
  .mega-menu-box .d-flex,
  .mega-menu-box .nav-sub-menu {
    flex-direction: column;
  }
  .mega-menu-box .nav-list,
  .mega-menu-box .web-bevelopment {
    flex: auto;
  }
  .mega-menu-box .dropdown-menu.dropdown-menu {
    width: auto;
  }
  .mega-menu-box button.accordion-button {
    background-color: #003271;
    color: #fff;
  }
  .mega-menu-box .dropdown-menu.dropdown-menu {
    background-color: #335b8d;
  }
  .mega-menu-box .accordion-button:after {
    filter: brightness(100);
  }
  .mega-menu-box .tab-content > .tab-pane {
    display: block;
    opacity: 1;
    margin: 0;
  }
  .mega-menu-box .fade ul li {
    opacity: 1;
  }
  .mega-menu-box .tab-content > .active {
    padding: 0;
  }
  .mega-menu-box .accordion-button:focus {
    border-color: transparent;
    box-shadow: none;
  }
  .mega-menu-box.dropdown-menu {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  nav .accordion-header {
    margin-bottom: 0px;
  }
  .footer-last-menu li a {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .mega-menu-box .web-bevelopment h5 {
    margin-bottom: 15px;
  }
  .mega-menu-box .nav-pills a {
    padding: 12px 15px;
    font-size: 13px;
  }
  .mega-menu-box ul li img {
    height: 15px;
  }
  .mega-menu-box .nav-link {
    padding: 12px 15px !important;
  }
  .mega-menu-box .nav-list {
    padding: 10px 0 !important;
  }
  .mega-menu-box .web-bevelopment {
    padding: 15px;
  }
  .mega-menu-box .web-bevelopment .comman-btn {
    margin-top: 5px;
  }
  .btn-outline-primary {
    line-height: 26px;
  }
  .dropdown-toggle::after,
  .show.dropdown-toggle::after,
  .navbar-light .navbar-nav .nav-link:focus::after,
  .navbar-light .navbar-nav .nav-link:hover::after {
    right: 13px;
    padding: 6px;
    top: 12px;
  }
}

@media screen and (max-width: 639px) {
  .mega-menu-box .nav-pills a {
    padding: 7px 15px;
  }
  .mega-menu-box .nav-link {
    padding: 7px 15px !important;
  }

  .scroll-bar {
    display: none;
  }
}

@media (max-width: 576px) {
  .copytext-main {
    position: unset;
    transform: unset;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 10px;
  }

  .copytext-main img {
    margin-left: 10px;
  }

  .right_section {
    background: var(--atlasblack);
    position: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }

  .right_section .social-icon {
    margin-right: 10px;
    margin-top: 0;
  }

  .right_section .social-icon:last-child {
    margin-right: 0;
  }

  .get-in-touch {
    flex-direction: row;
    align-items: center;
  }
  .get-in-touch-box {
    margin: 0 !important;
  }
  .get-in-touch-text {
    writing-mode: unset;
    transform: unset;
  }

  .social-icons-box .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .get-in-touch-box .get-in-touch-icon {
    margin-bottom: 0 !important;
    margin-right: 10px;
  }

  div#HeaderFormModal form {
    width: 90%;
    margin: 0 auto;
  }

  div#HeaderFormModal form .form-group {
    flex-wrap: wrap;
  }
  div#HeaderFormModal form .form-group label {
    font-size: 16px;
    margin-right: 0;
    width: 100%;
  }

  div#NewsModal form .form-group input,
  div#NewsModal
    form#quoteSubmit
    .error-messages
    div#HeaderFormModal
    form
    .form-group
    input,
  div#HeaderFormModal form#quoteSubmit .error-messages {
    width: 100%;
  }

  .footer-last-menu li {
    border-left: 1px solid #5f6367;
  }
}

@media (max-width: 320px) {
  .bars {
    width: 50px;
  }
}

.commoncta {
  position: relative;
}
/* .commoncta::before {
  content: "";
  width: 100%;
  height: 2px;
  background: var(--atlaswhite);
  position: absolute;
  left: 0;
  top: 0;
} */
.workcommonbeforenone .commoncta::before {
  display: none;
}
.commoncta .commoncta-img {
  height: 100%;
  object-fit: contain;
  object-position: top right;
}
/* @media screen and (min-width: 1320px) {
  .commoncta::before {
    width: 50%;
  }
} */

/* 29-2-2024 */
header.fixed-top nav {
  background-color: var(--atlaswhite) !important;
}
.btn {
  color: var(--atlasblack);
}
nav .btn-primary {
  color: var(--atlaswhite);
}

.dark-mode .latest_articles .latest_articles_card:hover a .blog-img,
.dark-mode .lifeatatlas,
.dark-mode .sticky-nav {
  box-shadow: 20px 20px 60px #ffffff1a;
}

.dark-mode .our-office-section .our-office li img,
.dark-mode .hsDotBox:before {
  filter: brightness(1) invert(10);
}

.dark-mode #recent-work .work_card-inner figure,
.dark-mode .slick-initialized .slick-list .slick-slide .item {
  background-color: var(--atlasblack);
}
.dark-mode div#HeaderFormModal .submit_button button.btn.btn-primary,
.dark-mode .slider_main .heading p,
.dark-mode .expert-mobile-services-section .services-box h6,
.dark-mode .expert-mobile-services-section .services-box p,
.dark-mode .expert-mobile-services-section .all-service.expertise li,
.dark-mode .slider_main .slider_body p {
  color: var(--atlaswhite);
}
.dark-mode .time-material .accordion-header:hover button,
.dark-mode .time-material button[aria-expanded="true"],
.dark-mode .aboutseven .slick-dots li button:before,
.dark-mode .join-team,
.dark-mode .blogdate,
.dark-mode #faq p,
.dark-mode .copytext-main p,
.dark-mode .since-title,
.dark-mode .first-life .atlas-life h3,
.dark-mode .footer-contact-details p,
.dark-mode .footer-contact-main a {
  color: var(--atlasblack);
}
.dark-mode .footer-contact-details p:hover,
.dark-mode [type="search"],
.dark-mode .footer-contact-details p:hover a {
  color: var(--atlasgreen);
}

.dark-mode .accordion-item .accordion-button,
.dark-mode .accordion-item,
.dark-mode .atlasdark,
.dark-mode .vacuum-container,
.dark-mode .expert-mobile-services-section,
.dark-mode .top-mobile-development-section,
.dark-mode .blackbg {
  background: var(--atlaswhite);
}

.dark-mode .domain-section,
.dark-mode .green-testi,
.dark-mode .about-bg-video:before,
.dark-mode .graybg {
  background: var(--atlaswhite);
}

.dark-mode .perks-inner-section .dot-sec1,
.dark-mode .green-testi {
  background-color: var(--atlasblack);
}

.dark-mode .hire-apply-job-section .box .dot-sec1,
.dark-mode #recent-work .work_card .dots span.black,
.dark-mode .services-box .dot-sec1,
.dark-mode .aboutseven .green-testi {
  background-color: #212529;
}

.dark-mode div#FormModal .btn-close,
.dark-mode .form-select,
.dark-mode .job-arrow-icon img,
.dark-mode .benefits-main-text,
.dark-mode .domain-inner-logo img,
.dark-mode .wordpress-box img {
  filter: brightness(0) invert(1);
}

.dark-mode .box-inner svg path {
  stroke: var(--atlasblack);
}

.dark-mode .create-experiences-section .contact-details .text img,
.dark-mode .mobile-services-section .developer-icon figure img,
.dark-mode .our-office-section .title-box .d-flex img {
  filter: brightness(0) invert(1);
}

.dark-mode .mobile-services-section .developer-icon figure:hover img {
  filter: unset;
}

.dark-mode .client-inner-logo img {
  background: #68686875;
}

.dark-mode .portfolio-button a svg g path,
.dark-mode .client-tebimation svg path {
  fill: var(--atlasblack);
}

.dark-mode #galleryModal .modal-content .modal-body button.btn svg path {
  fill: var(--atlaswhite);
}

.dark-mode #galleryModal .modal-content .modal-body button.btn:hover svg path {
  fill: var(--atlasblack);
}

.dark-mode #technologies_slider .slider-thumb .thumb-icon span,
.dark-mode #Services_slide .slider-thumb .thumb-icon span,
.dark-mode .graylitebg,
.dark-mode .expertise-section,
.dark-mode .accredited-by-section,
.dark-mode #faq,
.dark-mode #choose-accordion,
.dark-mode #choose-slider,
.dark-mode .our-office-section,
.form-control {
  background-color: var(--atlaswhite) !important;
}

.dark-mode .page-link {
  background: transparent;
}

.dark-mode .hire-apply-job-section .nav-item .nav-link:after {
  background-color: white;
}

.dark-mode #galleryModal .modal-content .btn-close {
  border: 4px solid var(--atlaswhite);
  background-color: var(--atlasblack);
}

.dark-mode #galleryModal .modal-content .modal-body button.btn:hover {
  border: 4px solid var(--atlasblack) !important;
}

/* Shimmer Loader */

.shimmer {
  position: relative;
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #f3f8fd;
  background: linear-gradient(to right, #c1dceb 4%, #8ad5fd 25%, #c1dceb 36%);
  background-size: 1200px 100%;
  box-shadow: 0 0 10px 1px var(--atlaswhiteload);
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}

#container {
  width: 500px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#square {
  width: 100%;
  height: 300px;
  border-radius: 12px;
}
.content {
  width: 100%;
}

#content {
  flex: 1;
  height: 300px;
  width: 100%;
  padding: 0.5rem 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#title {
  width: 100%;
  height: 50px;
  border-radius: 6px;
}

#desc {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 12px;
}

.line {
  width: 100%;
  height: 20px;
  border-radius: 4px;
}

/* Shimmer Loader */

/* Error Page */
.error-page-btn {
  color: var(--atlaswhite);
}
/* Error Page */
