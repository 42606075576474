#Thankyou {
    text-align: center;
  }
  
  #Thankyou h3 {
    font-size: 50px;
    font-weight: bold;
    color: var(--atlasgreen);
    margin-bottom: 30px;
  }
  
  #Thankyou p {
    font-size: 28px;
    font-weight: bold;
    color: var(--atlasblack);
    margin-bottom: 10px;
  }
  
  #Thankyou .link-thank {
    font-size: 24px;
    font-weight: 400;
    color: var(--atlasblack);
  }
  
  #Thankyou a {
    color: var(--atlasdarkblue);
    transition: all 0.4s;
  }
  
  #Thankyou a:hover {
    text-decoration: underline !important;
  }
  #check-group {
    animation: 0.32s ease-in-out 1.03s check-group;
    transform-origin: center;
}

#check-group #check {
    animation: 0.34s cubic-bezier(0.65, 0, 1, 1) 0.8s forwards check;
    stroke-dasharray: 0, 75px;
    stroke-linecap: round;
    stroke-linejoin: round;
}

#check-group #outline {
    animation: 0.38s ease-in outline;
    transform: rotate(0deg);
    transform-origin: center;
}

#check-group #white-circle {
    animation: 0.35s ease-in 0.35s forwards circle;
    transform: none;
    transform-origin: center;
}

@keyframes outline {
  from {
    stroke-dasharray: 0, 345.576px;
  }
  to {
    stroke-dasharray: 345.576px, 345.576px;
  }
}
@keyframes circle {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}
@keyframes check {
  from {
    stroke-dasharray: 0, 75px;
  }
  to {
    stroke-dasharray: 75px, 75px;
  }
}
@keyframes check-group {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.09);
  }
  to {
    transform: scale(1);
  }
}