.Career-section {
  padding: 50px 0;
}

.Career-content-inner h3 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #a2ba5f;
  line-height: 36px;
}

.Career-content-inner h2 {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: bold;
  color: #212529;
  line-height: 60px;
}

a.career-mail {
  border: 1px solid #212529;
  background: #212529;
  border-radius: 100px;
  padding: 8px 15px;
  text-decoration: none;
  transition: all 0.4s;
}

a.career-mail:hover {
  background: #ffffff;
}

a.career-mail:hover p {
  color: #212529;
}

.career-mail p {
  font-size: 18px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: white;
  line-height: 27px;
  margin-bottom: 0;
}

.career-mail {
  gap: 12px;
}

.career-phone {
  gap: 12px;
}

a.career-phone {
  border: 1px solid #212529;
  background: white;
  border-radius: 100px;
  padding: 8px 15px;
  text-decoration: none;
  transition: all 0.4s;
}

a.career-phone:hover {
  background: #212529;
}

a.career-phone:hover p {
  color: white;
}

.career-phone p {
  font-size: 18px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #212529;
  line-height: 27px;
  margin-bottom: 0;
  transition: all 0.4s;
}

.career-inner-icon {
  gap: 40px;
}

.wordpress-box img {
  width: 64px;
  height: 64px;
}

.wordpress-box h3 {
  font-size: 24px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  line-height: 45px;
  transition: all 0.4s linear;
}

.box-inner {
  gap: 10px;
}

.box-inner svg {
  width: 7px;
  height: 14px;
}

.box-inner p {
  font-size: 18px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  line-height: 30px;
  margin-bottom: 0;
  transition: all 0.4s linear;
}

.career-aplly {
  border: 1px solid transparent;
  background: #212529;
  border-radius: 100px;
  padding: 8px 15px;
  width: fit-content;
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
}

.career-aplly:hover {
  background-color: var(--atlasgreen);
}

.career-aplly a {
  font-size: 18px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: white;
  line-height: 27px;
  text-decoration: none;
}

.career-view {
  border: 1px solid #212529;
  background: white;
  border-radius: 100px;
  padding: 8px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
}

.career-view:hover {
  border: 1px solid white;
  background: #212529;
}

.career-view a {
  font-size: 18px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #212529;
  line-height: 27px;
  text-decoration: none;
  transition: all 0.4s;
}

.career-view:hover a {
  color: white;
}

.career-btn {
  gap: 14px;
}

.wordpress-box {
  position: relative;
  height: 100%;
}

.wordpress-box::before {
  content: "";
  border-radius: 25px 0px 0px 25px;
  background-color: #a2ba5f;
  width: 10px;
  height: 100%;
  position: absolute;
  transition: all 0.4s linear;
}

.wordpress-box:hover::before {
  width: 100%;
  transition: all 0.4s linear;
}

.wordpress-box:hover img {
  transition: all 0.4s linear;
}
.wordpress-box:hover img {
  filter: brightness(0) invert(1);
}

.padding-box {
  padding: 23px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.wordpress-box:hover h3,
.wordpress-box:hover p {
  color: white;
  transition: all 0.4s linear;
}

.wordpress-box:hover .box-inner svg path {
  stroke: white;
  transition: all 0.4s linear;
}

.black-box::before {
  background-color: #212529;
}

.blue-box::before {
  background-color: #003271;
}

/* job-section-start */

.job-section .life-content h3 p {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: bold;
  color: #212529;
  line-height: 60px;
}

.job-section .life-content h3 p .green {
  color: #a2ba5f;
}

.job-section .life-content h3 p .blue {
  color: #003271;
}

/* benefits-section-start  */

.perks-content h3 {
  font-family: "Poppins", sans-serif;
  font-size: 45px;
  font-weight: bold;
  color: var(--atlasblack);
  line-height: 50px;
}

.perks-content p {
  font-size: 20px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  line-height: 30px;
}

.perks-text {
  border: 1px solid transparent;
  border-radius: 25px;
  gap: 30px;
  padding: 20px 40px;
  height: 200px;
}

.box-green {
  background-color: #a2ba5f !important;
}

.green-active .perks-text {
  background-color: #a2ba5f;
}

.perks-inner-section {
  transition: all 0.4s;
}

.box-black {
  background-color: #212529;
}

.perks-text p {
  font-size: 24px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  line-height: 30px;
  margin-bottom: 0;
}

.dot-sec {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #a2ba5f;
  border: 1px solid transparent;
}

.perks-inner-section .dot-sec1 {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #212529;
  border: 1px solid transparent;
}

.dot-sec2 {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #003271;
  border: 1px solid transparent;
}

.dot-flex {
  gap: 5px;
}

.perks-content {
  position: sticky;
  top: 130px;
}

/* life-section-start */

.join-team-content h2 {
  font-size: 94px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  line-height: 130px;
}

.join-team-content h3 {
  font-size: 40px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #a2ba5f;
  line-height: normal;
}

.life-section {
  background-color: #212529;
}

.career-banner {
  position: relative;
}

.career-banner img {
  filter: grayscale(100%);
}

.career-banner::after {
  content: "";
  background: #000000;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  opacity: 41%;
  border-radius: 18px;
}

.life-section .play-icon {
  top: 20px;
  left: 40px;
}

.life-section .play-icon img {
  width: 48px;
  height: 48px;
}

.life-section .life-content {
  position: absolute;
  bottom: 40px;
  left: 40px;
}

.life-section .life-content h3 {
  font-size: 24px;
  font-weight: bold;
  color: white;
  font-family: "Poppins", sans-serif;
}

.article-text h3 {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: var(--nir-lightblack);
}

.arrow-icon {
  position: absolute;
  left: 11px;
  bottom: 0;
  top: 0;
  margin: auto;
  content: "";
  background: #acbde1;
  display: block;
  border: 1px solid transparent;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  transition: 0.4s all linear;
}

.arrow-icon img {
  z-index: 1;
  position: relative;
  left: 14px;
  top: 4px;
}

/* life-section-start */
.play-icon-life {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  width: 80px;
  height: 80px;
  transform: translateY(-50%);
}

.play-icon-life img {
  width: 84px;
  height: 84px;
}

.dark-mode .modal {
  filter: drop-shadow(2px 4px 6px white);
}

.dark-mode button.btn-close {
  filter: brightness(1) invert(1);
}

.modal-backdrop {
  z-index: 1 !important;
}

.modal-body {
  padding: 0 !important;
}

.modal-header {
  border-bottom: 1px solid transparent !important;
  padding: 0 !important;
}

.modal-header .btn-close {
  position: unset;
  opacity: 1 !important;
  flex: 0 0 30px;
}

.modal-title {
  display: none;
}

.modal-header {
  padding: 10px 20px !important;
  background-color: transparent;
  border: none !important;
}

.modal-body {
  background: transparent;
  padding: 10px !important;
}

.modal-content {
  background-color: transparent;
  border: none;
}

.modal.show .modal-dialog {
  transition: all 0.4s;
}

.modal-body iframe {
  height: 50vh;
}

.play-content h2 {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: bold;
  color: #212529;
  color: white;
}

.play-content p {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: normal;
  color: white;
  line-height: 40px;
}

.btn-close:focus {
  box-shadow: none !important;
}

.border-radius {
  border-radius: 40px;
}

.blog-image img {
  width: 100%;
  aspect-ratio: 4 / 2;
  object-fit: cover;
}

#careerForm .careers_heading {
  color: var(--atlasgreen);
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 50px;
}

#careerForm form {
  width: 70%;
  margin: 0 auto;
}

#careerForm .row {
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  align-items: center;
}

#careerForm label {
  font-size: 18px;
  font-weight: 500;
}

#careerForm .experience-card {
  padding: 0;
}
#careerForm label span {
  font-size: 16px;
  font-weight: 500;
}

#careerForm form .form-group {
  display: flex;
  justify-content: start;
  align-items: start;
  margin-bottom: 20px;
}

#careerForm form .error-messages {
  width: 60%;
}

#careerForm form .error-messages p {
  text-align: end;
  margin: 0;
  line-height: 20px;
}

#careerForm .recaptcha-error-messages p {
  text-align: center !important;
  line-height: 20px;
}
#careerForm .recaptcha-error-messages .recaptcha {
  margin-bottom: 10px;
}
#careerForm .recaptcha-error-messages {
  text-align: center;
  width: 100% !important;
  margin-bottom: 30px;
}

#careerForm form .form-group label {
  width: max-content;
  margin-right: 20px;
}

#careerForm .services-select {
  display: inline-block;
  position: relative;
}

#careerForm form .services-select label {
  margin: 0;
}

#careerForm .services-select input {
  width: 50%;
  background-color: transparent;
  transition: all 0.4s;
}

#careerForm .services-select input {
  position: absolute;
  cursor: pointer;
  opacity: 0;
  width: 100% !important;
  height: 100% !important;
}

#careerForm .select_butons .services-select label {
  font-size: 14px !important;
  padding: 4px 15px;
  font-weight: 400;
  line-height: normal;
}
#careerForm .select_butons label {
  width: 100% !important;
  margin-bottom: 10px;
}
#careerForm .select_butons {
  display: flex;
  flex-wrap: wrap;
}

#careerForm .select_butons .services-select {
  margin-bottom: 5px;
  margin-right: 5px;
}

.services-select input[type="radio"]:checked + label {
  background-color: var(--atlasgreen);
}

#careerForm form .form-group [type="file"]::-webkit-file-upload-button {
  background-color: var(--atlasgreen);
  color: var(--atlasblack);
  font-weight: bold;
  border-radius: 6px;
  margin: 0px;
  margin-right: 10px;
  font-size: 16px;
  padding: 2px 6px;
  transition: all 0.4s;
}

#careerForm form .form-group select,
#careerForm form .form-group input {
  width: 100%;
}

#careerForm .submit_button {
  text-align: center;
}
#careerForm .submit_button button {
  color: var(--atlaswhite);
}

#careerForm input::placeholder {
  color: var(--atlaslitegray);
  font-size: 18px;
  font-weight: 500;
}

#careerForm .recaptcha {
  margin: 40px 0 20px 0;
}

#careerForm .recaptcha div {
  display: flex;
  justify-content: center;
}

#careerForm .resume-upload {
  position: relative;
}

#careerForm .resume-file {
  opacity: 0;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

#careerForm .resume-upload input {
  border: 0px dashed var(--atlaslitegray);
  border-bottom-width: 2px;
  transition: all 0.4s;
  height: 80px;
}

#careerForm .resume-upload:hover input,
#careerForm .resume-upload:focus input {
  box-shadow: 5px 5px 30px #0000001a;
  border: 2px dashed var(--atlasgreen);
}

#careerForm textarea,
#careerForm select,
#careerForm input {
  font-size: 18px;
  padding: 2px 10px;
  border-radius: 0;
  border: 0px solid var(--atlaslitegray);
  border-bottom-width: 2px;
  font-weight: 600;
  color: var(--atlasgreen);
  transition: all 0.4s;
}

#careerForm input:hover,
#careerForm select:hover,
#careerForm textarea:hover,
#careerForm textarea:focus,
#careerForm select:focus,
#careerForm input:focus-visible,
#careerForm input:focus {
  box-shadow: unset;
  outline: none;
  border: 0px solid var(--atlasgreen);
  border-bottom-width: 2px;
}

div#NewsModal .btn-close,
div#HeaderFormModal .btn-close,
div#FormModal .btn-close {
  border-radius: 50%;
  padding: 5px;
  border: none;
  opacity: 1;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
  transition: all 0.4s;
}

div#NewsModal .btn-close:hover,
div#HeaderFormModal .btn-close:hover,
div#FormModal .btn-close:hover {
  transform: rotate(180deg);
}

#NewsModal .modal-body,
#HeaderFormModal .modal-body,
#FormModal .modal-body {
  padding: 30px 10px !important;
}

#NewsModal,
#HeaderFormModal,
#FormModal {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

#NewsModal .modal-dialog .modal-content,
#HeaderFormModal .modal-dialog .modal-content,
#FormModal .modal-dialog .modal-content {
  background-color: var(--atlaswhite);
  border-radius: 15px;
  box-shadow: 0px 0px 50px #000000b3;
}

@media (max-width: 1200px) {
  .perks-content h3 {
    font-size: 38px;
    line-height: 45px;
  }
}

@media (max-width: 1024px) {
  .perks-content p {
    font-size: 18px;
    font-weight: normal;
  }
  .perks-text p {
    font-size: 22px;
    line-height: normal;
  }
}

@media (max-width: 768px) {
  div#NewsModal .btn-close,
  div#HeaderFormModal .btn-close,
  div#FormModal .btn-close {
    left: unset;
    right: 10px;
    top: 10px;
  }
  .join-team-content h2 {
    font-size: 50px;
    line-height: 60px;
  }

  .perks-text .details {
    font-size: 18px;
  }

  .perks-text {
    padding: 20px;
  }

  .life-section .play-icon img {
    width: 28px;
    height: 28px;
  }
  .modal.show .modal-dialog {
    max-width: none;
  }

  .perks-content h3 {
    font-size: 32px;
    line-height: 50px;
  }

  .perks-content p {
    font-size: 18px;
    line-height: normal;
  }

  .perks-text p {
    font-size: 20px;
    line-height: normal;
  }
  .perks-text .details {
    font-size: 18px;
    line-height: normal;
  }

  .Career-content-inner h3 {
    font-size: var(--f20);
    margin-bottom: 20px;
  }
  .Career-content-inner h2 {
    font-size: 28px;
    line-height: 32px;
  }

  .wordpress-box h3 {
    font-size: 20px;
    line-height: normal;
    margin-top: 10px;
  }
  .job-section .life-content h3 p {
    font-size: 42px;
    line-height: normal;
  }
  .perks-text img {
    width: 80px;
  }
}

@media only screen and (max-width: 475px) {
  .perks-content h3 {
    font-size: 22px;
    line-height: normal;
  }

  .perks-content p {
    font-size: 16px;
    line-height: normal;
  }

  .perks-text {
    padding: 10px 20px;
  }

  .perks-text p {
    font-size: 20px;
    line-height: normal;
    margin-bottom: 10px;
  }

  .perks-text .details {
    font-size: 16px;
    line-height: normal;
  }
  .career-inner-icon {
    gap: 20px;
  }

  .box-inner p {
    font-size: 16px;
    line-height: normal;
  }
  .career-aplly {
    height: auto;
  }
  .career-aplly a,
  .career-view a {
    font-size: 16px;
    line-height: normal;
  }
  .job-section .life-content h3 p {
    font-size: 32px;
    line-height: normal;
  }
  .perks-text img {
    width: 60px;
  }
  .perks-text {
    border-radius: 15px;
  }

  #careerForm form {
    width: 90%;
    margin: 0 auto;
  }

  #careerForm textarea,
  #careerForm select,
  #careerForm input,
  #careerForm label {
    font-size: 16px;
  }
  #careerForm form .form-group select,
  #careerForm form .error-messages,
  #careerForm form .form-group input,
  #careerForm form .form-group label {
    width: 100%;
    margin-right: 0;
  }
  #careerForm form .form-group {
    flex-wrap: wrap;
  }
}
