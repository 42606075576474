.testimonials-section {
  padding: 50px 0;
}

.client-content-inner h3 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #a2ba5f;
  line-height: 36px;
}

.client-content-inner h2 {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: bold;
  color: var(--atlasblack);
  line-height: 60px;
}

.coding-text h2 {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: #212529;
}

.coding-text p {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #212529;
}

.client-text h2 {
  font-family: "Poppins", sans-serif;
  font-size: 94px;
  font-weight: bold;
  color: var(--atlasblack);
  line-height: 75px;
}

.client-flex {
  gap: 25px;
}

.client-text p {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: var(--atlasblack);
}

.client-content h3 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: var(--atlasblack);
  margin-bottom: 0;
}

.client-content p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: var(--atlasblack);
}

.client-main-content {
  gap: 20px;
}

.watch-content {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: normal;
  color: #212529;
  line-height: 35px;
}

.client-main-content img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

@media only screen and (max-width: 1200px) {
  .client-text h2 {
    font-size: 60px;
    line-height: 60px;
  }
  .client-content-inner h3 {
    font-size: 22px;
    line-height: normal;
  }
  .client-content-inner h2 {
    font-size: 42px;
    line-height: normal;
  }
}
@media only screen and (max-width: 991px) {
  .client-text h2 {
    font-size: 50px;
    line-height: 55px;
    margin-bottom: 0;
  }

  .client-text p {
    font-size: 20px;
  }
  .testimonial-counter .client-img {
    width: 65px;
    margin-left: 0;
  }
  .testimonial-counter .client-text {
    width: 80%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .coding-section h2 {
    font-size: 77px;
  }

  .coding-text h2 {
    font-size: 26px;
  }

  .coding-section p {
    font-size: 17px;
  }

  .client-text p {
    font-size: 17px;
  }

  .client-text h2 {
    font-size: 50px;
    line-height: 63px;
  }

  .client-content-inner h2 {
    font-size: 35px;
    line-height: 45px;
  }
}
