ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

.title-box {
  margin-bottom: 40px;
}

.title-box h2 {
  font-size: 50px;
  font-weight: bold;
  color: var(--atlasblack);
  margin: 0;
}

.title-box .white-color {
  color: white;
}

.title-box p {
  font-size: 24px;
  line-height: 40px;
  color: var(--atlasblack);
  flex: 0 0 58%;
  margin: 0;
}

/* .common-section {
  padding: 132px 0;
} */

.common-bottom-section {
  padding-bottom: 132px;
}

.common-top-section {
  padding-top: 132px;
}

img {
  max-width: 100%;
}

p {
  font-size: 20px;
  line-height: 33px;
  color: var(--atlasblack);
}

a {
  text-decoration: none;
}

/* === button === */

.comman-btn {
  position: relative;
  background-color: #212529;
  font-size: 24px;
  line-height: 35px;
  text-transform: unset;
  padding: 26px 37px 26px 99px;
  border-radius: 50px;
  font-weight: bold;
  color: #fff;
  display: inline-block;
  transition: all 0.4s linear;
  overflow: hidden;
  text-align: left;
}

.comman-btn:before {
  content: "";
  background-color: #acbde1;
  position: absolute;
  height: 66px;
  width: 66px;
  border-radius: 50%;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: all 0.4s linear;
}

.comman-btn:after {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  border-left: solid 3px #234285;
  border-bottom: solid 3px #234285;
  z-index: 1;
  left: 26px;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(225deg);
  transition: all 0.4s linear;
}

.comman-btn:hover:after {
  border-color: #acbde1;
  left: calc(100% - 54px);
  transition: all 0.4s linear;
}

.comman-btn:hover:before {
  left: calc(100% - 74px);
  background-color: var(--atlasblack);
  transition: all 0.4s linear;
}

.comman-btn:hover {
  background-color: #acbde1;
  padding: 26px 99px 26px 37px;
  transition: all 0.4s linear;
  color: var(--atlasblack);
}

.comman-btn.white-bg {
  background: #fff;
  color: #212529;
}

.comman-btn.white-bg:hover:before {
  background-color: #acbde1;
}

.comman-btn.white-bg:hover:after {
  border-color: var(--atlasblack);
}

/* === Hire Section One ===*/

.banner-inner-section {
  /*height: calc(100vh - 0px);*/
  display: flex;
  align-items: center;
}

.banner-inner-section .inner-content {
  max-width: 725px;
}

.banner-inner-section .inner-content h5 {
  color: #a2ba5f;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 20px;
  font-weight: bold;
}

.banner-inner-section .inner-content h1 {
  font-size: 50px;
  color: var(--atlasblack);
  margin-bottom: 30px;
  line-height: 60px;
  font-weight: bold;
}

.banner-inner-section .inner-content p {
  font-size: 18px;
  color: var(--atlasblack);
  line-height: 27px;
  font-weight: normal;
  margin-bottom: 20px;
}

.banner-inner-section .inner-content b {
  font-weight: bold;
  font-size: 24px;
  color: var(--atlasblack);
}

/* === Hire Section Two ===*/

.top-mobile-development-section {
  background-color: #e6e6e6;
}

.top-mobile-development-section .d-flex {
  gap: 83px;
}

.top-mobile-development-section .content-box b {
  font-size: 24px;
  color: var(--atlasblack);
  margin-bottom: 20px;
  display: block;
}

.top-mobile-development-section .content-box p {
  font-size: 20px;
  color: var(--atlasblack);
  line-height: 40px;
}

/* === Hire Section Three ===*/

.expert-mobile-services-section {
  background-color: var(--atlasblack);
}

.expert-mobile-services-section .services-box {
  padding: 30px;
  background-color: #fff;
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
  scale: 1;
  transition: all 0.4s;
}

.expert-mobile-services-section .services-box:hover {
  scale: 1.05;
  box-shadow: 10px 10px 10px #ffffff1a;
}

.expert-mobile-services-section .services-box div {
  margin-bottom: 10px;
}

.expert-mobile-services-section .services-box h6 {
  font-size: 24px;
  color: var(--atlasblack);
  margin-bottom: 14px;
  font-weight: bold;
  width: 100%;
}

.expert-mobile-services-section .services-box p {
  color: var(--atlasblack);
  font-size: 20px;
  margin-bottom: 4px;
  line-height: 33px;
}

.expert-mobile-services-section .all-service {
  display: grid;
  gap: 86px;
  grid-template-columns: 1fr 1fr 1fr;
}

.expert-mobile-services-section .bullet-point-ui {
  justify-content: end;
  width: 100%;
}

.expert-mobile-services-section .all-service.expertise li {
  font-size: 18px;
  line-height: 34px;
  padding-left: 25px;
  position: relative;
  margin-bottom: 5px;
}

.expert-mobile-services-section .all-service.expertise li:after {
  content: "";
  width: 8px;
  height: 8px;
  border-right: solid 1px #212529;
  border-bottom: solid 1px #212529;
  transform: rotate(-45deg);
  position: absolute;
  left: 0;
  top: 12px;
}

.expert-mobile-services-section .all-service.expertise {
  gap: 52px;
}

.bullet-point-ui {
  display: flex;
  gap: 13px;
}

.bullet-point-ui li {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #a2ba5f;
}

.bullet-point-ui li:nth-child(2) {
  background-color: var(--atlasblack);
}

.bullet-point-ui li:nth-child(3) {
  background-color: #003271;
}

/* === Hire Section Four ===*/

.mobile-services-section .d-flex {
  gap: 97px;
  align-items: start;
}

.mobile-services-section p {
  font-size: 20px;
  line-height: 33px;
  color: var(--atlasblack);
  max-width: 1440px;
  flex: 0 0 auto;
}

.mobile-services-section .developer-icon {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  width: 40%;
}

.mobile-services-section .developer-icon figure {
  border: solid 1px var(--atlasblack);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
  width: calc(33.33% - 20px);
  margin-bottom: 0;
  aspect-ratio: 1 / 1;
  padding: 10px;
}

.mobile-services-section .developer-icon figure:hover {
  background-color: var(--atlasblack);
}
.mobile-services-section .developer-icon figure img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.mobile-services-section .developer-icon figure:hover img {
  filter: brightness(1) invert(1);
}

/* === Hire Section Five ===*/

.hire-mobile-app-section .img-box {
  width: 40%;
}

.hire-mobile-app-section .content-area {
  width: 60%;
}

.hire-mobile-app-section .d-flex {
  gap: 81px;
}

.hire-mobile-app-section .content-area p {
  margin-bottom: 40px;
}

/* === Hire Section Seven === */
/* 
.home-banner {
  background-color: var(--nir-lightgray);
} */

.owl-content {
  padding: 60px 0 30px 0;
}

.owl-content h2 {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: var(--nir-lightblack);
}

.white-box img {
  filter: saturate(0);
}

.top-mobile-development-section .img-box img {
  border-radius: 25px;
  width: 100%;
}

.techstack .services-box {
  align-content: start;
  transition: all 0.4s;
}

.techstack .services-box:hover {
  background-color: #acbde1;
  scale: 1.02;
}

@media (min-width: 992px) {
  .top-mobile-development-section .img-box {
    flex: 0 0 40%;
  }
}

@media screen and (max-width: 1919px) {
  .custom-container {
    padding: 0 128px;
  }

  /* .common-section {
    padding: 100px 0;
  } */

  .common-bottom-section {
    padding-bottom: 100px;
  }

  .common-top-section {
    padding-top: 100px;
  }

  .title-box h2 {
    font-size: 42px;
  }

  .title-box {
    margin-bottom: 22px;
  }

  .top-mobile-development-section .content-box p {
    line-height: 38px;
  }

  .mobile-services-section .d-flex,
  .expert-mobile-services-section .all-service {
    gap: 66px;
  }

  .comman-btn {
    font-size: 22px;
  }
}

@media screen and (max-width: 1599px) {
  .custom-container {
    padding: 0 108px;
  }

  /* .common-section {
    padding: 80px 0;
  } */

  .comman-btn.top-space {
    margin: 50px auto 0;
  }

  .common-bottom-section {
    padding-bottom: 80px;
  }

  .common-top-section {
    padding-top: 80px;
  }

  .banner-inner-section .inner-content h5 {
    font-size: 22px;
  }

  .banner-inner-section .inner-content h1 {
    font-size: 44px;
    line-height: 48px;
  }

  .title-box h2 {
    font-size: 38px;
  }

  .title-box p {
    font-size: 20px;
    line-height: normal;
  }

  .hire-mobile-app-section .content-area p,
  .mobile-services-section p,
  .expert-mobile-services-section .services-box p,
  .top-mobile-development-section .content-box p {
    font-size: 18px;
    line-height: 28px;
  }

  .top-mobile-development-section .content-box p {
    line-height: 28px;
  }

  .expert-mobile-services-section .services-box {
    padding: 30px 30px 20px;
  }

  .mobile-services-section .d-flex,
  .expert-mobile-services-section .all-service {
    gap: 45px;
  }

  .mobile-services-section .content {
    flex: 0 0 60%;
    width: 60%;
  }

  .mobile-services-section .developer-icon img {
    height: 96px;
  }

  .comman-btn {
    font-size: 20px;
    padding: 15px 30px 15px 80px;
  }

  .comman-btn::before {
    width: 50px;
    height: 50px;
  }

  .comman-btn::after {
    left: 22px;
  }

  .comman-btn:hover {
    padding: 15px 80px 15px 30px;
  }

  .comman-btn:hover:before {
    left: calc(100% - 58px);
  }

  .comman-btn:hover:after {
    left: calc(100% - 46px);
  }

  .bullet-point-ui {
    gap: 10px;
  }

  .bullet-point-ui li {
    width: 16px;
    height: 16px;
  }
}

@media screen and (max-width: 1365px) {
  .custom-container {
    padding: 0 100px;
  }

  /* .common-section {
    padding: 70px 0;
  } */

  .comman-btn.top-space {
    margin: 70px auto 0;
  }

  .common-bottom-section {
    padding-bottom: 70px;
  }

  .common-top-section {
    padding-top: 70px;
  }

  .title-box h2 {
    font-size: 34px;
  }
}

@media screen and (max-width: 1279px) {
  /* .common-section {
    padding: 60px 0;
  } */

  .comman-btn.top-space {
    margin: 50px auto 0;
  }

  .common-bottom-section {
    padding-bottom: 60px;
  }

  .common-top-section {
    padding-top: 60px;
  }

  .banner-inner-section .inner-content h5 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .banner-inner-section .inner-content h1 {
    font-size: 38px;
    margin-bottom: 20px;
    line-height: 48px;
  }

  .hire-mobile-app-section .img-box {
    width: 35%;
  }

  .hire-mobile-app-section .content-area {
    width: 60%;
  }

  .hire-mobile-app-section .content-area p,
  .mobile-services-section p,
  .expert-mobile-services-section .services-box p,
  .top-mobile-development-section .content-box p,
  .banner-inner-section .inner-content p {
    font-size: 16px;
    line-height: 1.6;
  }

  .banner-inner-section .inner-content b {
    font-size: 22px;
  }

  .banner-inner-section .inner-content {
    max-width: 595px;
  }

  .top-mobile-development-section .d-flex {
    gap: 40px;
  }

  .title-box {
    margin-bottom: 25px;
  }

  .title-box h2 {
    font-size: 30px;
  }

  .custom-container {
    padding: 0 60px;
  }

  .expert-mobile-services-section .services-box {
    padding: 20px 20px 15px;
  }

  .mobile-services-section .d-flex,
  .expert-mobile-services-section .all-service {
    gap: 25px;
  }

  .mobile-services-section .developer-icon img {
    height: 90px;
  }

  .mobile-services-section .developer-icon {
    gap: 15px;
    width: 40%;
  }
  .mobile-services-section .d-flex {
    flex-direction: row;
  }
}

@media screen and (max-width: 1023px) {
  .custom-container {
    padding: 0 50px;
  }

  /* .common-section {
    padding: 40px 0;
  } */

  .comman-btn.top-space {
    margin: 40px auto 0;
  }

  .common-bottom-section {
    padding-bottom: 40px;
  }

  .common-top-section {
    padding-top: 40px;
  }

  .banner-inner-section {
    height: auto;
  }

  .title-box h2 {
    font-size: 26px;
  }

  .bullet-point-ui li {
    width: 14px;
    height: 14px;
  }

  .banner-inner-section .inner-content h5 {
    font-size: 18px;
    margin-bottom: 10px;
    line-height: normal;
  }

  .banner-inner-section .inner-content h1 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 15px;
  }

  .banner-inner-section .inner-content b {
    font-size: 20px;
  }

  .expert-mobile-services-section .all-service {
    grid-template-columns: 1fr 1fr;
  }

  .hire-mobile-app-section .d-flex {
    gap: 30px;
  }

  .expert-mobile-services-section .services-box h6 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .mobile-services-section .developer-icon img {
    height: 80px;
    padding: 22px 8px;
  }

  .hire-mobile-app-section .content-area p {
    margin-bottom: 30px;
  }

  .banner-inner-section .inner-content {
    max-width: 90%;
  }
}

@media screen and (max-width: 767px) {
  .custom-container {
    padding: 0 30px;
  }

  .owl-content {
    padding: var(--bs-gutter-x, 0.75rem);
  }

  .banner-inner-section .inner-content h1 {
    font-size: 28px;
    line-height: normal;
  }

  .banner-inner-section .inner-content b {
    font-size: 18px;
  }

  .title-box {
    font-size: 20px;
  }

  .top-mobile-development-section .content-box b {
    font-size: 20px;
    margin-bottom: 0;
  }

  .top-mobile-development-section .content-box p {
    margin-bottom: 10px;
  }

  .hire-mobile-app-section .content-area p,
  .mobile-services-section p,
  .expert-mobile-services-section .services-box p,
  .top-mobile-development-section .content-box p,
  .banner-inner-section .inner-content p {
    font-size: 15px;
    line-height: normal;
  }

  .hire-mobile-app-section .d-flex,
  .mobile-services-section .d-flex,
  .expert-mobile-services-section .all-service,
  .top-mobile-development-section .d-flex {
    flex-direction: column;
  }

  .mobile-services-section .content,
  .mobile-services-section .developer-icon {
    width: 100%;
  }

  .mobile-services-section .developer-icon figure {
    width: calc(25% - 20px);
  }
  .hire-mobile-app-section .img-box {
    width: 100%;
  }

  .hire-mobile-app-section .content-area {
    width: 100%;
  }
  .hiremainslider .slick-initialized .slick-list .slick-slide .item {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 639px) {
  .comman-btn {
    font-size: 18px;
    padding: 10px 20px 10px 70px;
  }

  .comman-btn::before {
    width: 40px;
    height: 40px;
  }

  .comman-btn::after {
    left: 20px;
    width: 15px;
    height: 15px;
  }

  .comman-btn:hover {
    font-size: 18px;
    padding: 10px 70px 10px 20px;
  }

  .comman-btn:hover::before {
    left: calc(100% - 48px);
  }

  .comman-btn:hover::after {
    left: calc(100% - 38px);
  }

  .expert-mobile-services-section .all-service {
    grid-template-columns: 1fr;
  }

  .mobile-services-section .d-flex,
  .expert-mobile-services-section .all-service {
    gap: 20px;
  }

  .top-mobile-development-section .d-flex {
    gap: 30px;
  }

  .mobile-services-section .developer-icon img {
    height: 75px;
  }
}

@media (max-width: 600px) {
  .owl-content h2 {
    font-size: 26px;
    line-height: 36px;
  }
}

@media screen and (max-width: 479px) {
  /* .common-section {
    padding: 30px 0;
  } */

  .comman-btn {
    line-height: normal;
    font-size: 16px;
  }

  .comman-btn.top-space {
    margin: 30px auto 0;
  }

  .common-bottom-section {
    padding-bottom: 30px;
  }

  .common-top-section {
    padding-top: 30px;
  }

  .custom-container {
    padding: 0 20px;
  }

  .banner-inner-section .inner-content h1 {
    font-size: 22px;
    line-height: normal;
  }

  br {
    display: none;
  }

  .title-box h2 {
    font-size: 22px;
  }

  .title-box {
    margin-bottom: 15px;
  }

  .mobile-services-section .developer-icon img {
    height: 60px;
    padding: 15px 10px;
  }

  .mobile-services-section .developer-icon {
    gap: 10px;
  }

  .hire-mobile-app-section .d-flex {
    gap: 20px;
  }

  .banner-inner-section .inner-content {
    max-width: none;
  }

  .banner-inner-section .inner-content b {
    font-size: 17px;
  }
}
