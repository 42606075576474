.perks-text p {
  margin-bottom: 20px;
}
.perks-text .details {
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
.perks-text {
  height: auto;
  transition: all 0.4s;
}

.perks-text img {
  width: 100px;
}

/* === service_inner_ui === */

#service_inner_ui h2 {
  font-size: 40px;
  font-weight: bold;
  font-family: "Poppins";
  color: var(--atlasblack);
  line-height: 54px;
  margin-bottom: 40px;
}

#service_inner_ui .service_ui_card {
  padding: 30px 70px 30px 30px;
  border-left: 10px solid;
  border-radius: 10px;
}

#service_inner_ui .service_ui_card:nth-child(1) {
  border-color: #a2ba5f;
}

#service_inner_ui .service_ui_card:nth-child(2) {
  border-color: #212529;
}

#service_inner_ui .service_ui_card:nth-child(3) {
  border-color: #003271;
}

/* dots */
#service_inner_ui .dots {
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 0;
  margin-top: 4px;
  width: auto;
  height: auto;
  opacity: 1;
  position: unset;
}

#service_inner_ui .dots span {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50%;
  margin-left: 10px;
  filter: drop-shadow(0px 0px 4px white);
}

#service_inner_ui .dots span.green {
  background: #a2ba5f;
}

#service_inner_ui .dots span.black {
  background: #212529;
}

#service_inner_ui .dots span.blue {
  background: #003271;
}

/* dots */

#service_inner_ui p {
  font-size: 18px;
  font-weight: 400;
  color: var(--atlasblack);
  line-height: normal;
}

#service_inner_ui .service_ui_card h4 {
  font-size: 24px;
  font-weight: bold;
  line-height: 35px;
  margin-bottom: 14px;
}

#service_inner_ui .service_ui_card p {
  font-size: 20px;
  font-weight: 400;
  line-height: 33px;
  color: #212529;
  margin-bottom: 4px;
}

/* dots */
#choose-slider .dots {
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 0;
  opacity: 1;
  width: auto;
  height: auto;
  margin-top: 4px;
  position: unset;
}

#choose-slider .dots span {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50%;
  margin-left: 10px;
  filter: drop-shadow(0px 0px 4px white);
}

#choose-slider .dots span.green {
  background: #a2ba5f;
}

#choose-slider .dots span.black {
  background: #212529;
}

#choose-slider .dots span.blue {
  background: #003271;
}

/* dots */

#choose-slider {
  background-color: #e6e6e6;
  padding: 130px 0;
}

#choose-accordion {
  background-color: #e6e6e6;
  padding: 0;
}

#choose-slider .choose_head h2 {
  font-size: 40px;
  font-weight: bold;
  line-height: 54px;
  margin-bottom: 0;
}

#choose-slider .choose_head p {
  font-size: 24px;
  line-height: 40px;
  font-weight: 400;
  margin-bottom: 0;
}

#choose-slider .slider_choose .choose_slide h3 {
  font-size: 24px;
  line-height: 35px;
  font-weight: bold;
  margin-bottom: 14px;
  color: var(--atlasblack);
}

#choose-slider .slider_choose .choose_slide p {
  font-size: 20px;
  line-height: 33px;
  font-weight: 400;
  margin-bottom: 4px;
}

#choose-slider .choose_slide {
  border: 1px solid #212529;
  border-radius: 25px;
  padding: 30px;
  height: 100%;
}

#choose-slider .choose_slide > * {
  width: 100%;
}
#choose-slider .choose_slide {
  display: flex !important;
  flex-wrap: wrap;
  align-content: space-between;
  transition: all 0.4s;
}
#choose-slider .choose_slide:hover {
  background-color: var(--atlasgreen);
}

#choose-slider .slick-track {
  display: flex !important;
}
#choose-slider .slick-track .slick-slide {
  display: flex !important;
  height: auto !important;
  align-items: stretch !important;
  justify-content: center !important;
  transition: all 0.4s;
}

#choose-slider .slick-track .slick-slide:hover {
  scale: 1.02;
}

#technologies_slider.choose_slide {
  display: unset;
}

#choose-slider .slider_choose {
  margin-top: 40px;
}

.slick-slide > div {
  padding: 10px;
}

.slider .slick-dots {
  position: unset;
}

#choose-slider .slick-dots {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0;
  margin-top: 10px;
}

#choose-slider .slick-dots li {
  width: 18px;
  height: 18px;
  background-color: #212529;
  border-radius: 50%;
  cursor: pointer;
}

#choose-slider .slick-dots .slick-active {
  background-color: #a2ba5f;
}

#choose-slider .slick-dots button {
  background: transparent;
  color: transparent;
}

.slick-dots li.slick-active button:before,
.slick-dots li button:before {
  color: transparent;
}

#choose-accordion .choose_head h2 {
  font-size: 40px;
  font-weight: bold;
  line-height: 54px;
  margin-bottom: 0;
}

#choose-accordion .choose_head p {
  font-size: 24px;
  line-height: 40px;
  font-weight: 400;
  margin-bottom: 0;
}

#choose-accordion .accordion_section {
  margin-top: 50px;
  border-radius: 25px 25px 0 0;
  background: #212529;
  padding: 60px;
}

#choose-accordion .accordion-item {
  background: transparent;
  color: #ffffff;
  border: none;
}

#choose-accordion .accordion-header button {
  background-color: transparent;
  color: #ffffff;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  padding: 0;
  margin: 10px 0;
}

#choose-accordion .accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: transparent;
  box-shadow: unset;
}

#choose-accordion .accordion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

#choose-accordion .accordion-header .accordion-button span {
  background: #a2ba5f;
  transition: all 0.6s;
}

#choose-accordion .accordion-header .collapsed span {
  background: transparent;
  transition: all 0.6s;
}

#choose-accordion .accordion-button:not(.collapsed)::after,
#choose-accordion .accordion-button::after {
  background-image: none;
}

#choose-accordion .accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

#choose-accordion .accordion-header button span {
  border-radius: 50%;
  margin-right: 10px;
  padding: 3px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
  font-size: 18px;
  line-height: 19px;
}

#choose-accordion .accordion-body figure {
  margin: 0;
}

#choose-accordion .accordion-body figure img {
  width: 90%;
  height: 100%;
  object-fit: cover;
}

#choose-accordion .accordion-button:not(.collapsed) {
  border-bottom: none;
}

#design-tool {
  padding: 130px 0;
}

#design-tool .tools_head h2 {
  font-size: 40px;
  line-height: 54px;
  font-weight: bold;
  margin-bottom: 0;
}

#design-tool .tools_head p {
  font-size: 24px;
  line-height: 40px;
  font-weight: 400;
  margin-bottom: 0;
}

#design-tool .tools_head {
  margin-bottom: 40px;
}

.design-tools .tools {
  padding: 20px;
  border-top: 2px solid #212529;
}

.design-tools .tools:last-child {
  border-bottom: 2px solid #212529;
}

.design-tools .tools .tools_name {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}

.design-tools .tools .tools_name p {
  background: #2a2f34;
  color: #ffffff;
  border-radius: 50px;
  padding: 4px 10px;
  font-size: 18px;
  line-height: 29px;
  font-weight: 400;
  margin: 10px 0;
  margin-right: 30px;
  transition: all 0.4s;
}

.design-tools .tools .tools_name p:hover {
  background: var(--atlasgreen);
}

.design-tools .tools .tools_name p:last-child {
  margin-right: 0;
}

.design-tools .tools h5 {
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 0;
  color: var(--atlasblack);
}

.service-detail .box li:after {
  content: none !important;
}

.service-detail .box li {
  padding-left: 0 !important;
}

/* new add */
#Services_slide .technologies_content .slider_slid img {
  height: 100%;
  width: 90%;
}

#Services_slide .slider_slid .slide_li ul {
  width: fit-content;
}

.slide_li ul li a {
  color: var(--atlasblack);
  transition: all 0.4s;
}

.slide_li ul li:hover a {
  color: var(--atlasblue);
  transform: translateX(10px);
}

.slide_li ul li {
  transition: all 0.4s;
  transform: translateX(0);
}
.slide_li ul li:hover {
  transform: translateX(10px);
}
#services_slide_inner .slick-slide div {
  height: auto !important;
}
#services_slide_inner .technologies_thumb .slick-slide div {
  height: 100% !important;
}
/* new add */

@media (max-width: 1200px) {
  #choose-accordion .accordion_section {
    padding: 30px;
  }
  #choose-slider {
    padding: 70px 0;
  }
  #service_inner_ui h2 {
    font-size: 34px;
    margin-bottom: 20px;
  }
  #choose-accordion .choose_head h2,
  #design-tool .tools_head h2,
  #faq .faq_head h2,
  #choose-slider .choose_head h2 {
    font-size: 34px;
    line-height: 45px;
  }
  #service_inner_ui .all-developer .box {
    padding: 20px 30px;
  }
  #design-tool .tools_head p,
  #choose-accordion .choose_head p,
  #choose-slider .choose_head p,
  #faq .faq_head p,
  #service_inner_ui p {
    font-size: 18px;
    line-height: normal;
  }
  .design-tools .tools h5 {
    font-size: 22px;
    line-height: normal;
  }
  .design-tools .tools .tools_name p {
    margin-right: 10px;
    font-size: 16px;
  }
  .design-tools .tools {
    padding: 10px;
  }
  .perks-text p {
    font-size: 22px;
    line-height: normal;
  }
  .perks-text .details {
    font-size: 20px;
    line-height: normal;
  }
  #choose-slider .choose_slide {
    padding: 20px;
    margin-right: 0px;
  }
  #choose-slider .slider_choose .choose_slide h3 {
    font-size: 22px;
    line-height: 30px;
  }
  #choose-slider .slider_choose .choose_slide p {
    font-size: 20px;
    line-height: 33px;
    margin-bottom: 10px;
  }
}
/* 
@media (max-width: 991px) {
  #Services_slide .slider_slid .slide_li ul {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-wrap: wrap;
  }

  .slide_li ul li {
    width: 48%;
  }
} */
@media (max-width: 767px) {
  #service_inner_ui .service_ui_card {
    margin-bottom: 20px;
  }

  #choose-accordion .accordion-body figure img {
    width: 100%;
  }

  .perks-text p {
    font-size: 20px;
    line-height: normal;
  }

  .perks-text .details {
    font-size: 18px;
    line-height: normal;
  }

  #service_inner_ui h2 {
    font-size: 30px;
    margin-bottom: 20px;
    line-height: 34px;
  }

  #service_inner_ui p {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 10px;
  }

  #service_inner_ui .service_ui_card {
    padding: 10px 20px;
  }

  #service_inner_ui .service_ui_card h4 {
    font-size: 22px;
  }

  #service_inner_ui .service_ui_card p {
    font-size: 18px;
    line-height: 24px;
  }

  #service_inner_ui .dots span {
    width: 13px;
    height: 13px;
    margin-left: 6px;
  }
  #choose-slider .choose_head h2 {
    font-size: 30px;
    margin-bottom: 20px;
    line-height: 34px;
  }

  #choose-slider .choose_head p {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
  }

  #choose-slider .choose_slide {
    padding: 18px;
    margin-right: 0px;
  }

  #choose-slider .slider_choose .choose_slide h3 {
    font-size: 20px;
    line-height: normal;
  }

  #choose-slider .slider_choose .choose_slide p {
    font-size: 18px;
    line-height: normal;
    margin-bottom: 10px;
  }

  #choose-slider .dots span {
    width: 13px;
    height: 13px;
  }

  #choose-slider .slick-dots {
    margin-top: 30px;
  }

  #choose-accordion .choose_head h2 {
    font-size: 30px;
    margin-bottom: 20px;
    line-height: 34px;
  }

  #choose-accordion .choose_head p {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
  }

  #choose-accordion .accordion {
    flex-wrap: wrap;
  }

  #choose-accordion .accordion_section {
    padding: 40px 30px;
  }

  #design-tool .tools_head h2 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 20px;
  }

  #design-tool .tools_head p {
    font-size: 20px;
    line-height: 26px;
  }

  .design-tools .tools {
    padding: 10px;
  }

  .design-tools .tools h5 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  .design-tools .tools .tools_name p {
    margin: 5px 0;
    margin-right: 6px;
    font-size: 16px;
    padding: 1px 10px;
  }

  #design-tool,
  #choose-slider {
    padding: 100px 0;
  }

  #choose-slider .slider_choose {
    margin-top: 20px;
  }
  #Services_slide .slider_slid .slide_li ul {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-wrap: wrap;
  }
  .slide_li ul li {
    width: 48%;
  }
}

@media (max-width: 475px) {
  #design-tool,
  #choose-slider {
    padding: 50px 0;
  }

  .slide_li ul li {
    width: 100%;
  }

  #design-tool .tools_head h2,
  #service_inner_ui h2 {
    font-size: 28px;
    line-height: normal;
  }

  .design-tools .tools h5,
  #design-tool .tools_head p,
  #service_inner_ui p {
    font-size: 18px;
    line-height: normal;
  }

  .top-talents-hire-section .all-developer {
    gap: 15px !important;
  }

  #choose-accordion .choose_head h2,
  #choose-slider .choose_head h2 {
    font-size: 28px;
    line-height: normal;
  }

  #choose-accordion .choose_head p,
  #choose-slider .choose_head p {
    font-size: 18px;
    line-height: normal;
  }
  #choose-slider .slider_choose {
    margin-top: 0px;
  }
  #choose-slider .slider_choose .choose_slide h3 {
    font-size: 18px;
    line-height: normal;
  }
  #choose-slider .slider_choose .choose_slide p {
    font-size: 18px;
    line-height: normal;
  }
  #choose-slider .slick-dots {
    margin-top: 0;
  }
  #choose-accordion .accordion_section {
    margin-top: 10px;
  }
  #choose-accordion .accordion_section .accordion-button {
    padding: 5px;
  }
  #choose-accordion .accordion_section {
    padding: 20px 10px;
  }
  .perks-text .details,
  #choose-accordion .accordion-header button {
    font-size: 16px;
    line-height: normal;
  }
  #choose-accordion .accordion-item {
    margin-bottom: 20px;
  }

  .design-tools .tools .tools_name p {
    font-size: 14px;
    line-height: normal;
    margin: 2px 0;
    margin-right: 6px;
  }
  .perks-text p {
    margin-bottom: 10px;
  }
  .dot-flex {
    gap: 0;
  }
  #choose-slider .slick-dots li,
  .slick-dots li button:before,
  .slick-dots li button {
    width: 14px;
    height: 14px;
  }
}

.servicesdanimation9,
.servicesdanimation7,
.servicesdanimation8,
.servicesdanimation5,
.servicesdanimation2,
.servicesdanimation21,
.commonanimation3,
.commonanimation2 {
  opacity: 0;
  transform: translate(-30px);
  /* transition: all 0.4s; */
}
.hiredevloperform #careerForm .form-group .error-messages {
  text-align: right;
}
.hiredevloperform #careerForm .form-group.select_butons .error-messages {
  display: none;
}
.google-recaptchacenter > div > div {
  transform-origin: center;
  -webkit-transform-origin: center;
  overflow: hidden;
}
