/* Home section Three start */
:root {
  --clr1: #a2ba5f;
  --clr2: #003271;
  --clr3: #212529;
  --clr4: var(--atlasblack);
  --hsHeadingTop: 100px;
  --hsHeadingHeight: 120px;
  --hsTextTop: 220px;
  --wwaWidth: 71px;
  --wwaWidth2: 150px;
  --dotBoxLiWidth: 30px;
  --dotBoxDuration: 1s;
  --dotBoxTop: 150vh;
  --expWidth: 40px;
  --wwa1Top: 0;
  --wwa1Left: 0;
  --wwa2Top: 0;
  --wwa2Left: 0;
  --wwa3Top: 0;
  --wwa3Left: 0;
  --durationCommon: 400;
  --service1Height: 120vh;
  --service2Height: 110vh;
  --serviceMainHeight: 450vh;
  --hsTrigger1: 120vh;
  --hsTrigger12: 220vh;
  --dots: 30vh;
  --hs-img-box: 424px;
  --tlServAnimContainer: 100vh;
  --hs-ellipse-width-1: 370px;
  --hs-ellipse-width-2: 305px;
  --hs-ellipse-width-3: 395px;
  --bannerHeight: 0;
  --wwrHeight: 0;
  --servDetLeft: 170px;
}
.dark-mode {
  --clr3: #ffffff !important;
}
@media all and (max-width: 1366px) and (min-width: 1281px) {
  :root {
    --wwaWidth: 50px;
    --wwaWidth2: 120px;
    --hs-img-box: 250px;
    --expWidth: 30px;
  }
}
@media all and (max-width: 800px) {
  :root {
    --expWidth: 35px;
    --wwaWidth: 8vw;
    --wwaWidth2: 16vw;
    --servDetLeft: 70px;
  }
}

.bg-videos-add {
  position: absolute;
  max-width: 100vw !important;
  object-fit: cover;
  top: 0;
  right: 50%;
  transform: translateX(50%);
  height: 100% !important;
}
#home-banner-videos::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: var(--atlaswhite);
  opacity: 0.5;
}
#home-banner-videos {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-banner-texts {
  position: relative;
  z-index: 2;
  text-align: center;
  width: 80%;
}

/* .home-banner {
  background-image: url("../src/homepage/blurry.mp4");
} */
.whoWeAreImg #whoWeAreCanvas svg {
  display: none;
}
.whoWeAreImg #whoWeAreCanvas svg:first-child {
  display: block;
}
.darkAnim {
  display: none;
}
.dots {
  position: absolute;
  top: var(--dots);
  left: 50%;
  margin-left: -310px;
  width: 0;
  height: 0;
  border-radius: 50%;
  opacity: 0;
}

.dot1 {
  background: var(--clr1);
  top: 48vh;
  left: 36%;
}

.dot2 {
  background: var(--clr2);
}

.dot3 {
  background: var(--clr3);
  top: 48vh;
  left: 36%;
}

.dot4,
.dot5,
.dot6 {
  opacity: 0;
  height: 65px;
  width: 65px;
  margin-left: 0;
  background: var(--clr3);
}

.dot4 {
  background: var(--clr1);
}

.dot5 {
  background: var(--clr2);
}
.dotBox {
  position: absolute;
  top: 30vh;
  left: 50%;
  margin-left: -370px;
  width: 200px;
  height: 200px;
  overflow: hidden;
  opacity: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.dotBox li {
  position: absolute;
  border-radius: 50%;
  width: 0;
  height: 0;
  left: 0;
  top: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.dotBox li:nth-of-type(1) {
  background: var(--clr2);
  left: 4%;
  top: 4%;
}

.dotBox li:nth-of-type(2) {
  background: var(--clr3);
  left: 24%;
  top: 24%;
}

.dotBox li:nth-of-type(3) {
  background: var(--clr1);
  left: 44%;
  top: 13%;
}

.dotBox li:nth-of-type(4) {
  background: var(--clr1);
  left: 74%;
  top: 34%;
}

.dotBox li:nth-of-type(5) {
  background: var(--clr2);
  left: 84%;
  top: 14%;
}

.dotBox li:nth-of-type(6) {
  background: var(--clr2);
  left: 14%;
  top: 84%;
}

.dotBox li:nth-of-type(7) {
  background: var(--clr1);
  left: 34%;
  top: 54%;
}

.dotBox li:nth-of-type(8) {
  background: var(--clr1);
  left: 54%;
  top: 84%;
}

.dotBox li:nth-of-type(9) {
  background: var(--clr2);
  left: 64%;
  top: 54%;
}

.dotBox li:nth-of-type(10) {
  background: var(--clr3);
  left: 84%;
  top: 84%;
}
#whoWeAreCanvas,
#whoWeAreCanvasDark {
  max-width: 100%;
  margin: 0 auto;
}

.whoWeAreImg {
  margin: 0 auto;
  position: relative;
  margin: 10px auto 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.whoWeAreImg::before {
  content: "";
  width: 100%;
  height: 1px;
  border: 1px dashed var(--atlasblack);
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -0.5px;
}

.whoWeAreImg .wwa {
  position: relative;
}
.whoWeAreImg .wwa1,
.whoWeAreImg .wwa3 {
  background: var(--atlaswhite);
}
.whoWeAreImg .wwa1 img,
.whoWeAreImg .wwa3 img {
  width: 70px;
}
.whoWeAreImg .wwa2 img {
  width: 100px;
}
.wwwadots {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
}
.dot2-wwa {
  /*background: var(--atlasblack);*/
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
}
.filledcircles .dot1-wwa {
  background: var(--atlasgreen);
}
.filledcircles .dot2-wwa {
  background: var(--atlasblack);
}
.filledcircles .dot3-wwa {
  background: var(--atlasblue);
}

.hs-box {
  position: relative;
}

.hs-box .container {
  height: 100%;
  position: relative;
}

.hs-service-in {
  position: sticky;
  position: -webkit-sticky;
  top: var(--hsHeadingTop);
}

.hs-service {
  height: var(--service2Height);
  position: relative;
}

.hs-service h2,
.hs-service h4 {
  font-size: 36px;
  color: var(--atlasblack);
  line-height: 1.3;
  margin-bottom: 0;
  font-weight: 900;
  text-transform: capitalize;
  position: relative;
  padding: 20px 0;
  padding-left: 70px;
}

.hs-service h2.hsTrigBtn,
.hs-service h4.hsTrigBtn {
  position: absolute;
}

.hs-service h2.hsTrigBtn:nth-last-of-type(1),
.hs-service h4.hsTrigBtn:nth-last-of-type(1) {
  top: 240px;
}

.hs-service h2.hsTrigBtn:nth-last-of-type(2),
.hs-service h4.hsTrigBtn:nth-last-of-type(2) {
  top: 120px;
}

.hs-service h2 b,
.hs-service h4 b {
  font-weight: 900;
  text-transform: capitalize;
}
.homesectionthreetitleanimation {
  opacity: 0;
  transform: translateX(-50px);
}

.hs-service ul {
  padding-left: 70px !important;
  position: sticky;
  position: -webkit-sticky;
}

.hs-service ul li p strong {
  opacity: 0;
  margin-bottom: 0px;
  margin-left: -50px;
}
.hs-1-img,
.hs-2-img,
.hs-3-img {
  position: sticky;
  position: -webkit-sticky;
  opacity: 0;
  width: 70%;
  margin-left: -50px;
}

.hs-service ul li a {
  color: var(--atlasblack);
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 0px;
  text-transform: capitalize;
  transition: all 0s ease-in-out;
  -webkit-transition: all 0s ease-in-out;
  cursor: default;
}

.hs-service ul li a:hover {
  background-position-x: 100%;
}

.hsdot,
.hsdot-3a {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  left: 0;
  /* background: var(--clr1); */
  top: 0;
  opacity: 0;
}

.hsDotBox {
  width: 32px;
  height: 32px;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.hsDotBox:before {
  content: "";
  position: absolute;
  left: -15px;
  top: -15px;
  background: url(https://ik.imagekit.io/2eribsa8w/round_sVony6uRCE5.svg)
    no-repeat center center / contain;
  width: calc(100% + 30px);
  height: calc(100% + 30px);
  border-radius: 50%;
  -webkit-animation: rotation 5s linear infinite;
  animation: rotation 5s linear infinite;
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.dark-mode .hsdot-2 {
  filter: brightness(1) invert(1);
}
.hsdot-2 {
  /* background: var(--clr2); */
}

.hsdot-3 {
  /* background: var(--clr3); */
}

.h-founder-box {
  position: relative;
  padding: 0 0 150px;
}

@media (min-width: 768px) {
  .whoWeAreImg .wwa1 img,
  .whoWeAreImg .wwa3 img {
    width: 150px;
  }
  .whoWeAreImg .wwa2 img {
    width: 250px;
  }
  .wwwadots {
    width: 70px;
    height: 70px;
    margin-left: -35px;
    margin-top: -35px;
  }
  .dot2-wwa {
    width: 120px;
    height: 120px;
    margin-left: -60px;
    margin-top: -60px;
  }
  .hs-service h2,
  .hs-service h4 {
    font-size: 52px;
    padding-left: 120px;
  }
  .hsDotBox {
    width: 64px;
    height: 64px;
  }
  .hs-service ul {
    padding-left: 120px !important;
  }
  .hs-service ul li a {
    font-size: 24px;
    padding-bottom: 4px;
  }
  .hs-service ul li {
    margin-bottom: 15px;
  }
  .hs-1-img,
  .hs-2-img,
  .hs-3-img {
    width: 100%;
  }
}
/* Home section Three end */

/* Home section 4 start */
.counter-main {
  display: flex;
}
.dark-mode .counter-icon1 img {
  filter: brightness(1) invert(1);
}
.counter-icon {
  border: 2px dashed var(--atlasblack);
  border-radius: 50%;
  padding: 10px;
  margin-right: 10px;
  width: 65px;
  height: 65px;
  flex: 0 0 auto;
  text-align: center;
}
.counter-icon img {
  object-fit: contain;
  filter: none;
  transition: all 0.5s;
  height: 40px;
}
.counter-icon.counter-icon-animate img {
  filter: brightness(0) invert(0);
  animation: zoom-in-zoom-out 2s ease-out infinite;
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 0.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.counter-content {
  font-size: 74px;
  line-height: 60px;
  font-weight: bold;
}
@media (min-width: 992px) {
  .counter-content {
    font-size: 74px;
    line-height: 60px;
    font-weight: bold;
  }
}
@media (min-width: 1200px) {
  .counter-content {
    font-size: 94px;
    line-height: 70px;
    font-weight: bold;
  }
}
/* Home section 4 end */

/*Home section5 start*/
#technologies_slider
  .slider-thumb
  .thumb_name
  .thumb-icon
  span
  svg
  circle#Ellipse_194 {
  fill: #ffffff;
}

#technologies_slider
  .slider-thumb
  .thumb_name
  .thumb-icon
  span
  svg
  path#Polygon_12,
#technologies_slider
  .slider-thumb
  .thumb_name
  .thumb-icon
  span
  svg
  path#Polygon_13 {
  opacity: 0;
}

#technologies_slider
  .slider-thumb
  .thumb_name
  .thumb-icon
  span
  svg
  g#Ellipse_199
  circle {
  opacity: 0;
}

#technologies_slider
  .slider-thumb
  .slick-current
  .thumb-icon
  span
  svg
  circle#Ellipse_194 {
  fill: rgb(162, 186, 95);
}

#technologies_slider
  .slider-thumb
  .slick-current
  .thumb-icon
  span
  svg
  path#Polygon_12,
#technologies_slider
  .slider-thumb
  .slick-current
  .thumb-icon
  span
  svg
  path#Polygon_13 {
  opacity: 1;
}

#technologies_slider
  .slider-thumb
  .slick-current
  .thumb-icon
  span
  svg
  g#Ellipse_199
  circle {
  opacity: 1;
}

#technologies_slider .technologies_content .slider_slid img {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  object-fit: contain;
}

#technologies_slider .slider_slid img {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}
#technologies_slider {
  transition: all 1s;
}
#technologies_slider.technologystart {
  background: #000000;
}
#technologies_slider.technologystart .page-title {
  background: var(--atlasgreen);
  background: -webkit-linear-gradient(
    to right,
    var(--atlasgreen) 0%,
    var(--atlasblue) 100%
  );
  background: -moz-linear-gradient(
    to right,
    var(--atlasgreen) 0%,
    var(--atlasblue) 100%
  );
  background: linear-gradient(
    to right,
    var(--atlasgreen) 0%,
    var(--atlasblue) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.5s;
}
#technologies_slider .slider-thumb .thumb-icon span {
  position: relative;
  background-color: var(--atlasblack);
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}
#technologies_slider.technologystart .slider-thumb .thumb-icon span {
  background-color: #000000;
}

#technologies_slider .slider-thumb {
  position: relative;
  z-index: 1;
  background: transparent;
  padding: 0;
  width: 97%;
  margin: 0 auto;
}

#technologies_slider .slider-thumb:after {
  content: "";
  position: absolute;
  top: 80%;
  border-bottom: 1px solid #ffffff;
  width: 80%;
  right: 50%;
  z-index: -1;
  margin: 0 auto;
  transform: translateX(50%);
}

#technologies_slider .slick-nav.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

#technologies_slider .next-arrow {
  left: calc(100% + 70px);
}

#technologies_slider .prev-arrow {
  right: calc(100% + 70px);
}

#technologies_slider .slider-thumb .thumb_name .thumb-icon h3 {
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  line-height: 35px;
  margin-bottom: 18px;
  text-wrap: nowrap;
}

#technologies_slider .slider-thumb .slick-current .thumb-icon h3 {
  color: #a2ba5f;
}

#technologies_slider .thumb-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.home_page_slider#technologies_slider .slick-track {
  display: block !important;
}

#technologies_slider .slick-track {
  display: flex !important;
}

#technologies_slider .slick-slide {
  height: inherit !important;
}

#technologies_slider .slick-slide div {
  height: 100% !important;
}

#technologies_slider .slider_slid .row {
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
}

#technologies_slider .slider_slid .row > div {
  text-align: center;
}

#technologies_slider .slider_slid .row div h3 {
  margin-top: 15px;
  margin-bottom: 0;
  color: #a2ba5f;
  font-size: 22px;
  line-height: 35px;
  font-weight: bold;
}

.atlas_life_videos {
  position: absolute;
  filter: grayscale(1);
  top: 0;
  right: 0;
  z-index: -1;
  border-radius: 50px;
}

.careerblockimg img {
  transition: all 0.4s;
}
.careerblock:hover .careerblockimg img {
  opacity: 0;
}

.careerblockimg .atlas_life_videos {
  transition: all 0.4s;
}
.careerblock:hover .careerblockimg .atlas_life_videos {
  z-index: 1;
}

@media (max-width: 1700px) {
  #technologies_slider .technologies_content .slider_slid img {
    width: auto;
    height: 60px;
    margin: 0 auto;
  }

  #technologies_slider .slider_slid .row div h3 {
    margin-top: 10px;
    line-height: 26px;
  }

  #technologies_slider .slider_slid .row {
    margin-top: 30px;
  }
}
@media (max-width: 1200px) {
  #technologies_slider .slider-thumb .thumb_name .thumb-icon h3 {
    font-size: 24px;
    line-height: 30px;
  }
}

@media (max-width: 1024px) {
  #technologies_slider .slider-thumb .thumb_name .thumb-icon h3 {
    font-size: 22px;
    line-height: 30px;
  }

  #technologies_slider .slider_slid .row div h3 {
    font-size: 18px;
    line-height: 30px;
  }

  #technologies_slider .slider-thumb {
    width: 90%;
  }

  #technologies_slider .prev-arrow {
    right: calc(100% + 20px);
  }

  #technologies_slider .next-arrow {
    left: calc(100% + 20px);
  }

  #technologies_slider .technologies_content .slider_slid img {
    width: auto;
    height: 60px;
    margin: 0 auto;
  }
}

@media (max-width: 992px) {
  .counter-main {
    display: block;
    text-align: center;
  }

  .counter-icon {
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

@media (max-width: 769px) {
  #technologies_slider .next-arrow {
    left: calc(100% + 20px);
  }

  #technologies_slider .prev-arrow {
    right: calc(100% + 20px);
  }
}

@media (max-width: 767px) {
  #technologies_slider .slider_slid .row > div {
    margin-bottom: 20px;
  }

  #technologies_slider .slider_slid .row div h3 {
    margin-top: 10px;
  }

  #technologies_slider .slider-thumb .thumb_name .thumb-icon h3 {
    font-size: 20px;
    line-height: 20px;
  }

  #technologies_slider .slider-thumb {
    width: 93%;
  }

  #technologies_slider .slider-thumb:after {
    width: 90%;
  }

  #technologies_slider .slider-thumb:after {
    top: 76%;
  }

  #technologies_slider .technologies_content .slider_slid img {
    width: auto;
    height: 50px;
    margin: 0 auto;
  }

  div#aboutModalLabel .btn-close,
  div#careersModal .btn-close,
  div#homeModal .btn-close,
  #aboutModal .btn-close {
    left: unset !important;
    right: 10px;
  }
}

@media (max-width: 450px) {
  #technologies_slider .slider-thumb {
    width: 80%;
  }

  #technologies_slider .prev-arrow {
    right: calc(100% + 20px);
  }

  #technologies_slider .next-arrow {
    left: calc(100% + 20px);
  }
}
.slick-prev,
.slick-next {
  width: 40px !important;
  height: 40px !important;
  top: calc(50% + 10px);
}

/*Home section5 ends*/

/*Home section8 start*/
#pinContainer {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}
#slideContainer {
  width: 400%; /* to contain 4 panels, each with 100% of window width */
  height: 100vh;
}
.panel {
  height: 100vh;
  width: 25%; /* relative to parent -> 25% of 400% = 100% of window width */
  float: left;
  display: flex;
  align-items: center;
}
.testimonial-section > .scrollmagic-pin-spacer {
  padding-bottom: 0px !important;
}

.dot-testi {
  width: 18px;
  min-width: 18px;
  height: 18px;
  border-radius: 50%;
}
.dot-testi:not(:first-child) {
  margin-left: 5px;
}
.light-green-testi {
  background: var(--atlasgreen);
}
.green-testi {
  background: var(--atlasblack);
}
.blue-testi {
  background: var(--atlasdarkblue);
}
.testi-img {
  width: 50px;
  height: 50px;
  flex: 0 0 auto;
}
.testi-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.f14 {
  font-size: 14px;
}
.testiname {
  padding-left: 15px;
}
.testiname .f14 {
  line-height: 21px;
}
.testimonial-section {
  font-size: var(--f12);
}
.project-img {
  width: 100%;
  object-fit: contain;
}

.testimonial-section .slick-dots li button {
  color: var(--atlasblack);
  background: var(--atlasblack);
  border-radius: 50px;
}

.testimonial-section .slick-dots li.slick-active button {
  color: var(--atlasgreen);
  background: var(--atlasgreen);
  border-radius: 50px;
}

@media (min-width: 767px) {
  .testi-img {
    width: 105px;
    height: 105px;
  }
  .project-img {
    width: 100%;
    /* height: 70vh; */
    height: 100%;
  }
  .testimonial-section {
    font-size: var(--f18);
  }
}
/*Home Section8 end*/

/*Home blog section start */
.blog-img {
  aspect-ratio: 3 / 2;
  border-radius: 25px;
}
.blog-img img {
  width: 100%;
  object-fit: cover;
}
.careerblockimg {
  border-radius: 50px;
}
.careerblock .careerblockimg img {
  filter: grayscale(100%);
  height: 300px;
  object-fit: cover;
}
.careerblock .careerblockimg::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #000000;
  opacity: 0.4;
  border-radius: 50px;
  z-index: 1;
}
.careerblockcontent {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}
.playimg {
  width: 70px;
  transition: all 0.4s;
}
.playimg:hover {
  transform: rotate(-360deg);
}
.lifeatatlas .shaluimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50% !important;
  object-position: top center;
}
.lifeatatlas .shaluimg {
  width: 60px;
  height: 60px;
  flex: 0 0 auto;
  margin-right: 10px;
}
.lifeatatlas {
  box-shadow: 0px 0px 20px #00000029;
  border-radius: 50px;
  width: 100%;
  background: var(--atlaswhite);
  padding: 20px;
  margin-top: 2rem;
}

.hs-service ul br {
  display: block;
}

.latest_articles .latest_articles_card a .blog-img {
  transition: all 0.4s;
  scale: 1;
  overflow: hidden;
}
.latest_articles .latest_articles_card:hover a .blog-img {
  scale: 1.05;
  box-shadow: 0px 30px 20px #0000001a;
}
div#careersModal,
div#homeModal {
  backdrop-filter: blur(10px);
}
div#careersModal .btn-close,
div#homeModal .btn-close {
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  border: 4px solid black;
  opacity: 1;
  position: absolute;
  left: 100%;
  z-index: 10;
  transition: all 0.4s;
}
div#careersModal .btn-close:hover,
div#homeModal .btn-close:hover {
  filter: brightness(10) invert(10);
}
.modal-dialog-scrollable .modal-content {
  overflow: unset;
}
.modal-dialog-scrollable .modal-body iframe {
  border-radius: 15px;
  height: 80vh;
}
.logo-slider-home {
  height: 140px;
}

#privacy ul li,
#terms ul li {
  list-style: inside;
  font-size: 18px;
}

@media (min-width: 576px) {
  .logo-slider-home {
    height: 220px;
  }
  .slick-prev,
  .slick-next {
    top: 50%;
  }
}
@media (min-width: 768px) {
  .logo-slider-home {
    height: 150px;
  }

  #privacy h2,
  #terms h2 {
    font-size: 28px;
    line-height: normal;
  }

  #privacy p,
  #terms p {
    font-size: 18px;
    line-height: normal;
  }
}
@media (min-width: 992px) {
  #privacy h2,
  #terms h2 {
    font-size: 30px;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 10px;
    margin-top: 50px;
  }

  #privacy p,
  #terms p {
    font-weight: 400;
    line-height: normal;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .playimg {
    width: auto;
  }
  .careerblockcontent {
    padding: 50px;
  }
  .careerblock .careerblockimg img {
    height: auto;
  }
  .lifeatatlas {
    box-shadow: 42px 42px 60px #00000029;
    border-radius: 50px;
    width: 450px;
    background: var(--atlaswhite);
    height: auto;
    z-index: 10;
    right: 50px;
    bottom: -115px;
    position: absolute;
  }
  .lifeatatlas .shaluimg {
    width: 105px;
    height: 105px;
  }
  .logo-slider-home {
    height: 140px;
  }
}
@media (min-width: 1100px) {
  .logo-slider-home {
    height: 150px;
  }
}
@media (min-width: 1200px) {
  .logo-slider-home {
    height: 160px;
  }
  .requestdiv {
    width: 80%;
    margin-left: auto;
  }
}
@media (min-width: 1400px) {
  .logo-slider-home {
    height: 190px;
  }
}
@media (min-width: 1600px) {
  .logo-slider-home {
    height: 250px;
  }
}
/* @media (min-width: 1400px) {
  .requestdiv {
    width: 70%;
  }
} */
/*Home blog section end*/

.bannervideo {
  width: 100%;
}

@media (min-width: 1200px) {
  .bannervideo {
    height: 446px;
    scale: 1.3;
  }
}
@media (max-width: 768px) {
  .bannervideo {
    height: 300px;
  }
  .logo-slider-home {
    height: 200px;
  }
  .atlas_life_videos,
  .careerblockimg {
    border-radius: 25px;
  }

  .careerblock .careerblockimg::before {
    border-radius: 25px;
  }

  .careerblockcontent {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: auto;
    bottom: 0;
    top: auto;
  }

  .lifeatatlas {
    border-radius: 25px;
    padding: 10px;
  }
}

@media (max-width: 500px) {
  .hs-service h2,
  .hs-service h4 {
    font-size: 26px;
  }
  .hsDotBox:before {
    left: -10px;
    top: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
  }
  .counter-content {
    font-size: 50px;
    line-height: 50px;
  }
}
.commonanimation,
.commonanimation1,
.commonanimation2,
.commonanimation3,
.commonanimation4,
.commonanimation5 {
  opacity: 0;
  transform: translateX(-30px);
}
.title-colorchange .page-title {
  background: var(--atlasgreen);
  background: linear-gradient(
    to right,
    var(--atlasgreen) 0%,
    var(--atlasblue) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.5s;
}

input::-webkit-input-placeholder {
  transform: translateX(0);
  opacity: 1;
  transition: all 1s;
}

input:focus::-webkit-input-placeholder {
  transform: translateX(-100%);
  opacity: 0;
}
