.clients-content h3 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #a2ba5f;
  line-height: 36px;
}

.clients-content h2 {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: bold;
  color: var(--atlasblack);
  line-height: 60px;
}

.client-tebimation p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: var(--atlasblack);
  line-height: 14px;
  margin-bottom: 0;
  margin-left: 8px;
}

.client-logo-section .accordion-button:not(.collapsed) p {
  color: #a2ba5f;
}

.client-logo-section .accordion-button:not(.collapsed) svg path {
  fill: #a2ba5f;
}

.client-active a {
  text-decoration: none;
}

.client-logo-section .accordion-button:not(.collapsed) {
  border-bottom: 1px solid #a2ba5f;
}

.client-tebimation {
  margin-right: 50px;
}

.client-tebimation svg {
  width: 27px;
  height: 29px;
}

.domain-section {
  background-color: #e6e6e6;
}

.domain-text h2 {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: bold;
  color: var(--atlasblack);
  line-height: 60px;
}

.domain-inner-logo p {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: var(--atlasblack);
  line-height: normal;
  text-transform: capitalize;
  transition: all 0.4s;
}

.domain-inner-logo:hover p {
  color: var(--atlasgreen);
}
.dark-mode .client-inner-logo img{
background: var(--atlasblack);
}
.client-inner-logo img {
  width: 100%;
  border: 1px solid var(--atlasblack);
  border-radius: 25px;
  transition: all 0.2s;
}
.client-inner-logo img:hover {
  box-shadow: 0 5px 8px #68686875;
  scale: 1.05;
}

.domain-inner-logo img {
  width: 70px;
  height: 70px;
  scale: 1;
  transition: all 0.4s;
}

.domain-inner-logo:hover img {
  scale: 1.05;
}

.client-logo-section .accordion-item-nav {
  display: flex;
  justify-content: start;
  align-items: stretch;
}

.domain-section .row .row .col {
  min-width: 20%;
}

@media (max-width: 995px) {
  .domain-section .row .row .col {
    min-width: 25%;
  }
}

@media (max-width: 767px) {
  .client-inner-logo img {
    border-radius: 15px;
  }
  .accordion-item-nav {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .client-logo-section .accordion-header {
    margin-bottom: 10px;
  }
  .client-tebimation {
    margin-right: 10px;
  }
  .client-logo-section .accordion-item-nav {
    margin-bottom: 30px;
  }
  .domain-section .row .row .col {
    width: 50%;
    flex: 0 0 auto;
  }
  .clients-content h2 {
    font-size: 42px;
    line-height: 50px;
  }
  .clients-content h3 {
    font-size: var(--f20);
    margin-bottom: 20px;
  }
  .domain-text h2 {
    font-size: 42px;
    line-height: 50px;
  }
  .domain-inner-logo p {
    font-size: 18px;
  }
  .domain-section .row {
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 450px) {
  .client-logo-section .accordion-header {
    width: 50%;
  }

  .domain-section .row .row {
    padding: 0;
    margin: 0;
  }
  .clients-content h2 {
    font-size: 28px;
    line-height: 32px;
  }
  .domain-text h2 {
    font-size: 28px;
    line-height: 32px;
  }
  .domain-inner-logo p {
    font-size: 16px;
  }
}
