#brand-inception h2 {
  font-weight: bold;
  font-size: 60px;
  text-transform: capitalize;
  background: linear-gradient(
    to right,
    var(--atlasgreen) 0%,
    var(--atlasblue) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.5s;
  margin-bottom: 30px;
  display: table;
}

#brand-inception h3 {
  font-weight: 500;
  font-size: 28px;
  text-transform: capitalize;
}

#brand-inception p {
  margin-bottom: 20px;
}

#brand-today .brand-today h3 {
  color: white;
  margin-bottom: 50px;
  font-size: 80px;
  font-weight: bold;
}

.dark-mode #brand-today .brand-today p {
  color: var(--atlasblack);
}
#brand-today .brand-today p {
  margin-bottom: 20px;
  color: var(--atlaswhite);
}

#brand-inception .years {
  font-size: 70px;
  line-height: normal;
  font-weight: bold;
  color: var(--atlasgreen);
  transform: rotate(-90deg);
  width: fit-content;
  display: table;
  line-height: 51px;
  margin-bottom: 0;
  transform-origin: top left;
  padding-left: 0px;
  padding-top: 40px;
  margin-bottom: 20px;
}

.since-title p {
  margin-bottom: 0px !important;
}
.customtopborder:not(:first-child) .customtopborderinnerone {
  border-top: 1px solid #cbcbcb;
}
@media (min-width: 992px) {
  .customtopborder {
    padding-top: 210px;
  }
  #brand-inception .customtopborder:not(:last-child) {
    border-right: 1px solid #cbcbcb;
  }
  #brand-inception .years {
    padding-left: 50px;
    padding-top: 20px;
    margin-bottom: 0;
  }
  .customtopborder .customtopborderinnerone {
    border-top: 1px solid #cbcbcb;
  }
  #brand-inception .logoimgbrand {
    height: 650px;
    width: 100%;
    object-fit: contain;
    object-position: top left;
  }
}
@media (max-width: 991px) {
  #brand-inception .years {
    transform: unset;
    line-height: 70px;
  }
}

@media (max-width: 768px) {
  #brand-inception h2 {
    font-size: 60px;
    margin-bottom: 10px;
  }

  #brand-today .brand-today h3 {
    font-size: 60px;
    margin-bottom: 30px;
  }
}

@media (max-width: 450px) {
  #brand-inception .years {
    font-size: 50px;
    line-height: 50px;
  }
  #brand-inception h3 {
    font-size: 24px;
  }
  #brand-inception h2 {
    font-size: 50px;
    line-height: 50px;
  }
  #brand-today .brand-today h3 {
    font-size: 50px;
  }
}
