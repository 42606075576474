.porfolio-card-main {
  display: flex;
  justify-content: start;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 30px;
}

.porfolio-card {
  width: calc(33.33% - 20px);
}

.porfolio-card .images-card {
  border: 1px solid;
  border-radius: 20px;
  overflow: hidden;
}

.porfolio-card .images-card figure .backgrounds {
  opacity: 0;
  transition: all 0.4s;
  position: absolute;
}

.porfolio-card .images-card figure .logos {
  opacity: 1;
  transition: all 0.4s;
}

.porfolio-card .images-card figure .backgrounds {
  height: 100%;
  object-fit: contain;
}

.porfolio-card .images-card figure img {
  background: white !important;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.porfolio-card .images-card figure {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  aspect-ratio: 4/3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.title {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.porfolio-card .title .card-btns {
  margin-left: 10px;
}

.porfolio-card .title .card-btns a {
  display: block;
}

.title h4 {
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  color: var(--atlasblack);
  margin: 0;
  margin-bottom: 10px;
  margin-right: 5px;
}

.title p {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #a2ba5f;
  margin: 0;
}

.card-tags {
  display: flex;
  justify-content: start;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.card-tags span {
  border-radius: 6px;
  padding: 1px 5px;
  display: block;
  color: #212529;
  font-size: 14px;
  line-height: 23px;
}

.porfolio-card:nth-child(7n + 1) .images-card {
  border-color: #e95036;
}

.porfolio-card:nth-child(7n + 2) .images-card {
  border-color: #fbb900;
}

.porfolio-card:nth-child(7n + 3) .images-card {
  border-color: #ffee01;
}

.porfolio-card:nth-child(7n + 4) .images-card {
  border-color: #65b33b;
}

.porfolio-card:nth-child(7n + 5) .images-card {
  border-color: #00a9e5;
}

.porfolio-card:nth-child(7n + 6) .images-card {
  border-color: #3c4395;
}

.porfolio-card:nth-child(7n + 7) .images-card {
  border-color: #694895;
}

.card-tags span:nth-child(4n + 1) {
  background-color: #e95036;
}

.card-tags span:nth-child(4n + 2) {
  background-color: #fbb900;
}

.card-tags span:nth-child(4n + 3) {
  background-color: #ffee01;
}

.card-tags span:nth-child(4n + 4) {
  background-color: #65b33b;
}

.porfolio-card:hover .images-card figure .backgrounds {
  opacity: 1;
}

.porfolio-card:hover .images-card figure .logos {
  opacity: 0;
}

.card-btns path,
.card-btns circle {
  transition: all 0.4s;
}

.porfolio-card:hover .card-btns path {
  stroke: white;
}

.porfolio-card:hover:nth-child(7n + 1) .card-btns circle {
  fill: #e95036;
}

.porfolio-card:hover:nth-child(7n + 2) .card-btns circle {
  fill: #fbb900;
}

.porfolio-card:hover:nth-child(7n + 3) .card-btns circle {
  fill: #ffee01;
}

.porfolio-card:hover:nth-child(7n + 4) .card-btns circle {
  fill: #65b33b;
}

.porfolio-card:hover:nth-child(7n + 5) .card-btns circle {
  fill: #00a9e5;
}

.porfolio-card:hover:nth-child(7n + 6) .card-btns circle {
  fill: #3c4395;
}

.porfolio-card:hover:nth-child(7n + 7) .card-btns circle {
  fill: #694895;
}

.portfolio-btn {
  text-align: center;
}

.portfolio-btn a {
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: white;
  line-height: 27px;
  border: 1px solid transparent;
  background: #212529;
  border-radius: 100px;
  padding: 8px 15px;
  text-decoration: none;
  transition: all 0.4s;
}

.portfolio-btn a:hover {
  background-color: var(--atlasgreen);
}

.select-portfolio {
  border: none;
  border-bottom: 1px solid var(--atlasblack) !important;
  border-radius: unset;
  background-color: transparent;
}

.select-portfolio option {
  color: var(--atlasblack);
  background-color: var(--atlaswhite);
}

.form-select:focus {
  box-shadow: none;
}
.form-select {
  background-image: url(../src/img/select-icon.svg);
}

.main-select div {
  width: 50%;
}

.portfolio-tab {
  width: 23%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  padding: 10px 0;
}

.portfolio-tab::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background-color: #c7c8c9;
  width: 1px;
  height: 100%;
}

.portfolio-selects {
  width: 40%;
  padding-left: 30px;
}

.portfolio-button {
  width: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-portfolio,
.portfolio-tab p {
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: var(--atlasblack);
  border-bottom: 1px solid transparent;
  transition: all 0.4s;
  cursor: pointer;
}

.portfolio-tab div {
  position: relative;
}

.portfolio-tab p:after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background: #006331;
  top: 50%;
  left: -20px;
  transform: translate(50%, -50%);
  transition: all 0.4s;
}

.portfolio-tab p:hover,
.portfolio-tab p.active {
  color: #006331;
  border-bottom: 1px solid #006331;
  cursor: pointer;
}

.filter_barmain {
  position: relative;
}

.work p {
  font-weight: 400;
}

#portfolio_slider .slider_inner {
  width: 90%;
  margin: 0 auto;
}

/* #portfolio_slider .slick-initialized .slick-list .slick-slide:hover img {
  scale: unset;
} */

#portfolio_slider .slider_inner img {
  scale: 1;
  transition: all 0.4s;
  box-shadow: unset;
  border: unset;
  overflow: hidden;
}

#portfolio_slider .slider_inner:hover img {
  scale: 1.05;
}

#portfolio_slider .slider_inner {
  display: block;
  border: 1px solid #cccccc;
  background-color: var(--atlaswhite);
  box-shadow: 0px 5px 8px #68686875;
  border-radius: 15px;
  overflow: hidden;
  scale: 1;
  transition: all 0.4s;
}

/* .filter_bar {
  position: sticky;
  top: 80px;
  z-index: 1;
} */

@media (max-width: 1400px) {
  .portfolio-button {
    width: 45px;
  }
  .portfolio-tab {
    width: 23%;
    padding-right: 20px;
  }
}

@media (max-width: 1200px) {
  .portfolio-selects {
    width: 50%;
    padding-left: 10px;
  }
  .portfolio-tab {
    width: 30%;
    padding-right: 20px;
  }
  .portfolio-tab div {
    margin-left: 30px;
  }
  .portfolio-tab div:last-child {
    margin-left: 50px;
  }
  .portfolio-button {
    width: 45px;
  }
}

@media (max-width: 992px) {
  .title h4 {
    font-size: 20px;
    line-height: 26px;
  }
  .portfolio-button {
    width: 45px;
    justify-content: space-evenly;
  }
  .portfolio-selects {
    width: 50%;
    padding-left: 0px;
  }
  .portfolio-tab {
    width: 100%;
    justify-content: center;
    margin-bottom: 30px;
  }
  .portfolio-tab::after {
    content: none;
  }
}
@media (max-width: 767px) {
  .portfolio-button {
    width: 45px;
    justify-content: center;
    gap: 20px;
  }
  .portfolio-selects {
    width: calc(100% - 100px);
    margin-bottom: 0;
  }
  .portfolio-tab {
    justify-content: center;
  }
  .main-select div {
    width: 48%;
    margin-bottom: 10px;
  }
  .main-select {
    justify-content: space-between;
  }
  .porfolio-card {
    width: calc(50% - 15px);
  }
  .title h4 {
    font-size: 20px;
    line-height: 20px;
  }
  .title p {
    line-height: 14px;
  }
  .card-btns svg {
    width: 30px;
    height: 30px;
  }
  .title {
    margin-top: 10px;
  }
  .card-tags {
    margin-top: 10px;
  }
}

@media (max-width: 500px) {
  .porfolio-card .images-card figure {
    aspect-ratio: 5 / 3;
  }
  .main-select div {
    width: 90%;
    margin-bottom: 10px;
  }
  .main-select {
    justify-content: center;
  }
  .porfolio-card-main {
    justify-content: center;
  }
  .porfolio-card {
    width: calc(100% - 15px);
  }
  .portfolio-button {
    order: 2;
    padding: 0 30px;
    margin-bottom: 30px;
  }
  .portfolio-selects {
    order: 3;
    width: 100%;
    padding: 0 30px;
  }
  .portfolio-tab {
    width: calc(100% - 100px);
    padding: 0 30px;
    justify-content: start;
  }
}

@media (max-width: 380px) {
  .portfolio-selects,
  .portfolio-tab,
  .portfolio-tab {
    padding: 0 10px;
  }
}
