.pn-color1 {
  background-color: #e95036;
}
.pn-color2 {
  background-color: #fbb900;
}
.pn-color3 {
  background-color: #ffee01;
}
.pn-color4 {
  background-color: #65b33b;
}
.pn-color5 {
  background-color: #00a9e5;
}
.pn-color6 {
  background-color: #3c4395;
}
.pn-color7 {
  background-color: #694895;
}
.pn-color0 {
  background-color: #fbb900;
}

.vacuum-inner-text h2 {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  margin-bottom: 30px;
  font-weight: bold;
  color: var(--atlasblack);
  line-height: 60px;
}

.vacuum-container {
  background-color: #ffffff;
  border-radius: 75px 75px 0px 0px;
}

.vacuum-content p {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: normal;
  color: var(--atlasblack);
  line-height: 36px;
}

.project-text h3 {
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  color: var(--atlasblack);
  line-height: 40px;
}

.problem-text h3 {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: bold;
  color: var(--atlasblack);
  line-height: 60px;
}

.problem-text p {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: normal;
  color: var(--atlasblack);
  line-height: 40px;
}

.solution-text h3 {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: bold;
  color: #ffffff;
  line-height: 60px;
}

.solution-text p {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: normal;
  color: #ffffff;
  line-height: 40px;
}

.solution-text {
  background-color: #212529;
  padding: 60px;
  border-radius: 20px;
  height: 100%;
}

.went-text h3 {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: bold;
  color: var(--atlasblack);
  line-height: 60px;
}

.went-text p {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: normal;
  color: var(--atlasblack);
  line-height: 40px;
}

.vacuum-line {
  border: 1px solid #212529;
  border-radius: 15px;
}

.owl-carousel-parent {
  width: 768px;
  height: 360px;
}

.owl-item,
.item {
  position: relative;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.item {
  display: block;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 53px;
  height: auto;
  overflow: hidden;
}

.center .item {
  margin-top: 0;
  height: auto;
  overflow: hidden;
  margin-bottom: 0;
}

.center .item .scale-wrapper {
  transform: scale(1.3);
}

.center .item .img-content {
  bottom: 0;
}

.owl-item.active.center {
  z-index: 5;
}

.owl-item.active.right,
.owl-item.active.left {
  z-index: 4;
}
@media screen and (max-width: 1499px) {
  .went-text h3,
  .solution-text h3,
  .problem-text h3,
  .vacuum-inner-text h2 {
    font-size: 44px;
    line-height: normal;
  }
  .problem-text p,
  .solution-text p,
  .went-text p,
  .vacuum-content p {
    font-size: 20px;
    line-height: normal;
  }
  .solution-text {
    padding: 40px;
  }
  .project-text h3 {
    font-size: 22px;
    line-height: normal;
  }
}

@media screen and (max-width: 1200px) {
  .vacuum-content p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .vacuum-inner-text h2 {
    font-size: 35px;
    line-height: 45px;
  }
  .vacuum-content p {
    font-size: 20px;
  }
  .project-text h3 {
    font-size: 22px;
    line-height: normal;
  }
  .solution-text h3,
  .went-text h3,
  .problem-text h3 {
    font-size: 42px;
  }
  .solution-text p,
  .went-text p,
  .problem-text p {
    font-size: 22px;
    line-height: normal;
  }
}

@media only screen and (max-width: 768px) {
  .vacuum-inner-text h2 {
    font-size: 35px;
    line-height: 45px;
  }

  .solution-text {
    padding: 30px;
  }
  .vacuum-content p {
    font-size: 18px;
    line-height: normal;
    margin: 0;
  }
  .project-text h3 {
    font-size: 20px;
    line-height: normal;
  }
  .solution-text h3,
  .went-text h3,
  .problem-text h3 {
    font-size: 32px;
  }
  .solution-text p,
  .went-text p,
  .problem-text p {
    font-size: 20px;
    line-height: normal;
  }
}
