.blog-section {
  padding: 50px 0;
}

#blog-content .blog-image img {
  border-radius: 25px;
  width: 100%;
  aspect-ratio: 4 / 2;
  object-fit: cover;
}

#blog_section .blog-inner-img {
  height: 100%;
}
#blog_section .blog-inner-img img {
  height: 100%;
  border-radius: 25px;
  object-fit: cover;
}

.blog-content h1 {
  font-size: 50px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  line-height: 60px;
  margin-top: 15px;
}

.author-icon img {
  width: 46px;
  height: 46px;
}

.author-text h3 {
  font-size: 14px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  margin-bottom: 0;
}

.author-text p {
  font-size: 13px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  line-height: normal;
  color: var(--atlasblack);
  margin-bottom: 0;
}

.author-content {
  gap: 25px;
}

.dot-sec {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--atlasgreen);
  border: 1px solid transparent;
}

.dot-sec1 {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--atlasblack);
  border: 1px solid transparent;
}

.dot-sec2 {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--atlasdarkblue);
  border: 1px solid transparent;
}

.dot-flex {
  gap: 18px;
}

.blog-content p {
  font-size: 18px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: #212529;
  line-height: 30px;
}

.blog-inner-content h2 {
  font-size: 50px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #212529;
  line-height: 60px;
}

.blog-inner-content p {
  font-size: 18px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: #212529;
  line-height: 30px;
}

.blog-btn h3 {
  font-size: 18px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: white;
  line-height: 30px;
  border: 1px solid;
  background: #212529;
  border-radius: 100px;
  width: 224px;
  height: 50px;
  text-align: center;
  padding: 10px 17px;
  transition: all 0.4s;
}

.blog-btn h3:hover {
  background: #a2ba5f;
}

a.blog-btn {
  text-decoration: none;
}

.blog-inner-text h3 {
  font-size: 40px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #212529;
  line-height: 60px;
}

.blog-inner-content ul {
  padding-left: 1.5rem !important;
}

.blog-inner-content li::marker {
  color: #0d6b3b;
  font-weight: bold;
}
.blog-inner-content li {
  list-style: lower-latin;
}

.blog-inner-content li b {
  color: #0d6b3b;
}

.blog-icon {
  gap: 30px;
}

.blog-inner-icon {
  gap: 30px;
}

.blog-inner-icon a:hover img {
  scale: 1.1;
}

.blog-inner-icon a img {
  transition: all 0.4s;
}

.touch-form h2 {
  font-size: 40px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: white;
  line-height: 60px;
}

.touch-control {
  padding: 0 0.75rem;
  line-height: 20px;
  border: none;
  border-bottom: 1px solid #ffffff;
  border-radius: 0;
  background-color: transparent !important;
}

.touch-control:focus {
  color: white;
  background-color: transparent;
  border-color: white;
  outline: 0;
  box-shadow: none;
}

.touch-group label {
  color: white;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.blog-form {
  color: var(--atlasblack);
  padding: 10px;
}

.detail-form .accordion-item{
  border-radius: 10px !important;
  margin-bottom: 10px;
  padding: 0;
}

.detail-form .accordion-item:last-child{
  margin-bottom: 0;
}

.detail-form .accordion-item .accordion-button{
  padding: 0;
  background-color: transparent;
  color: var(--atlasblack);
}

.detail-form .accordion-header {
  background-color: var(--atlasdarkblue);
  border-radius: 10px;
  padding: 10px 20px;
}
  
.detail-form .accordion-body {
  padding: 10px 20px !important;
}

.dark-mode .detail-form .accordion-header .accordion-button{
  color: var(--atlasblack);
}

.detail-form .accordion-header .accordion-button{
  font-size: 22px;
  font-weight: 600;
  color: var(--atlaswhite);
}

.detail-form .accordion-button::after{
  content: '';
  filter: brightness(0) invert(1);
}

.detail-form .table-data-blog>*{
  display: none;
}

.detail-form .table-data-blog h1,
.detail-form .table-data-blog h2,
.detail-form .table-data-blog h3,
.detail-form .table-data-blog h4,
.detail-form .table-data-blog h5,
.detail-form .table-data-blog h6{
  display: block;
  font-size: 18px;
  line-height: 24px;
  padding-left: 20px;
  margin-bottom: 10px;
  position: relative;
  font-weight: 400;
}

.detail-form .table-data-blog h1::after,
.detail-form .table-data-blog h2::after,
.detail-form .table-data-blog h3::after,
.detail-form .table-data-blog h4::after,
.detail-form .table-data-blog h5::after,
.detail-form .table-data-blog h6::after{
  content: '';
  position: absolute;
  top: 8px;
  left: 0;
  width: 8px;
  height: 8px;
  background-color: var(--atlasblack);
  border-radius: 50px;
}

.detail-form .table-data-blog strong{
  font-weight: unset;
}

.detail-form .accordion-body{
  padding: 0;
}

form#newsletter-form .artical-group label,
form#newsletter-form .artical-group input{
  border-color: var(--atlasblack);
  color: var(--atlasblack);
}
.touch-group label {
  color: var(--atlasblack);
}
.blog-submit button.btn h3{
  background-color: var(--atlasgreen);
  color: var(--atlaswhite);
}
.touch-control {
  padding: 0 0.75rem;
  line-height: 20px;
  border: none;
  border-bottom: 1px solid var(--atlasblack);
  border-radius: 0;
  background-color: transparent !important;
}
.blog-submit button.btn h3 {
  border: 2px solid var(--atlasgreen);
}

.artical-control,
.blog-form select.form-control,
.blog-form input.form-control {
  color: var(--atlasgreen);
  font-weight: bold;
  padding: 10px 0;
}

.artical-control {
  color: var(--atlasblue);
}

.artical-form .artical-form {
  padding: 0;
}
.blog-submit h3 {
  font-size: 20px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #212529;
  line-height: 35px;
  border: 1px solid transparent;
  background: white;
  border-radius: 100px;
  width: auto;
  text-align: center;
  padding: 10px;
  transition: all 0.4s;
}

.blog-submit a:hover h3 {
  color: white;
  background: var(--atlasblack);
}

.artical-form {
  background: #a2ba5f;
  padding: 20px;
  border-radius: 25px;
  border: 1px solid transparent;
}

.artical-form h2 {
  color: #212529;
  font-size: 40px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  line-height: 60px;
}

.artical-group label {
  color: #212529;
  text-transform: capitalize;
}

.artical-control {
  padding: 10px 0;
  line-height: 20px;
  border: none;
  border-bottom: 1px solid #212529;
  border-radius: 0;
  background-color: transparent;
}

.detail-form {
  position: unset;
}

form#newsletter-form {
  margin-top: 30px;
}

form#newsletter-form .artical-group{
  margin-top: 20px;
}

.form-group.touch-group {
  margin-top: 10px;
}

.blog-submit button.btn {
  width: 100%;
}

.artical-control:focus,
.blog-submit button.btn:focus {
  outline: 0;
  box-shadow: none;
}

.form-control:focus {
  background-color: transparent;
  border-bottom: 1px solid var(--atlasgreen);
}

.blog-submit button.btn:hover h3 {
  background-color: var(--atlasblack);
  color: var(--atlaswhite);
  border: 2px solid var(--atlaswhite);
}

.listing_blog h2 {
  font-size: 30px;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 10px;
  margin-top: 50px;
}

.listing_blog h2:first-child {
  margin-top: 0;
}

.listing_blog ul, .listing_blog ol {
  padding-left: 20px !important;
}

.listing_blog ol li,
.listing_blog ul li,
.listing_blog p {
  font-weight: 400;
  line-height: normal;
  font-size: 20px;
  margin-bottom: 10px;
  list-style: unset;
}

.listing_blog p strong {
  color: var(--atlasblack);
}

.listing_blog a strong,
.listing_blog a {
  color: var(--atlasdarkgreen);
  font-weight: bold;
  font-size: 18px;
  transition: all 0.4s;
  border-bottom: 2px solid transparent;
}

.listing_blog a:hover {
  /* text-decoration: underline !important; */
  border-bottom: 2px solid var(--atlasdarkgreen);
}
.listing_blog img {
  width: 100%;
  height: auto;
}

form#newsletter-form .artical-control,
form#blog-form input.form-control {
  background: transparent !important;
}

.table_of_contents{
  padding-left: 20px !important;
}

.table_of_contents li {
  list-style: circle;
}

.table_of_contents li a {
  color: var(--atlasblack);
}

.table_of_contents li a:hover {
  color: var(--atlasblue);
}

.listing_blogs h1{
  font-size: 46px;
  line-height: 56px;
  }
  
.listing_blogs h2{
  font-size: 40px;
  line-height: 50px;
  margin-top: 30px;
    margin-bottom: 15px;
}
  
.listing_blogs h3{
  font-size: 36px;
  line-height: 46px;
  margin-top: 30px;
    margin-bottom: 15px;
}

.listing_blogs h4{
  font-size: 32px;
  line-height: 42px;
  margin-top: 30px;
    margin-bottom: 15px;
}

.listing_blogs h5{
  font-size: 28px;
  line-height: 38px;
  margin-top: 30px;
    margin-bottom: 15px;
}

.listing_blogs h6{
  font-size: 24px;
  line-height: 34px;
  margin-top: 30px;
    margin-bottom: 15px;
}
.listing_blogs iframe{width: 100% !important;}

form#blog-form input::placeholder {
  text-transform: capitalize;
}

@media only screen and (max-width: 1499px) {
  .blog-content h1 {
    font-size: 44px;
    line-height: normal;
  }
  .artical-form h2,
  .touch-form h2 {
    font-size: 32px;
    line-height: normal;
  }
}
@media only screen and (max-width: 768px) {
  .listing_blog h2 {
    font-size: 28px;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 10px;
    margin-top: 50px;
  }

  .listing_blog h2:first-child {
    margin-top: 0;
  }

  .listing_blog ul li,
  .listing_blog p {
    font-weight: 400;
    line-height: normal;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .blog-inner-content h2 {
    font-size: 35px;
    line-height: 44px;
  }

  .blog-inner-text h3 {
    font-size: 30px;
    line-height: 34px;
  }

  .blog-content h1 {
    font-size: 35px;
    line-height: 45px;
  }

  .blog-inner-icon {
    gap: 15px;
  }

  .artical-form h2 {
    font-size: 35px;
    line-height: 47px;
  }

  .blog-icon {
    gap: 0;
  }

  .listing_blogs h1{
    font-size: 44px;
    line-height: 54px;
    }
    
  .listing_blogs h2{
    font-size: 38px;
    line-height: 48px;
  }
    
  .listing_blogs h3{
    font-size: 34px;
    line-height: 44px;
  }
  
  .listing_blogs h4{
    font-size: 30px;
    line-height: 40px;
  }
  
  .listing_blogs h5{
    font-size: 26px;
    line-height: 36px;
  }
  
  .listing_blogs h6{
    font-size: 20px;
    line-height: 30px;
  }
}

@media (max-width:500px){
  .listing_blogs h1{
    font-size: 30px;
    line-height: 40px;
    }
    
  .listing_blogs h2{
    font-size: 28px;
    line-height: 38px;
  }
    
  .listing_blogs h3{
    font-size: 26px;
    line-height: 36px;
  }
  
  .listing_blogs h4{
    font-size: 24px;
    line-height: 34px;
  }
  
  .listing_blogs h5{
    font-size: 22px;
    line-height: 32px;
  }
  
  .listing_blogs h6{
    font-size: 20px;
    line-height: 30px;
  }
}


@media (min-width:992px){
  .detail-form {
    top: 100px;
    position: sticky;
  }
}