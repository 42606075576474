/* == Section One === */

.create-experiences-section .d-flex {
  gap: 100px;
}

.create-experiences-section label {
  font-size: 24px;
  font-weight: bold;
  color: var(--atlasblack);
  white-space: nowrap;
  margin-right: 25px;
  text-transform: capitalize;
}

.create-experiences-section .form-control {
  border: none;
  border-bottom: solid 1px #e6e6e6;
  font-size: 24px;
  padding: 0 0 20px;
  font-weight: bold;
}

.create-experiences-section .form-control:hover,
.create-experiences-section .form-control:focus {
  box-shadow: none;
  border-color: #000;
}

.create-experiences-section ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #e6e6e6;
}

.create-experiences-section ::-moz-placeholder {
  /* Firefox 19+ */
  color: #e6e6e6;
}

.create-experiences-section :-ms-input-placeholder {
  /* IE 10+ */
  color: #e6e6e6;
}

.create-experiences-section :-moz-placeholder {
  /* Firefox 18- */
  color: #e6e6e6;
}

.create-experiences-section .form-group {
  margin-bottom: 15px;
}

form p br {
  display: none;
}
.create-experiences-section .form-group p {
  display: flex;
  align-items: start;
}
.error-messages p {
  font-size: 14px;
}
.create-experiences-section .form-group .lable-and-input,
.create-experiences-section .form-group .error-messages {
  width: 100%;
}
.create-experiences-section .form-group.d_open .error-messages {
  width: auto;
}

.create-experiences-section .service-select .service-type p span .wpcf7-radio {
  display: flex;
  justify-content: start;
  align-items: stretch;
  flex-wrap: wrap;
}

.create-experiences-section
  .service-select
  .service-type
  p
  span
  .wpcf7-radio
  .first {
  margin-left: 0;
}

.create-experiences-section
  .service-select
  .service-type
  p
  span
  .wpcf7-radio
  span {
  margin: 5px;
}
.create-experiences-section
  .service-select
  .service-type
  p
  span
  .wpcf7-radio
  .last {
  margin-right: 0;
}

.create-experiences-section textarea.form-control {
  height: 80px;
  resize: none;
}

.create-experiences-section
  .service-select
  .service-type
  p
  span
  .wpcf7-radio
  span
  label {
  border-radius: 50px;
  background-color: var(--atlasblack);
}

.create-experiences-section .service-select input[type="radio"]:checked {
  background-color: var(--atlasgreen);
  border-radius: 50px;
  z-index: -1;
}

.create-experiences-section .service-select label:hover,
.create-experiences-section
  .service-select
  input[type="radio"]:hover:not(:checked) {
  background-color: var(--atlasgreen);
  border-radius: 50px;
  z-index: -1;
  color: var(--atlaswhite);
}

.create-experiences-section .btn {
  font-size: 18px;
  padding: 12px 38px;
  border-radius: 50px;
  background: #212529;
  color: #fff;
  font-weight: bold;
  border: solid 1px #000;
  margin-top: 30px;
}

.create-experiences-section .btn input {
  background: transparent;
  border: none;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.create-experiences-section .btn:hover input {
  background: #fff;
  color: #000;
}

.create-experiences-section .btn:hover,
.create-experiences-section .btn:focus,
.create-experiences-section .btn:active {
  background: #fff;
  color: #000;
}

.create-experiences-section .contact-details span {
  font-size: 18px;
  display: block;
  color: #212529;
  margin-bottom: 10px;
  line-height: 30px;
}

.create-experiences-section .contact-details span p {
  margin: 0;
  font-size: 18px;
  line-height: normal;
}

.create-experiences-section .contact-details a {
  font-size: 24px;
  display: inline-block;
  color: var(--atlasblack);
  font-weight: bold;
  transition: all 0.3s linear;
  white-space: nowrap;
}

.create-experiences-section .contact-details a:focus,
.create-experiences-section .contact-details a:active,
.create-experiences-section .contact-details a:hover {
  color: #a2ba5f;
  transition: all 0.3s linear;
}

.create-experiences-section .contact-details .text div {
  width: fit-content;
}
.create-experiences-section .contact-details .text {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.create-experiences-section .contact-details .text img {
  display: block;
  max-width: 100%;
  width: 34px;
  height: 34px;
}

.create-experiences-section .contact-details .text div {
  margin-left: 15px;
}

.create-experiences-section .details {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  margin-top: 200px;
  text-align: start;
  align-items: self-start;
}

/* === Section Two === */

.our-office-section .title-box .d-flex {
  align-items: center;
}

.our-office-section .title-box .d-flex img {
  margin-right: 20px;
}

.our-office-section .title-box h2 {
  white-space: nowrap;
}

.our-office-section .title-box p {
  font-weight: 600;
  margin-top: 60px;
}

.our-acknowledgements .title-box {
  margin-top: 30px;
}

.our-office-section {
  background: #e6e6e6;
}

.our-office-section .our-office {
  width: 40%;
  padding-right: 40px;
}

.our-office-section .our-acknowledgements {
  width: 60%;
}

.our-office-section .our-office ul {
  display: flex;
  gap: 40px;
  align-items: center;
}

.our-office-section .our-office ul li a img {
  width: 32px;
  height: 32px;
}

.our-office-section .our-acknowledgements ul {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  row-gap: 30px;
}

.our-office-section .our-acknowledgements li {
  width: calc(20% - 12px);
}

.our-office-section .our-acknowledgements li img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  object-fit: contain;
  border-radius: 15px;
  border: 4px solid var(--atlasgreen);
  padding: 10px;
  transition: all 0.4s;
}

.our-office-section .our-acknowledgements li:hover img {
  box-shadow: 10px 10px 20px #0000001a;
  background-color: var(--atlaswhite);
}

.our-office-section .our-office li:hover img,
.our-office-section .our-office li img {
  transition: all 0.3s linear;
}

.our-office-section .our-office li:hover img {
  transform: scale(1.2);
}

.our-office-section .our-office li img {
  transform: scale(1);
}

.our-office-section .office-acknowledgements {
  align-items: start;
}

/* === Section Three === */

.hire-apply-job-section .nav-tabs {
  border: none;
  justify-content: center;
  gap: 290px;
  position: relative;
  margin-bottom: 85px;
}

.hire-apply-job-section .hire-info {
  display: flex;
  flex-wrap: wrap;
}

.hire-apply-job-section .nav-item {
  padding-bottom: 15px;
  position: relative;
}

.hire-apply-job-section .nav-item .nav-link {
  border: none;
  font-size: 24px;
  color: var(--atlasblack) !important;
  font-weight: bold;
  padding: 0;
  position: relative;
  display: block;
  padding-bottom: 33px;
  background: transparent;
}

.hire-apply-job-section .nav-item:focus-visible,
.hire-apply-job-section .nav-item:focus,
.hire-apply-job-section .nav-item:hover,
.hire-apply-job-section .nav-item .nav-link:focus-visible,
.hire-apply-job-section .nav-item .nav-link:focus,
.hire-apply-job-section .nav-item .nav-link:hover {
  border: none;
  outline: none;
  transition: all 0.3s linear;
}

.hire-apply-job-section .nav-item .nav-link:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: #212529;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 4;
  border-radius: 50%;
}

.hire-apply-job-section .nav-item .nav-link:before {
  content: "";
  position: absolute;
  width: 38px;
  height: 38px;
  border: solid 1px transparent;
  bottom: -13px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 3;
  background-color: var(--atlaswhite);
}

.hire-apply-job-section .nav-item .nav-link.active {
  border: none;
  color: #a2ba5f !important;
}

.hire-apply-job-section .nav-item .nav-link.active:after {
  background: #a2ba5f;
}

.hire-apply-job-section .nav-item .nav-link.active:before {
  background-image: url(../src/img/contact/tab-border.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.hire-apply-job-section .nav-tabs:after {
  content: "";
  position: absolute;
  width: 28%;
  bottom: 18px;
  height: 1px;
  background: #212529;
  z-index: -1;
}

.hire-apply-job-section .box .bullet-point-ui {
  display: flex;
  justify-content: end;
  margin-top: 5px;
}

.hire-apply-job-section .box {
  background: #e6e6e6;
  border-radius: 25px;
  padding: 21px;
  flex: 0 0 30%;
  margin: 0 3.33% 3.33% 0;
  transition: all 0.4s;
}

.hire-apply-job-section .box:hover {
  box-shadow: 10px 10px 10px #89898961;
}

.hire-apply-job-section .box p,
.hire-apply-job-section .box p a {
  font-size: 24px;
  font-weight: bold;
  color: #212529;
  margin: 0;
  transition: all 0.4s;
}

.hire-apply-job-section .box:hover p,
.hire-apply-job-section .box:hover p a {
  color: #a2ba5f;
}

.hire-apply-job-section .box .d-flex {
  align-items: start;
}

.hire-apply-job-section .box .d-flex img {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  flex: 0 0 auto;
  transition: all 0.4s;
}

.hire-apply-job-section .box:hover .d-flex img {
  scale: 1.1;
}

.create-experiences-section .more-details {
  display: none;
}

.create-experiences-section .form-arrow {
  margin-bottom: 15px;
  display: none;
}

.expanded-form .create-experiences-section .title-box {
  display: none;
}

.expanded-form .create-experiences-section .more-details {
  display: block;
}

.expanded-form .create-experiences-section .form-arrow {
  display: block;
}

.create-experiences-section .service-select {
  flex-wrap: wrap;
  gap: 10px;
}

.create-experiences-section .service-select .service-type {
  overflow: hidden;
  position: relative;
}

.create-experiences-section .service-select .service-type input {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  appearance: none;
}

.create-experiences-section .service-select .service-type label {
  font-size: 18px;
  color: #fff;
  position: relative;
  z-index: 1;
  text-align: center;
  display: block;
  width: 100%;
  font-weight: normal;
  margin: 0;
  padding: 4px 12px;
  cursor: pointer;
}

.create-experiences-section .note {
  color: #ff0000;
  font-size: 14px;
  display: block;
  margin-bottom: 15px;
}

.create-experiences-section .form-group.service-select {
  flex-direction: row;
}

.contact-details {
  width: calc(50% - 50px);
}
.title-form {
  width: calc(50% - 50px);
}

.hire-apply-job-section .box {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.hire-apply-job-section .box > * {
  width: 100%;
}

div#contactForm form .form-group,
div#contactForm form .form-group .lable-and-input {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

div#contactForm form .form-group label {
  max-width: 40%;
}
div#contactForm form .form-group .error-messages {
  width: 50%;
}
div#contactForm form .form-group select,
div#contactForm form .form-group input {
  width: 100%;
  background-color: transparent;
  font-weight: 600;
  color: var(--atlasgreen);
  transition: all 0.4s;
}

#contactForm .more-detail .backbutton {
  margin-bottom: 30px;
  display: block;
}

#contact-api-form input:focus-visible {
  outline: none;
}

#contact-api-form .contact-input {
  border: none;
  border-bottom: 2px solid #e6e6e6;
  padding: 5px 10px;
}

div#contactForm form .form-group select:hover,
div#contactForm form .form-group input:hover {
  border-bottom: 2px solid var(--atlasgreen);
}

#contact-api-form .form-group {
  margin-bottom: 30px;
}

#contact-api-form .services-select label {
  /* color: var(--atlaswhite); */
}
#contact-api-form .services-select input {
  position: absolute;
  cursor: pointer;
  opacity: 0;
  width: 100% !important;
  height: 100% !important;
}
#contact-api-form .services-select label {
  max-width: 100% !important;
}
.services-select {
  margin-bottom: 10px;
  margin-right: 10px;
}
.services-select label {
  font-size: 18px !important;
  margin: 0;
  font-weight: 400;
  background-color: var(--atlasblack);
  color: var(--atlaswhite);
  transition: all 0.4s;
  padding: 4px 15px;
  cursor: pointer;
  border-radius: 50px;
}

.services-select label:hover,
.services-select input:hover + label,
.services-select input[type="checkbox"]:checked + label {
  background-color: var(--atlasgreen);
}

.services-select:nth-child(4n - 1) input[type="checkbox"]:checked + label {
  background-color: var(--atlasgreen);
}

.services-select:nth-child(4n - 2) input[type="checkbox"]:checked + label {
  background-color: var(--atlasdarkgreen);
}

.services-select:nth-child(4n - 3) input[type="checkbox"]:checked + label {
  background-color: var(--atlasblue);
}

.services-select:nth-child(4n - 4) input[type="checkbox"]:checked + label {
  background-color: var(--bs-gray);
}

#contact-api-form .services-select {
  display: inline-block;
  position: relative;
}

/* === Form Detail === */
.second_day {
  /* height: 650px; */
}
.d_open.con_can {
  height: 215px;
  transition: all 0.6s;
}
.d_open.backbuttons {
  height: 50px;
  transition: all 0.6s;
}

.backbutton {
  display: block;
}

#contact-api-form .form-group.d_close {
  margin-bottom: 0;
  transition: all 0.6s;
}
.d_open {
  position: relative;
  left: 0;
  right: auto;
  opacity: 1;
  overflow: hidden;
  margin-bottom: 20px;
  /* z-index: 2; */
  transition: all 0.6s;
}
.d_close {
  position: relative;
  left: 0;
  right: auto;
  height: 0;
  opacity: 0;
  overflow: hidden;
  /* z-index: 2; */
  transition: all 0.6s;
}
.textareadiv .error-messages {
  width: 100%;
  display: block;
  min-width: 100%;
}

div#contactForm form .error-messages p {
  margin: 0;
  line-height: 20px;
  display: block !important;
  font-size: 14px;
  font-weight: 400;
  text-align: end;
}

div#contactForm form .recaptcha-error-messages p {
  text-align: start;
}

.con_can h2 {
  margin-bottom: 20px;
}
.contactlinkabsolute a {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 21px 21px 21px 90px;
}
/* === Form Detail === */
/* media css */

@media screen and (max-width: 1919px) {
  .custom-container {
    padding: 0 128px;
  }

  .common-bottom-section {
    padding-bottom: 100px;
  }

  .common-top-section {
    padding-top: 100px;
  }

  .title-box h2 {
    font-size: 42px;
  }

  /* .title-box {
    margin-bottom: 50px;
  } */

  .comman-btn {
    font-size: 22px;
  }

  /* contact us style */
  .create-experiences-section .details {
    margin-top: 130px;
  }

  .our-office-section .our-office {
    width: 40%;
  }
}

@media screen and (max-width: 1599px) {
  .custom-container {
    padding: 0 108px;
  }

  .comman-btn.top-space {
    margin: 80px auto 0;
  }

  .common-bottom-section {
    padding-bottom: 80px;
  }

  .common-top-section {
    padding-top: 80px;
  }

  .title-box h2 {
    font-size: 36px;
  }

  .title-box p {
    font-size: 20px;
    line-height: normal;
  }

  .comman-btn {
    font-size: 20px;
    padding: 15px 30px 15px 80px;
  }

  .comman-btn::before {
    width: 50px;
    height: 50px;
  }

  .comman-btn::after {
    left: 22px;
  }

  .comman-btn:hover {
    padding: 15px 80px 15px 30px;
  }

  .comman-btn:hover:before {
    left: calc(100% - 58px);
  }

  .comman-btn:hover:after {
    left: calc(100% - 46px);
  }

  .bullet-point-ui {
    gap: 10px;
  }

  .bullet-point-ui li {
    width: 16px;
    height: 16px;
  }

  /* contact us style */
  .create-experiences-section label,
  .create-experiences-section .form-control,
  .hire-apply-job-section .box p,
  .hire-apply-job-section .box p a,
  .create-experiences-section .contact-details a {
    font-size: 22px;
  }

  .hire-apply-job-section .nav-tabs {
    margin-bottom: 60px;
  }

  .our-office-section .our-office ul {
    gap: 30px;
  }

  .create-experiences-section .contact-details span {
    white-space: normal;
  }

  .create-experiences-section .d-flex {
    gap: 80px;
  }

  .our-office-section .our-office {
    width: 40%;
  }

  .hire-apply-job-section .nav-tabs {
    gap: 220px;
  }
}

@media screen and (max-width: 1365px) {
  .custom-container {
    padding: 0 100px;
  }

  .comman-btn.top-space {
    margin: 70px auto 0;
  }

  .common-bottom-section {
    padding-bottom: 70px;
  }

  .common-top-section {
    padding-top: 70px;
  }

  .title-box h2 {
    font-size: 34px;
  }

  .title-box p {
    flex: 0 0 52%;
  }

  /* contact us style */
  .create-experiences-section .contact-details img {
    height: 120px;
  }

  .create-experiences-section .details img {
    height: auto;
  }

  .create-experiences-section .details {
    margin-top: 80px;
  }

  .create-experiences-section .details {
    gap: 50px;
  }

  .create-experiences-section .d-flex {
    gap: 60px;
  }

  .our-office-section .our-office {
    width: 40%;
  }

  .our-office-section .our-acknowledgements li {
    flex: 0 0 16%;
    margin-bottom: 20px;
  }

  .our-office-section .our-acknowledgements ul {
    gap: 5%;
  }

  .create-experiences-section label,
  .create-experiences-section .form-control,
  .hire-apply-job-section .box p,
  .create-experiences-section .contact-details a,
  .hire-apply-job-section .box p a {
    font-size: 20px;
  }

  .create-experiences-section .contact-details img {
    height: 100px;
  }

  .create-experiences-section .details {
    margin-top: 60px;
  }

  .create-experiences-section .details img {
    height: auto;
  }

  .hire-apply-job-section .nav-item .nav-link {
    font-size: 22px;
  }

  .our-office-section .our-office li img {
    height: 22px;
  }

  .hire-apply-job-section .nav-tabs {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1279px) {
  .comman-btn.top-space {
    margin: 50px auto 0;
  }

  .common-bottom-section {
    padding-bottom: 60px;
  }

  .common-top-section {
    padding-top: 60px;
  }

  .title-box {
    margin-bottom: 25px;
  }

  .title-box h2 {
    font-size: 30px;
  }

  .custom-container {
    padding: 0 60px;
  }

  /* contact us style */
  .create-experiences-section .details,
  .create-experiences-section .d-flex {
    gap: 30px;
  }

  .our-office-section .our-office {
    width: 40%;
  }

  .hire-apply-job-section .box {
    padding: 18px;
  }

  .hire-apply-job-section .nav-tabs {
    margin-bottom: 40px;
  }

  .our-office-section .our-office li img {
    height: 20px;
  }

  .our-office-section .our-office ul {
    gap: 24px;
  }

  .our-office-section .title-box p {
    margin-top: 40px;
  }

  .create-experiences-section .btn {
    margin-top: 0;
  }

  .create-experiences-section .details {
    margin-top: 40px;
  }

  .our-office-section .our-office .title-box img {
    height: 80px;
  }

  .our-office-section .our-office {
    width: 40%;
  }

  .hire-apply-job-section .box .d-flex img {
    margin-right: 15px;
    width: 36px;
  }
  .contactlinkabsolute a {
    padding: 21px 21px 21px 70px;
  }

  .create-experiences-section label,
  .create-experiences-section .form-control,
  .hire-apply-job-section .box p,
  .create-experiences-section .contact-details a,
  .hire-apply-job-section .box p a {
    font-size: 18px;
  }
}

@media screen and (max-width: 1023px) {
  .custom-container {
    padding: 0 50px;
  }

  .comman-btn.top-space {
    margin: 40px auto 0;
  }

  .common-bottom-section {
    padding-bottom: 40px;
  }

  .common-top-section {
    padding-top: 40px;
  }

  .title-box h2 {
    font-size: 26px;
  }

  .bullet-point-ui li {
    width: 14px;
    height: 14px;
  }

  /* contact us style */
  .hire-apply-job-section .box {
    flex: 0 0 48%;
    margin: 0 0 4% 0;
  }

  .hire-apply-job-section .box:nth-child(odd) {
    margin-right: 4%;
  }

  .hire-apply-job-section .nav-tabs {
    gap: 180px;
  }

  .contact-details,
  .title-form {
    width: 100%;
  }

  .create-experiences-section .contact-details {
    margin: 0 auto;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .comman-btn {
    font-size: 18px;
    padding: 12px 20px 12px 70px;
  }
  .comman-btn:hover {
    padding: 12px 70px 12px 20px;
  }
  .our-office-section .office-acknowledgements,
  .create-experiences-section .details,
  .create-experiences-section .d-flex {
    flex-direction: column;
  }
  .our-office-section .our-acknowledgements {
    margin-top: 30px;
  }
  .custom-container {
    padding: 0 30px;
  }

  .title-box h2 {
    line-height: normal;
  }

  /* contact us style */
  .create-experiences-section .contact-details img {
    height: 80px;
  }

  .create-experiences-section .details img {
    height: auto;
  }

  .hire-apply-job-section .nav-tabs {
    gap: 80px;
  }

  .create-experiences-section .service-select .service-type label {
    font-size: 16px;
  }
  .our-office-section .our-office {
    width: 100%;
  }
  .our-office-section .our-acknowledgements {
    width: 100%;
  }

  .our-office-section .our-office {
    padding-right: 0;
  }
}

@media screen and (max-width: 639px) {
  .comman-btn::before {
    width: 40px;
    height: 40px;
  }

  .comman-btn::after {
    left: 20px;
    width: 15px;
    height: 15px;
  }

  .comman-btn:hover {
    font-size: 18px;
    padding: 10px 70px 10px 20px;
  }

  .comman-btn:hover::before {
    left: calc(100% - 48px);
  }

  .comman-btn:hover::after {
    left: calc(100% - 38px);
  }

  /* contact us style */
  .create-experiences-section .details {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .create-experiences-section .contact-details img {
    width: 100%;
    height: auto;
  }

  .create-experiences-section .details img {
    width: auto;
    height: auto;
  }

  .our-office-section .our-office .title-box img {
    height: 60px;
  }

  .hire-apply-job-section .hire-info {
    flex-direction: column;
  }

  .hire-apply-job-section .box:nth-child(2n + 1),
  .hire-apply-job-section .box {
    margin: 0 0 15px;
  }

  .hire-apply-job-section .box p br {
    display: none;
  }

  .hire-apply-job-section .box p,
  .hire-apply-job-section .box p a {
    line-height: normal;
  }

  .hire-apply-job-section .nav-item .nav-link {
    font-size: 18px;
  }

  .hire-apply-job-section .nav-tabs {
    gap: 40px;
  }

  .create-experiences-section .form-group {
    flex-direction: column;
  }
  #NewsModal form .error-messages,
  #NewsModal form {
    width: 100%;
  }
  .google-recaptcha > div > div {
    transform: scale(0.7) !important;
    -webkit-transform: scale(0.7) !important;
  }

  div#contactForm form .form-group {
    flex-direction: row;
  }

  .create-experiences-section label {
    margin: 0 0 10px;
  }

  .create-experiences-section .btn {
    font-size: 16px;
    padding: 10px 36px;
  }

  .our-office-section .title-box p {
    margin-top: 30px;
  }

  .our-office-section .title-box p br {
    display: none;
  }

  .create-experiences-section .form-control {
    padding: 0 0 15px;
  }
  .our-office-section .our-acknowledgements {
    width: 100%;
    margin-top: 30px;
  }
  .our-office-section .our-office {
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .comman-btn {
    line-height: normal;
    font-size: 16px;
  }

  .comman-btn.top-space {
    margin: 30px auto 0;
  }

  .common-bottom-section {
    padding-bottom: 30px;
  }

  .common-top-section {
    padding-top: 30px;
  }

  .custom-container {
    padding: 0 20px;
  }

  .title-box h2 {
    font-size: 28px;
  }

  .title-box {
    margin-bottom: 15px;
  }

  .title-box p {
    font-size: 16px;
  }

  .bullet-point-ui li {
    width: 12px;
    height: 12px;
  }

  /* contact us style */
  .create-experiences-section label,
  .create-experiences-section .form-control,
  .hire-apply-job-section .box p,
  .create-experiences-section .contact-details a {
    font-size: 16px;
  }

  .our-office-section .our-office ul {
    gap: 15px;
    justify-content: space-between;
  }

  .our-office-section .our-acknowledgements li {
    flex: 0 0 30%;
  }

  .our-office-section .our-acknowledgements ul {
    gap: 4%;
  }

  .hire-apply-job-section .nav-tabs {
    gap: 20px;
  }

  .hire-apply-job-section .nav-item .nav-link {
    font-size: 16px;
  }

  .create-experiences-section .contact-details span {
    font-size: 15px;
  }

  .hire-apply-job-section .box .d-flex img {
    width: 28px;
  }

  .hire-apply-job-section .nav-item .nav-link::after {
    width: 10px;
    height: 10px;
  }

  .hire-apply-job-section .nav-item .nav-link::before {
    width: 34px;
    height: 34px;
  }

  .our-office-section .our-office .title-box img {
    height: 40px;
  }

  .create-experiences-section .btn {
    width: 100%;
    margin-top: 20px;
  }
  .our-office-section .our-acknowledgements {
    width: 100%;
  }
}
@media screen and (min-width: 380px) {
  .d_open.con_can {
    height: 160px;
    transition: all 0.6s;
  }
}
@media screen and (min-width: 768px) {
  .d_open.con_can {
    height: 210px;
  }
}
@media screen and (min-width: 1200px) {
  .d_open.con_can {
    height: 171px;
  }
}
@media screen and (min-width: 1400px) {
  .d_open.con_can {
    height: 182px;
  }
}
@media screen and (min-width: 1600px) {
  .d_open.con_can {
    height: 220px;
  }
}
@media screen and (min-width: 2000px) {
  .d_open.con_can {
    height: 220px;
  }
}
.google-recaptcha > div > div {
  transform: scale(0.77);
  -webkit-transform: scale(0.77);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}
