/* === Aboutsectionone === */

.list-link {
  font-size: var(--f24);
  color: var(--clr1);
  font-weight: bold;
  line-height: 36px;
  margin-bottom: 55px;
}

.since-title {
  color: var(--bs-dark);
  font-size: var(--f18);
  margin-top: 32px;
  margin-bottom: 26px;
}

.atlas-paragraph {
  font-size: var(--f24);
  line-height: 40px;
  color: var(--bs-dark);
}

.atlasdark .atlas-paragraph p {
  color: var(--bs-white);
}

/* === Aboutsectiontwo === */

.aboutblockcontent {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.aboutblockcontent .playimg {
  width: 70px;
}

.aboutblockimg .careerblockimg img {
  height: 100%;
}

/* === Aboutsectionthree === */

.about-accordion .accordion {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.about-accordion .accordion-item {
  width: calc(100% - 300px);
}

.about-accordion .accordion-item-head {
  width: 250px;
}

.about-accordion div {
  border: none;
  background-color: transparent;
}

.about-accordion button {
  background-color: none;
}

.about-accordion .accordion-button {
  color: var(--atlasblack);
}
.about-accordion .accordion-button:not(.collapsed) {
  background-color: transparent;
  color: var(--clr1);
  box-shadow: none;
}

.about-accordion .accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}

.about-accordion .accordion-button::after {
  content: none;
}

.about-accordion .accordion-header button {
  font-size: var(--f50);
  font-weight: bold;
  background: transparent;
  line-height: 60px;
}

.about-accordion .accordion-body p {
  font-size: var(--f24);
  line-height: 40px;
  font-weight: bold;
}

.atlas_bg {
  position: absolute;
  bottom: 0;
  right: -15%;
  z-index: -1;
  height: 80%;
}

.dark-mode .atlas_bg {
  opacity: 0.4;
}

/* === Aboutsectionfive === */

.aboutfive .row {
  justify-content: space-between;
  align-items: stretch;
}

.aboutfive .img-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.aboutfive .img-section figure {
  height: 100%;
  border-radius: 25px;
  overflow: hidden;
  scale: 1;
  transition: all 0.4s;
  margin: 0;
}

.aboutfive .img-section figure:hover {
  box-shadow: 0px 30px 20px #0000001a;
  scale: 1.05;
}

.aboutfive .img-section figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.aboutfive .img-section h2 {
  font-size: 40px;
  font-weight: bold;
  line-height: 60px;
  margin: 0;
  margin-top: 20px;
  color: var(--atlasblack);
}

.aboutfive .img-section p {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  margin: 0;
  margin-top: 10px;
  color: var(--atlasblack);
}

/* === Aboutsectionsix === */

.aboutsix .row {
  justify-content: space-between;
  align-items: stretch;
}

/* === EmploySlider === Aboutsectionseven === */

.slider_main {
  padding: 0 20px;
}

.slider_main .slider_head {
  padding: 38px 40px;
  background: white;
  border-radius: 25px;
  color: black;
}

.slider_main .slider_heading {
  display: flex;
  justify-content: flex-start;
  align-items: start;
}

.slider_main figure {
  width: 50px;
  height: 50px;
  flex: 0 0 auto;
  margin: 0;
  margin-right: 20px;
}

.slider_main figure img {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  object-fit: cover;
  object-position: top;
}

.slider_main .heading h4 {
  font-size: 24px;
  line-height: 35px;
  font-weight: bold;
  margin: 0;
}

.slider_main .heading p {
  font-size: 18px;
  line-height: 29px;
  font-weight: 400;
  margin: 0;
}

.slider_main .slider_heading {
  margin-bottom: 20px;
}

.slider_main .slider_body p {
  font-size: 20px;
  line-height: 33px;
  font-weight: 400;
  margin: 0;
}

.slider_main .slider_body {
  margin-bottom: 30px;
}

.aboutseven .slick-dots {
  position: relative !important;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-top: 30px;
}

.aboutseven .slick-dots li button:before {
  font-size: 18px;
  line-height: 18px;
  opacity: 1;
  color: var(--atlaswhite);
}

.aboutseven .slick-dots .slick-active button:before,
.aboutseven .slick-dots li button:hover:before {
  color: var(--atlasgreen) !important;
  opacity: 1;
}

/* === Aboutsectioneight === */

.abouteight > .row {
  position: relative;
  align-items: start;
}

.about-bg-video {
  background-image: url(../src/img/homeimg/career-img.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 25px;
  padding: 21px 26px;
  transition: all 0.4s;
}

.about-bg-video .atlas_life_videos {
  border-radius: 25px;
  object-fit: cover;
}

.about-bg-video:hover {
  background-image: unset !important;
}

.about-bg-video:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--atlasblack);
  right: 0;
  overflow: hidden;
  opacity: 0.7;
  border-radius: 25px;
}

.about-videos-contact {
  position: relative;
}

.about-play {
  display: block;
  width: 50px;
  height: 50px;
}

.about-play img {
  width: 100%;
  height: 100%;
  transition: all 0.4s;
}

.about-play img:hover {
  transform: rotate(-360deg);
}

.join-team {
  font-size: 24px;
  line-height: 31px;
  font-weight: bold;
  color: var(--atlaswhite);
  margin-top: 50px;
}

.first-life {
  position: sticky;
  top: 100px;
}

.first-life .atlas-life {
  margin-bottom: 60px;
}

.first-life .atlas-life ul li a {
  scale: 1;
  display: block;
  transform: scaleX(1);
  transition: all 0.4s;
}

.first-life .atlas-life ul li a:hover {
  scale: 1.1;
  filter: drop-shadow(0px 0px 30px #ffffff80);
  transform: scaleX(-1);
}

.first-life .atlas-life h3 {
  font-size: 94px;
  line-height: 136px;
  font-weight: bold;
  color: var(--atlaswhite);
  margin-bottom: 40px;
}

.first-life .atlas-life h4 {
  font-size: 40px;
  font-weight: bold;
  line-height: 54px;
  color: var(--atlasgreen);
  margin-bottom: 0;
}

.second-life figure {
  height: 100%;
  border-radius: 25px;
  overflow: hidden;
  margin-bottom: 0;
}

.second-life figure.dark img {
  filter: grayscale(1);
}

.second-life figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.second-life .row div {
  margin-top: 24px;
  position: relative;
}

.second-life .row div:first-child {
  margin-top: 0px;
}

.second-life .slid-img {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.second-life .slid-img img {
  width: 100%;
  height: 100%;
}

.rate-card span p {
  font-size: 23px;
  color: var(--atlaswhite);
  font-weight: bold;
  line-height: 23px;
}

.rate-star {
  width: 20px;
  height: 20px;
  display: block;
  margin-bottom: 14px;
}

.rate-star svg {
  width: 100%;
  height: 100%;
}

.rate-card-inner {
  position: relative;
  transition: all 0.4s;
}

.rate-card-inner:hover {
  filter: drop-shadow(0px 0px 10px #ffffff80);
}

.rate-card-inner::after {
  position: absolute;
  content: "";
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #51575d;
  left: calc(100% + 13%);
}

.rate-card-inner:last-child:after {
  content: none;
}

.rate-img img {
  width: 150px;
}

.rate_point {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.aboutseven .slider_head > * {
  width: 100%;
}
.aboutseven .slider_head {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: end;
  align-items: stretch;
  align-content: space-between;
}

.aboutseven .slick-track {
  display: flex !important;
}
.aboutseven .slick-track .slick-slide {
  display: flex !important;
  height: auto !important;
  align-items: stretch !important;
  justify-content: center !important;
  scale: 1;
  transition: all 0.4s;
}
.aboutseven .slick-track .slick-slide:hover {
  scale: 1.03;
}

.aboutseven .slider_main .slider_head,
.aboutseven .slider_main {
  height: 100%;
}

/* section slide */
.scroll-slide-number .slide {
  width: max-content;
  overflow-x: auto;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 100px;
}
.scroll-slide-number .slide .main-slide-number {
  display: flex;
  align-items: center;
  justify-content: start;
}

.scroll-slide-number .slide .main-slide-number .text {
  font-size: 30px;
  font-weight: bold;
  line-height: 38px;
}
.scroll-slide-number .slide .main-slide-number .number {
  font-size: 184px;
  line-height: 270px;
  margin-right: 20px;
  color: var(--atlasgreen);
  font-weight: bold;
}

.scroll-slide-number .slide .main-slide-number:nth-child(odd) .number {
  color: var(--atlasgreen);
}

.scroll-slide-number .slide .main-slide-number:nth-child(even) .number {
  color: var(--atlasblue);
}

.scroll-slide-number .slide .main-slide-number:nth-child(even) .text {
  color: var(--atlasblue);
}

.scroll-slide-number .slide .main-slide-number:nth-child(odd) .text {
  color: #ffffff;
}

/* section slide */

/* === Atlas Life New === */

.life_of_atlas.even {
  width: 60%;
}

.life_of_atlas.odd {
  width: 40%;
}

.life_of_atlas {
  max-height: 300px;
  object-fit: cover;
  object-position: center;
}

.life_of_atlas_row .life_of_atlas:nth-child(1) {
  width: 100%;
}

.life_of_atlas_row .life_of_atlas:last-child {
  width: 100%;
  width: 100%;
}

.aboutsix .awards_card figure {
  border: 4px solid var(--atlasgreen);
  /* background: var(--atlasblue); */
  border-radius: 15px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
}

.aboutsix .awards_card figure img {
  aspect-ratio: 1 / 1;
  object-fit: contain;
  object-position: center;
  max-width: 100%;
  max-height: 180px;
  padding: 10px;
}

.aboutsix .awards_card figure:hover {
  box-shadow: 0px 30px 20px #0000001a;
}

/* === Atlas Life New === */
#galleryModal .modal-body {
  background-color: transparent;
  text-align: center;
}

#galleryModal .modal-content {
  background-color: transparent;
  border: none;
  position: relative;
}

#galleryModal .modal-content .btn-close {
  position: absolute;
  left: 100%;
  border: 4px solid var(--atlasblack);
  filter: brightness(1) invert(1);
  opacity: 1;
  border-radius: 50%;
  padding: 10px;
  transition: all 0.4s;
  background-color: var(--atlaswhite);
}

#galleryModal .modal-content .btn-close:hover {
  filter: unset;
}

#galleryModal .modal-content .modal-body img {
  max-height: 90vh !important;
  border-radius: 15px;
  object-fit: contain;
  box-shadow: 0px 0px 50px #ffffff57;
}

#galleryModal .modal-content .modal-body button.btn {
  padding: 0;
  border: 4px solid var(--atlaswhite);
}

#galleryModal .modal-content .modal-body button.btn:hover {
  border: 4px solid var(--atlaswhite) !important;
}

#galleryModal .modal-content .modal-body button.nexticon {
  transform: rotate(180deg);
  position: absolute;
  bottom: 50%;
  left: 100%;
}

#galleryModal .modal-content .modal-body button.previvon {
  position: absolute;
  bottom: 50%;
  right: 100%;
}

#galleryModal .modal-content .modal-body button.btn svg {
  margin: -3px;
}
div#galleryModal,
div#aboutModalLabel,
div#aboutModal {
  backdrop-filter: blur(10px);
}
div#aboutModalLabel .btn-close,
div#aboutModal .btn-close {
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  border: 4px solid black;
  opacity: 1;
  position: absolute;
  left: 100%;
  z-index: 10;
  transition: all 0.4s;
}
div#aboutModalLabel .btn-close:hover,
div#aboutModal .btn-close:hover {
  filter: brightness(10) invert(10);
}
.modal-dialog-scrollable .modal-content {
  overflow: unset;
}
.modal-dialog-scrollable .modal-body iframe {
  border-radius: 15px;
  height: 80vh;
}

.about-accordion .accordion-item {
  background: transparent;
}

/* === About Media === */

@media (min-width: 1500px) {
  /* === Aboutsectionthree === */

  .about-accordion .accordion-item {
    width: calc(100% - 500px);
  }

  .about-accordion .accordion-item-head {
    width: 250px;
  }
}

@media (max-width: 1400px) {
  /* === Aboutsectionthree === */

  .about-accordion .accordion-item {
    width: calc(100% - 300px);
  }

  .about-accordion .accordion-item-head {
    width: 250px;
  }
}

@media (max-width: 1500px) {
  .list-link {
    margin-bottom: 25px;
  }
  .atlas-paragraph {
    font-size: var(--f20);
    line-height: 30px;
  }
  .slider_main .slider_head {
    padding: 20px 20px;
  }
  .slider_main figure {
    margin-right: 15px;
  }
  .slider_main .heading h4 {
    font-size: 22px;
    line-height: 32px;
  }
  .slider_main .heading p {
    font-size: 16px;
    line-height: 29px;
  }
  .slider_main .slider_body p {
    font-size: 18px;
    line-height: 28px;
  }
}

@media (max-width: 1024px) {
  /* === Aboutsectionthree === */
  .slider_main .slider_head {
    padding: 20px 20px;
  }
  .since-title {
    font-size: var(--f16);
    margin-top: 24px;
    margin-bottom: 20px;
  }
  .aboutfive .img-section h2 {
    font-size: 36px;
    margin-top: 0px;
  }
  .first-life .atlas-life h3 {
    font-size: 80px;
  }
  .slider_main {
    padding: 0;
  }

  .scroll-slide-number .slide .main-slide-number .number {
    font-size: 150px;
    line-height: normal;
  }

  .aboutsix .awards_card figure img {
    max-height: 130px;
  }
}

@media (max-width: 992px) {
  .aboutfive .img-section {
    height: 600px;
  }
  .aboutfive .img-section figure img {
    object-position: 0px -70px;
  }

  .aboutsix .awards_card figure img {
    max-height: 100px;
  }
}
@media (max-width: 767px) {
  .aboutsix .awards_card figure img {
    max-height: 90px;
  }
  #galleryModal .modal-content .btn-close,
  #galleryModal .modal-content .modal-body button.nexticon {
    left: auto;
    right: 10px;
  }
  #galleryModal .modal-content .modal-body button.previvon {
    right: auto;
    left: 10px;
  }
  #galleryModal .modal-content .modal-body img {
    max-height: 80vh !important;
  }
  /* === Aboutsectionone === */

  .list-link {
    font-size: var(--f20);
    margin-bottom: 20px;
  }

  .since-title {
    font-size: var(--f16);
    margin-top: 22px;
    margin-bottom: 16px;
  }

  .atlas-paragraph {
    font-size: var(--f18);
    line-height: normal;
  }

  /* === Aboutsectiontwo === */

  .aboutblockimg .careerblockimg img {
    height: auto;
  }

  /* === Aboutsectionthree === */

  .about-accordion .accordion-item {
    width: 100%;
  }

  .about-accordion .accordion-item-head {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .about-accordion .accordion-item-head .accordion-header {
    margin-right: 0;
  }
  .about-accordion .accordion-item-head .accordion-header + .accordion-header {
    margin-left: 5px;
  }
  .about-accordion .accordion {
    flex-wrap: wrap;
  }

  .about-accordion .accordion-header button {
    font-size: var(--f30);
    line-height: 30px;
  }

  .about-accordion .accordion-body p {
    font-size: var(--f18);
    line-height: 30px;
  }

  .atlas_bg {
    height: 60%;
  }

  /* === Aboutsectionfive === */

  .aboutfive .img-section h2 {
    font-size: var(--f30);
    line-height: 40px;
    margin-top: 0px;
  }

  .aboutfive .img-section p {
    font-size: var(--f16);
    line-height: 20px;
  }

  /* === Aboutsectionseven === */

  .slider_main .slider_heading {
    flex-wrap: wrap;
  }

  .slider_main .slider_head {
    padding: 16px 16px;
  }

  .slider_main {
    padding: 0 5px;
  }

  .slider_main figure {
    margin: 0;
    margin-bottom: 10px;
    margin-right: 10px;
  }

  .slider_main .heading h4 {
    font-size: 22px;
    line-height: 25px;
  }

  .slider_main .heading p {
    font-size: 16px;
  }

  .slider_main .slider_heading {
    margin-bottom: 20px;
  }

  .slider_main .slider_body p {
    font-size: 18px;
    line-height: 24px;
  }
  .slider_main .slider_body {
    margin-bottom: 20px;
  }

  .first-life {
    position: unset;
    top: unset;
  }

  .first-life .atlas-life h3 {
    font-size: 70px;
    line-height: 80px;
  }

  .first-life .atlas-life h4 {
    font-size: 30px;
    line-height: 34px;
  }

  .second-life .row div:first-child {
    margin-top: 24px;
  }

  .rate-card-inner::after {
    content: none;
  }

  .rate-card-inner {
    justify-content: space-between;
  }

  .scroll-slide-number .slide .main-slide-number .number {
    font-size: 100px;
    line-height: normal;
  }
  .about-accordion .accordion-header button.accordion-button {
    padding-left: 0;
    font-size: 26px;
  }
}

@media (max-width: 450px) {
  /* === Aboutsectionfive === */

  .about-accordion .accordion-header button {
    font-size: var(--f20);
    padding: 10px;
  }

  .about-accordion .accordion-body p {
    font-size: var(--f16);
  }

  .atlas_bg {
    height: 30%;
    right: -10%;
  }

  .atlas-paragraph {
    font-size: var(--f16);
    line-height: normal;
  }

  .slider_main .slider_body p {
    font-size: var(--f16);
    line-height: normal;
  }

  .slider_main .heading h4 {
    font-size: 20px;
    line-height: normal;
  }

  .slider_main .heading p {
    font-size: 14px;
    line-height: normal;
  }
  .slider_main .slider_head {
    padding: 12px 12px;
  }

  .aboutseven .slick-slide > div {
    padding: 0;
  }

  .first-life .atlas-life h3 {
    font-size: 60px;
    line-height: normal;
    margin-bottom: 20px;
  }

  /* .life_of_atlas {
    padding: 0 5px;
  } */
  .second-life .row div {
    margin-top: 10px;
  }
  .second-life figure {
    border-radius: 15px;
  }
  .life_of_atlas_row .life_of_atlas {
    width: 100%;
  }
  .aboutfive .img-section h2 {
    font-size: 24px;
    line-height: normal;
  }
  .aboutfive .img-section p {
    font-size: var(--f14);
    line-height: 20px;
  }
}
.wp-block-heading {
  font-size: 28px;
  line-height: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}
@media (min-width: 1400px) {
  .wp-block-heading {
    font-size: 46px;
    line-height: 54px;
  }
}

@media (min-width: 1700px) {
  .wp-block-heading {
    font-size: 50px;
    line-height: 60px;
  }
}
