.page-link {
  border: none;
  color: var(--atlasblack);
  font-weight: bold;
}

.page-link:hover {
  background: transparent;
  color: #003271;
}

span.active.text-custom {
  color: #a2ba5f;
}

.filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.filter .all_btn a {
  display: inline-block;
  background-color: black;
  color: white;
  padding: 6px 16px;
  border-radius: 7px;
}

.filter .filter_tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 70%;
}

.filter_nav span {
  font-size: 14px;
  text-wrap: nowrap;
  white-space: nowrap;
  font-weight: bold;
  color: #c7c9cb;
  transition: all 0.4s;
  min-width: -moz-fit-content; /* Firefox */
  min-width: -webkit-fit-content; /* WebKit (Chrome, Safari) */
  min-width: fit-content; /* Standard */
}

.filter_nav span:hover {
  color: var(--atlasgreen);
}

.filter_nav span:last-child {
  padding-right: 10px;
}

.filter_nav span:first-child {
  padding-left: 10px;
}

.all_btn {
  width: 60px;
}

.filter_search {
  width: 20%;
}

.filter_nav {
  display: flex;
  justify-content: start;
  gap: 20px;
  align-items: center;
  overflow-x: auto;
}

/* Handle */
.filter .filter_tab ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

.filter .filter_tab ::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  border-radius: 50px;
}

.page-item .page-link {
  border-radius: 5px;
  line-height: unset;
}
.page-item:focus-visible,
.page-item .page-link:focus,
.page-item .page-link:focus-visible {
  outline: 0px;
  box-shadow: none;
  background-color: transparent;
}
.page-item.active .page-link {
  color: var(--atlaswhite) !important;
  background-color: var(--atlasgreen) !important;
  border-color: transparent;
  transition: all 0.4s;
}
.page-item .page-link:hover {
  color: var(--atlasblue);
}
.wp-block-table table tr td{border: 1px solid #000000; padding: 5px;}

/* slide animation */
/* .filter .filter_tab {
  position: relative;
  overflow: hidden;
}
.filter_nav {
  position: absolute;
  width: calc(100% - 30px);
  left: 10px;
  right: auto;
}

.next-btn-slide:hover + .filter_nav {
  position: relative;
  z-index: 2;
  left: 10px;
  right: auto;
}
.previous-btn-slide:hover + .filter_nav {
  position: relative;
  z-index: 2;
  right: 10px;
  left: auto;
} */

/* slide animation */

@media (max-width: 767px) {
  .filter {
    flex-wrap: wrap;
  }
  .filter_search {
    order: 2;
    width: calc(70% - 50px);
  }
  .filter .filter_tab {
    order: 3;
    width: 100%;
  }
}
@media (max-width: 475px) {
  .all_btn {
    width: 80px;
  }
  .filter .all_btn a {
    width: 100%;
    text-align: center;
  }
  .filter_search {
    width: calc(100% - 100px);
  }
}
