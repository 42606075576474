.accredited-by-section {
  background-color: #f0f0f0;
}

.accredited-by-section .title-box {
  padding-right: 30px;
  width: 30%;
}

.accredited-by-section .accredited-logo {
  width: 70%;
}

.accredited-by-section .accredited-logo ul {
  flex-wrap: wrap;
  align-items: stretch;
}

.accredited-by-section .accredited-logo li {
  width: 20%;
  text-align: right;
  padding: 10px 5px;
  transition: all 0.4s;
}

.accredited-by-section .accredited-logo li:hover a {
  background-color: var(--atlaswhite);
  box-shadow: 20px 20px 30px #0000001a;
}

.accredited-by-section .accredited-logo li img {
  display: flex;
  transition: all 0.4s;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 4px solid var(--atlasgreen);
  border-radius: 15px;
  aspect-ratio: 1 / 1;
  -webkit-aspect-ratio: 1 / 1;
  object-fit: contain;
  max-width: 100%;
  max-height: 180px;
}

/* === === */

.top-talents-hire-section .all-developer {
  justify-content: start;
  flex-wrap: wrap;
  gap: 15px;
}

.top-talents-hire-section .all-developer h5 {
  font-size: 24px;
  color: var(--atlasblack);
  font-weight: bold;
  margin-bottom: 15px;
}

.top-talents-hire-section .all-developer .box {
  position: relative;
  width: calc(33.33% - 32px);
  border-radius: 10px;
  overflow: hidden;
  padding: 35px;
}

.step-hire-expert-section .all-developer .box {
  position: relative;
  width: calc(25% - 11.25px);
  border-radius: 10px;
  overflow: hidden;
  padding: 35px;
}

.top-talents-hire-section .all-developer .box ul {
  margin-bottom: 0;
}

.top-talents-hire-section .all-developer .box:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 10px;
  height: 100%;
  background: #a2ba5f;
  transition: all 0.3s linear;
}

.top-talents-hire-section .all-developer .box:hover:after {
  width: 100%;
  transition: all 0.3s linear;
}

.top-talents-hire-section .all-developer .box:hover * {
  color: #fff !important;
  position: relative;
  z-index: 1;
  transition: all 0.3s linear;
}

.top-talents-hire-section .all-developer .box:hover li:after {
  border-color: #fff;
}

.top-talents-hire-section .all-developer .box li {
  font-size: 18px;
  margin-bottom: 10px;
  color: var(--atlasblack);
  position: relative;
  padding-left: 20px;
}

.top-talents-hire-section .all-developer .box li:last-of-type {
  margin-bottom: 0;
}

.top-talents-hire-section .all-developer .box:nth-child(3n + 2):after {
  background: #212529;
}

.top-talents-hire-section .all-developer .box:nth-child(3n + 3):after {
  background: #003271;
}

.top-talents-hire-section .all-developer .box li:after {
  content: "";
  width: 8px;
  height: 8px;
  border-right: solid 1px var(--atlasblack);
  border-bottom: solid 1px var(--atlasblack);
  transform: rotate(-45deg);
  position: absolute;
  left: 0;
  transition: all 0.4s;
  top: 8px;
  margin: auto;
}

.top-talents-hire-section .all-developer .box p {
  margin-bottom: 0;
}

/* === === */

.let-discuss-section .inner-content {
  background: #a2ba5f;
  padding: 50px;
  border-radius: 25px;
}

.let-discuss-section p {
  max-width: 82%;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
}

.let-discuss-section .title-box h2 {
  font-size: 40px;
  text-align: center;
}

/* === === */

.certified-developers-section .all-developer .box {
  background: var(--atlasblack);
  border-radius: 15px;
  width: 23.3%;
  padding: 15px;
  scale: 1;
  transition: all 0.2s;
}

.certified-developers-section .all-developer {
  justify-content: space-between;
}

.certified-developers-section .all-developer .box:hover {
  scale: 1.02;
  box-shadow: 0px 30px 20px #0000001a;
}

.certified-developers-section .all-developer .box * {
  color: var(--atlaswhite);
}

.certified-developers-section .all-developer .box h5 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  line-height: 34px;
}

.certified-developers-section .all-developer .box p {
  font-size: 18px;
  line-height: 28px;
  font-weight: 300;
  max-width: 85%;
  margin-bottom: 0;
}

.certified-developers-section .all-developer .box p span {
  font-weight: unset !important;
}

/* === === */

.expertise-section {
  background: #f0f0f0;
}

.expertise-section .expertise-programmers {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 20px;
  margin-top: 50px;
}

.expertise-section .expertise-programmers .box {
  width: calc(25% - 20px);
  margin-bottom: 30px;
}

.expertise-section .expertise-programmers .box h5 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.expertise-section .expertise-programmers .box p {
  font-size: 18px;
  line-height: normal;
  margin-bottom: 0;
}

/* === === */

.skills-developer-section .technical-non-technical * {
  color: #fff;
}

.skills-developer-section .technical-non-technical {
  display: flex;
}

.skills-developer-section .technical-non-technical h6 {
  font-size: 24px;
  margin-bottom: 30px;
  font-weight: bold;
}

.skills-developer-section .technical-non-technical .box {
  background: #a2ba5f;
  width: 100%;
  padding: 56px;
  border-radius: 20px 0 0 20px;
}

.skills-developer-section .technical-non-technical .box.dark {
  background: #212529;
  border-radius: 0 20px 20px 0;
}

.skills-developer-section .technical-non-technical li {
  font-size: 18px;
  line-height: 34px;
  padding-left: 25px;
  position: relative;
  margin-bottom: 5px;
}

.skills-developer-section .technical-non-technical li:after {
  content: "";
  width: 8px;
  height: 8px;
  border-right: solid 2px #fff;
  border-bottom: solid 2px #fff;
  transform: rotate(-45deg);
  position: absolute;
  left: 0;
  top: 12px;
}

/* === === */

.comman-btn.top-space {
  display: table;
  margin: 50px auto 0;
}

/* === === */

.why-us-section li {
  font-size: 24px;
  line-height: 34px;
  padding-left: 25px;
  position: relative;
  margin-bottom: 25px;
  color: var(--atlasblack);
}

.why-us-section li:after {
  content: "";
  width: 8px;
  height: 8px;
  border-right: solid 1px var(--atlasblack);
  border-bottom: solid 1px var(--atlasblack);
  transform: rotate(-45deg);
  position: absolute;
  left: 0;
  top: 12px;
}

.why-us-section h5 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 40px;
}

/* === === */

.step-hire-expert-section .box:nth-child(4):after {
  background-color: #c5c2c2;
}

.step-hire-expert-section .box:last-of-type {
  margin-right: 0;
}

/* === === */

#faq {
  background-color: var(--atlasblack);
}

#faq p {
  color: var(--atlaswhite);
}

#faq .faq_head {
  margin-bottom: 40px;
}

#faq .faq_head h2 {
  font-size: 40px;
  font-weight: bold;
  line-height: 54px;
  margin-bottom: 0;
  color: #ffffff;
}

#faq .faq_head p {
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 0;
  color: #ffffff;
}

#faq .faq_acc .faq_acc_head p {
  font-size: 20px;
  line-height: 33px;
  font-weight: 400;
  margin-bottom: 0;
  color: #ffffff;
}

#faq .accordion-button {
  background: transparent;
  color: white;
  padding: 30px 30px;
  font-size: 20px;
  line-height: 33px;
  font-weight: 400;
  margin: 0;
}

#faq .accordion-body {
  padding: 0 30px 30px 30px;
  font-size: 18px;
  line-height: 29px;
  font-weight: 400;
  margin: 0;
}

#faq .accordion-body ul li img {
  filter: brightness(10);
  margin-right: 10px;
}

#faq .accordion-body ul li {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 10px;
}

#faq .accordion-item {
  background-color: #2a2f34;
  border: none;
  color: white;
  margin-bottom: 20px;
  border-radius: 15px;
}

#faq .accordion-button:not(.collapsed) {
  background-color: #2a2f34;
  box-shadow: none;
  color: white;
}

#faq .accordion-button,
#faq .accordion-item:first-of-type .accordion-button,
#faq .accordion-item:last-of-type,
#faq .accordion-item:first-of-type {
  border-radius: 15px;
}

#faq .accordion-button::after {
  content: "";
}

#faq .accordion-button::after {
  filter: brightness(100);
}

#faq .accordion-button:focus {
  border-color: unset;
  box-shadow: none;
}

/* === === */
/* btn */

#recent-work .learn-btn span {
  background: #acbde1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  transition: all 0.6s;
  justify-content: center;
  order: 1;
}

#recent-work .learn-btn p {
  margin-bottom: 0;
  padding-right: 20px;
  font-size: 24px;
  line-height: 35px;
  font-weight: bold;
  transition: all 0.6s;
  order: 2;
  color: var(--atlasblack);
}

#recent-work .learn-btn {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 270px;
  padding: 5px 10px;
  border: 1px solid var(--atlasblack);
  border-radius: 50px;
  transition: all 0.6s;
  background-color: transparent;
}

#recent-work .learn-btn:hover span {
  order: 2;
}

#recent-work .learn-btn:hover p {
  order: 1;
  color: #ffffff;
  padding-right: 0;
  padding-left: 20px;
}

#recent-work .learn-btn:hover {
  background-color: #003271;
}

/* btn */
/* dots */

#recent-work .work_card .dots {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 4px;
}

#recent-work .work_card .dots span {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50%;
  margin-left: 10px;
}

#recent-work .work_card .dots span.green {
  background: #a2ba5f;
}

#recent-work .work_card .dots span.black {
  background: var(--atlasblack);
}

#recent-work .work_card .dots span.blue {
  background: #003271;
}

/* dots */

#recent-work {
  padding-bottom: 150px;
}

#recent-work .recent-work .recent-btn {
  display: flex;
  justify-content: end;
}

#recent-work .recent-work {
  margin-bottom: 30px;
}

#recent-work .recent-work h5 {
  font-size: 40px;
  line-height: 80px;
  font-weight: bold;
}

#recent-work .work_card {
  display: flex;
  justify-content: start;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 20px;
}

#recent-work .work_card-inner {
  width: calc(33.33% - 13.33px);
  border-radius: 25px;
  overflow: hidden;
  transition: all 0.4s;
  margin: 0;
  margin-top: 20px;
  border: 0.5px solid #cccccc;
}

#recent-work .work_card-inner:hover {
  box-shadow: 0px 30px 20px #0000001a;
}

#recent-work .work_card-inner figure {
  margin: 0;
  position: relative;
  height: 100%;
}

#recent-work .work_card-inner figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  scale: 0.9;
  transition: all 0.4s;
}

#recent-work .work_card-inner:hover figure img {
  scale: 1;
}

#recent-work .work_card-inner figure .dots {
  position: absolute;
  bottom: 10px;
  right: 10px;
  top: auto;
  left: auto;
  width: auto;
  height: auto;
  opacity: 1;
}

.title-box .white-color p {
  color: white;
}

/* === === */
.hireinneranimation2,
.hireinneranimation3,
.hireinneranimation4,
.hireinneranimation61,
.hireinneranimation62,
.hireinneranimation71,
.hireinneranimation72,
.hireinneranimation81,
.hireinneranimation82,
.hireinneranimation91,
.hireinneranimation92,
.hireinneranimation101,
.hireinneranimation102,
.hireinneranimation51,
.hireinneranimation52 {
  opacity: 0;
  transform: translateX(-100px);
}
/* === === */

/* === === */

@media screen and (max-width: 1599px) {
  .skills-developer-section .technical-non-technical h6 {
    font-size: 22px;
  }

  .skills-developer-section .technical-non-technical .box {
    padding: 50px;
  }

  .why-us-section li {
    font-size: 22px;
    line-height: 28px;
  }

  .expert-mobile-services-section .all-service.expertise {
    gap: 40px;
  }
}

@media screen and (max-width: 1365px) {
  .certified-developers-section .all-developer .box p {
    max-width: none;
  }

  .skills-developer-section .technical-non-technical .box {
    padding: 40px;
  }

  .top-talents-hire-section .all-developer .box {
    padding: 30px;
  }

  .title-box p {
    flex: 0 0 52%;
  }

  .skills-developer-section .technical-non-technical h6 {
    margin-bottom: 20px;
  }

  .expert-mobile-services-section .all-service.expertise {
    gap: 30px;
  }

  .why-us-section li {
    font-size: 20px;
    line-height: 20px;
  }

  .top-talents-hire-section .all-developer h5,
  .certified-developers-section .all-developer .box h5 {
    font-size: 22px;
  }

  .skills-developer-section .technical-non-technical .box {
    padding: 30px;
  }

  .why-us-section h5 {
    margin-bottom: 30px;
  }

  .let-discuss-section .inner-content {
    padding: 30px;
  }
}

@media screen and (max-width: 1279px) {
  .let-discuss-section .title-box h2 {
    font-size: 34px;
  }

  .certified-developers-section .all-developer .box {
    flex: 0 0 48%;
    margin-bottom: 4%;
  }

  .certified-developers-section .all-developer {
    flex-wrap: wrap;
  }

  .expertise-section .expertise-programmers .box p {
    line-height: 32px;
  }

  .expertise-section .expertise-programmers {
    justify-content: start;
  }

  .accredited-by-section .d-flex.justify-content-between,
  .title-box.d-flex {
    flex-direction: column;
  }

  .accredited-by-section .accredited-logo {
    width: 100%;
  }

  .accredited-by-section .accredited-logo li {
    width: 14%;
  }

  .title-box h2 {
    margin-bottom: 1rem;
  }

  .title-box h2.pe-5 {
    padding: 0 !important;
  }

  .accredited-by-section .accredited-logo li {
    text-align: left;
  }

  .title-box p {
    font-size: 18px;
  }

  .let-discuss-section .title-box h2 {
    font-size: 28px;
  }

  .top-talents-hire-section .all-developer {
    flex-wrap: wrap;
  }

  .top-talents-hire-section .all-developer .box {
    width: calc(50% - 36px);
    margin: 0 1% 1% 0;
  }

  .step-hire-expert-section .box:last-of-type {
    margin-right: 1%;
  }

  .why-us-section li,
  p {
    font-size: 18px;
    line-height: normal;
  }

  .why-us-section li {
    margin-bottom: 18px;
  }
}

@media screen and (max-width: 1023px) {
  .accredited-by-section .accredited-logo li img {
    max-height: 130px;
  }
  #recent-work {
    padding-bottom: 70px;
  }
  .expertise-section .expertise-programmers .box {
    width: calc(33.33% - 20px);
    margin-bottom: 20px;
  }

  .expertise-section .expertise-programmers .box h5 {
    font-size: 22px;
    margin-bottom: 15px;
  }

  .skills-developer-section .technical-non-technical {
    flex-direction: column;
  }

  .skills-developer-section .technical-non-technical .box {
    padding: 20px;
    border-radius: 20px 20px 0 0;
  }

  .skills-developer-section .technical-non-technical .box.dark {
    border-radius: 0 0 20px 20px;
  }

  .skills-developer-section .technical-non-technical h6 {
    margin-bottom: 15px;
  }

  .top-talents-hire-section .all-developer .box {
    padding: 20px 20px 20px 30px;
  }

  .accredited-by-section .accredited-logo li {
    margin-bottom: 15px;
    width: 20%;
  }

  .expertise-section .expertise-programmers .box p,
  .skills-developer-section .technical-non-technical li,
  .expert-mobile-services-section .all-service.expertise li,
  .why-us-section li,
  .why-us-section li,
  p,
  .top-talents-hire-section .all-developer .box li,
  .certified-developers-section .all-developer .box p {
    font-size: 16px;
    line-height: 30px;
  }

  .why-us-section li {
    margin-bottom: 10px;
  }

  .top-talents-hire-section .all-developer .box li {
    margin-bottom: 6px;
  }

  .certified-developers-section .all-developer .box h5 {
    margin-bottom: 10px;
  }

  #recent-work .work_card-inner {
    width: 47%;
  }

  #recent-work .work_card-inner:last-child {
    margin-bottom: 0;
  }
  .technical-non-technical .title-box,
  .technical-non-technical .title-box h2 {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 992px) {
  .accredited-by-section .accredited-logo li img {
    max-height: 100px;
  }
}
@media screen and (max-width: 767px) {
  .accredited-by-section .accredited-logo li img {
    max-height: 90px;
  }
  #recent-work {
    padding-bottom: 50px;
  }
  .certified-developers-section .all-developer .box,
  .expertise-section .expertise-programmers .box {
    flex: 0 0 100%;
  }

  .why-us-section .title-box h2 br:nth-child(2) {
    display: none;
  }

  .let-discuss-section .inner-content,
  .certified-developers-section .all-developer .box {
    padding: 20px;
  }

  .accredited-by-section .accredited-logo li {
    flex: 0 0 25%;
  }

  .top-talents-hire-section .all-developer .box {
    padding: 15px 15px 15px 30px;
    width: 100%;
    margin: 0 0% 1% 0;
  }

  .title-box h2 {
    line-height: normal;
  }

  .let-discuss-section .title-box h2 {
    font-size: 30px;
  }

  #faq .faq_head {
    margin-bottom: 30px;
  }

  #faq .faq_head h2 {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 20px;
  }

  #faq .faq_head p {
    font-size: 20px;
    line-height: 25px;
  }

  #faq .faq_acc .faq_acc_head p {
    margin-bottom: 20px;
  }

  #faq .accordion-button {
    padding: 15px;
    font-size: 18px;
    line-height: 27px;
  }

  #faq .accordion-body {
    font-size: 16px;
    line-height: 24px;
    padding: 15px;
    padding-top: 0;
  }

  .accredited-by-section .accredited-logo,
  .accredited-by-section .title-box {
    width: 100%;
    padding-right: 0;
  }
}

@media screen and (max-width: 639px) {
  .why-us-section h5 {
    margin-bottom: 15px;
    font-size: 22px;
  }

  .expertise-section .expertise-programmers .box h5 {
    font-size: 20px;
  }

  .let-discuss-section p {
    line-height: normal;
  }

  .accredited-by-section .accredited-logo li {
    flex: 0 0 33%;
  }

  .let-discuss-section p {
    max-width: none;
  }

  .expert-mobile-services-section .all-service.expertise {
    gap: 20px;
  }

  .accredited-by-section .accredited-logo li {
    flex: 0 0 25%;
  }

  .accredited-by-section .accredited-logo li img {
    width: 90%;
  }

  .expert-mobile-services-section .comman-btn.top-space {
    line-height: normal;
  }

  .top-talents-hire-section .all-developer h5 {
    margin-bottom: 10px;
  }

  .why-us-section h5,
  .top-talents-hire-section .all-developer h5,
  .certified-developers-section .all-developer .box h5,
  .skills-developer-section .technical-non-technical h6 {
    font-size: 20px;
  }

  .let-discuss-section .title-box h2 {
    font-size: 24px;
  }

  #recent-work .work_card {
    justify-content: center;
    gap: 0;
  }

  #recent-work .work_card-inner {
    width: 90%;
    margin-bottom: 0;
  }

  #recent-work .recent-work h5 {
    font-size: 32px;
    line-height: normal;
  }
}

@media screen and (max-width: 479px) {
  .why-us-section li {
    line-height: normal;
  }

  .accredited-by-section .accredited-logo li {
    flex: 0 0 33%;
  }

  .let-discuss-section .title-box h2 {
    font-size: 22px;
    line-height: normal;
  }

  .expertise-section .expertise-programmers .box p,
  .skills-developer-section .technical-non-technical li,
  .expert-mobile-services-section .all-service.expertise li,
  .why-us-section li,
  .why-us-section li,
  p,
  .top-talents-hire-section .all-developer .box li,
  .certified-developers-section .all-developer .box p {
    line-height: normal;
  }
  #recent-work .recent-work .recent-btn {
    justify-content: start;
  }
  #recent-work .work_card {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  #recent-work .work_card-inner {
    width: calc(50% - 10px);
    border-radius: 15px;
    margin-bottom: 20px;
    margin-top: 0px;
  }
  #recent-work .work_card-inner figure img {
    min-height: 150px;
  }
  #recent-work .work_card-inner figure .dots {
    bottom: 5px;
    right: 6px;
  }
  #recent-work .work_card-inner:last-child {
    margin-bottom: 20px;
  }
  #recent-work {
    padding-bottom: 30px;
  }
  .top-talents-hire-section .all-developer .box {
    margin: 0;
  }
  .top-talents-hire-section .all-developer {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  #faq .faq_head h2,
  #recent-work .recent-work h5 {
    font-size: 28px;
    line-height: normal;
  }

  #faq .faq_acc .faq_acc_head p,
  #faq .faq_head p {
    font-size: 18px;
    line-height: normal;
  }
  .let-discuss-section p {
    font-size: 14px;
    line-height: normal;
  }

  #faq .accordion-button {
    padding: 10px;
  }
  #faq .accordion-body {
    padding: 10px;
    padding-top: 0;
  }

  #faq p {
    font-size: 14px;
  }

  .title-box .white-color p {
    font-size: 16px;
  }

  .accredited-by-section .accredited-logo li a img {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }

  .accredited-by-section .accredited-logo,
  .accredited-by-section .title-box {
    width: 100%;
  }
}
