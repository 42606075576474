.wordpress-section {
  padding: 50px 0;
}

.wordpress-content h2 {
  font-size: 50px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  line-height: 60px;
}

.wordpress-content h3 {
  font-size: 24px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  line-height: 36px;
}

.wordpress-content p {
  font-size: 20px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  line-height: 30px;
}

.job-descroption-inner h3 {
  font-size: 24px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  line-height: 36px;
  margin-top: 40px;
}

.job-descroption-inner p {
  font-size: 20px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  line-height: 30px;
}

.job-descroption-inner ul {
  padding-left: 0;
}

.job-descroption-inner li {
  list-style: none;
}

.job-arrow-icon {
  gap: 15px;
}

.job-arrow-icon img {
  width: 7px;
  height: 14px;
}

.wordpress-section .career-aplly {
  font-size: 18px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: white;
  line-height: 27px;
  border: 1px solid transparent;
  background: #212529;
  border-radius: 100px;
  padding: 8px 15px;
  text-decoration: none;
  transition: all 0.4s;
}

.wordpress-section .career-aplly:hover {
  background-color: var(--atlasgreen);
}

.other-job-inner h3 {
  font-size: 24px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  line-height: 36px;
}

.other-job-content p {
  font-size: 18px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: var(--atlasblack);
  line-height: 30px;
}

.other-job-inner ul {
  padding-left: 0;
}

.other-job-inner li {
  list-style: none;
}

.other-job-content {
  border-bottom: 2px solid #d2d2d2;
}

@media only screen and (max-width: 768px) {
  .wordpress-content h2 {
    font-size: 35px;
    line-height: 40px;
  }

  .wordpress-content h3 {
    font-size: 18px;
    line-height: 25px;
  }

  .job-descroption-inner h3 {
    font-size: 22px;
    margin-top: 20px;
    line-height: normal;
  }
  .job-descroption-inner li {
    margin-bottom: 10px;
  }
  .job-descroption-inner li p {
    margin-bottom: 0;
  }
  .job-descroption-inner p {
    font-size: 18px;
    margin-bottom: 10px;
    line-height: normal;
  }
}
